import { FunctionComponent } from "react";
import { useRoutes } from "react-router-dom";
import { Unauthorized, Forbidden, NotFound, Error } from "pages";
import { ProjectProvider, TenantProvider, SecurityProvider, SettingsProvider, UserInfoProvider } from "providers";
import { TenantLayout } from "layouts";
import { useRouting } from "hooks";

// Customer
import { CustomersView } from "views/tenants/tenantId/customers";
import { CustomerCreateView } from "views/tenants/tenantId/customers/create";
import { CustomerView } from "views/tenants/tenantId/customers/customerId";
import { CustomerOverviewView } from "views/tenants/tenantId/customers/customerId/overview";
import { CustomerContractsView } from "views/tenants/tenantId/customers/customerId/contracts";
import { CustomerUpdateView } from "views/tenants/tenantId/customers/customerId/update";

// Provider
import { ProvidersView } from "views/tenants/tenantId/providers";
import { ProviderCreateView } from "views/tenants/tenantId/providers/create";
import { ProviderUpdateView } from "views/tenants/tenantId/providers/providerId/update";

// Project
import { ProjectsView } from "views/tenants/tenantId/projects";
import { ProjectCreateView } from "views/tenants/tenantId/projects/create";
import { ProjectView } from "views/tenants/tenantId/projects/projectId";
import { ProjectUpdateView } from "views/tenants/tenantId/projects/projectId/update";

// Activity
import { ProjectActivitiesView } from "views/tenants/tenantId/projects/projectId/activities";
import { ProjectActivityUpdateView } from "views/tenants/tenantId/projects/projectId/activities/activityId/update";
import { ProjectActivityCreateView } from "views/tenants/tenantId/projects/projectId/activities/create";

// Report
import { ProjectReportsView } from "views/tenants/tenantId/projects/projectId/reports";
import { ProjectReportView } from "views/tenants/tenantId/projects/projectId/reports/reportId";
import { ProjectReportUpdateView } from "views/tenants/tenantId/projects/projectId/reports/reportId/update";

// Document
import { ProjectDocumentBillingReportView } from "views/tenants/tenantId/projects/projectId/documents/billing-report";
import { ProjectDocumentSummaryReportView } from "views/tenants/tenantId/projects/projectId/documents/summary-report";
import { ProjectDocumentConstructionReportView } from "views/tenants/tenantId/projects/projectId/documents/construction-report";
import { ProjectDocumentCustomerReportView } from "views/tenants/tenantId/projects/projectId/documents/customer-report";

// Product
import { ProjectProductsView } from "views/tenants/tenantId/projects/projectId/products";
import { ProjectProductsUpdateView } from "views/tenants/tenantId/projects/projectId/products/update";
import { ProjectProductCreateView } from "views/tenants/tenantId/projects/projectId/products/create";
import { ProjectProductView } from "views/tenants/tenantId/projects/projectId/products/productId";
import { ProjectProductUpdateView } from "views/tenants/tenantId/projects/projectId/products/productId/update";

// ProductCategory
import { ProjectProductCategoryCreateView } from "views/tenants/tenantId/projects/projectId/product-categories/create";
import { ProjectProductCategoryUpdateView } from "views/tenants/tenantId/projects/projectId/product-categories/productCategoryId/update";
import { ProjectProductCategoryProductCreateView } from "views/tenants/tenantId/projects/projectId/product-categories/productCategoryId/products/create";

// Model
import { ProjectModelsView } from "views/tenants/tenantId/projects/projectId/models";
import { ProjectModelView } from "views/tenants/tenantId/projects/projectId/models/modelId";
import { ProjectModelImportView } from "views/tenants/tenantId/projects/projectId/models/import";

// Form
import { ProjectFormsView } from "views/tenants/tenantId/projects/projectId/forms";
import { ProjectFormCreateView } from "views/tenants/tenantId/projects/projectId/forms/create";
import { ProjectFormUpdateView } from "views/tenants/tenantId/projects/projectId/forms/formId/update";

// Section
import { ProjectSectionsView } from "views/tenants/tenantId/projects/projectId/sections";
import { ProjectSectionCreateView } from "views/tenants/tenantId/projects/projectId/sections/create";
import { ProjectSectionUpdateView } from "views/tenants/tenantId/projects/projectId/sections/sectionId/update";

// Unit
import { ProjectUnitCreateView } from "views/tenants/tenantId/projects/projectId/units/create";
import { ProjectUnitsUpdateView } from "views/tenants/tenantId/projects/projectId/units/update";
import { ProjectUnitUpdateView } from "views/tenants/tenantId/projects/projectId/units/unitId/update";
import { ProjectUnitContractCreateView } from "views/tenants/tenantId/projects/projectId/units/unitId/contracts/create";

// Contract
import { ProjectContractsView } from "views/tenants/tenantId/projects/projectId/contracts";
import { ProjectContractCreateView } from "views/tenants/tenantId/projects/projectId/contracts/create";
import { ProjectContractView } from "views/tenants/tenantId/projects/projectId/contracts/contractId";
import { ProjectContractSelectionsView } from "views/tenants/tenantId/projects/projectId/contracts/contractId/selections";
import { ProjectContractInvoicesView } from "views/tenants/tenantId/projects/projectId/contracts/contractId/invoices";
import { ProjectContractPaymentsView } from "views/tenants/tenantId/projects/projectId/contracts/contractId/payments";
import { ProjectContractRefundsView } from "views/tenants/tenantId/projects/projectId/contracts/contractId/refunds";
import { ProjectContractInvoiceView } from "views/tenants/tenantId/projects/projectId/contracts/contractId/invoices/invoiceId";
import { ProjectContractSelectionView } from "views/tenants/tenantId/projects/projectId/contracts/contractId/selections/selectionId";
import { ProjectContractSelectionGroupView } from "views/tenants/tenantId/projects/projectId/contracts/contractId/selections/selectionId/groups/groupId";
import { ProjectContractSelectionGroupUpdateView } from "views/tenants/tenantId/projects/projectId/contracts/contractId/selections/selectionId/groups/groupId/update";
import { ProjectContractSelectionUpdateView } from "views/tenants/tenantId/projects/projectId/contracts/contractId/selections/selectionId/update";
import { ProjectSectionView } from "views/tenants/tenantId/projects/projectId/sections/sectionId";
import { ProjectSectionVibesView } from "views/tenants/tenantId/projects/projectId/sections/sectionId/vibes";
import { ProjectFormReportLinkView } from "views/tenants/tenantId/projects/projectId/forms/formId/reports/reportId/link";
import { TenantSettingsUserCreateView } from "views/tenants/tenantId/settings/users/create";
import { ContractProvider } from "providers/ContractProvider";
import { ContractSelectionGroupProvider } from "providers/ContractSelectionGroupProvider";
import { ContractSelectionGroupFormProvider } from "providers/ContractSelectionGroupFormProvider";
import { TenantsView } from "views/tenants";
import { SettingsLayout } from "layouts/SettingsLayout";
import { TenantSettingsRolesView } from "views/tenants/tenantId/settings/roles";
import { TenantSettingsUserView } from "views/tenants/tenantId/settings/users/userId";
import { TenantSettingsProfileView } from "views/tenants/tenantId/settings/profile";
import { TenantSettingsPermissionsView } from "views/tenants/tenantId/settings/permissions";
import { TenantSettingsTaxAgenciesView } from "views/tenants/tenantId/settings/tax-agencies";
import { TenantSettingsTaxGroupsView } from "views/tenants/tenantId/settings/tax-groups";
import { TenantSettingsTaxesView } from "views/tenants/tenantId/settings/taxes";
import { TenantSettingsView } from "views/tenants/tenantId/settings";
import { TenantSettingsUsersView } from "views/tenants/tenantId/settings/users";
import { TenantSettingsUserUpdateView } from "views/tenants/tenantId/settings/users/userId/update";
import { CustomerProvider } from "providers/CustomerProvider";
import { ProviderView } from "views/tenants/tenantId/providers/providerId";
import { TenantSettingsRoleView } from "views/tenants/tenantId/settings/roles/roleId";
import { TenantView } from "views/tenants/tenantId";
import { LayoutProvider } from "providers/LayoutProvider";
import { ProjectDocumentsView } from "views/tenants/tenantId/projects/projectId/documents";

export const App: FunctionComponent = () => {
  const {
    toUnauthorizedPage,
    toForbiddenPage,
    toErrorPage,
    toTenantCustomersView,
    toTenantCustomerCreateView,
    toTenantCustomerView,
    toTenantCustomerUpdateView,
    toTenantCustomerContractsView,
    toTenantProvidersView,
    toTenantProviderCreateView,
    toTenantProviderView,
    toTenantProviderUpdateView,
    toProjectsView,
    toProjectCreateView,
    toProjectView,
    toProjectUpdateView,
    toProjectFormsView,
    toProjectFormCreateView,
    toProjectFormUpdateView,
    toProjectFormReportLinkView,
    toProjectSectionsView,
    toProjectSectionCreateView,
    toProjectSectionView,
    toProjectSectionUpdateView,
    toProjectSectionVibesView,
    toProjectUnitContractCreateView,
    toProjectUnitCreateView,
    toProjectUnitsUpdateView,
    toProjectUnitUpdateView,
    toProjectProductsView,
    toProjectProductView,
    toProjectProductsUpdateView,
    toProjectProductCreateView,
    toProjectProductUpdateView,
    toProjectProductCategoryProductCreateView,
    toProjectProductCategoryCreateView,
    toProjectProductCategoryUpdateView,
    toProjectModelsView,
    toProjectModelView,
    toProjectModelImportView,
    toProjectActivitiesView,
    toProjectActivityCreateView,
    toProjectActivityUpdateView,
    toProjectContractsView,
    toProjectContractView,
    toProjectContractCreateView,
    toProjectContractSelectionsView,
    toProjectContractInvoicesView,
    toProjectContractPaymentsView,
    toProjectContractRefundsView,
    toProjectContractInvoiceView,
    toProjectContractSelectionView,
    toProjectContractSelectionUpdateView,
    toProjectContractSelectionGroupView,
    toProjectContractSelectionGroupUpdateView,
    toProjectReportsView,
    toProjectReportView,
    toProjectReportUpdateView,
    toProjectDocumentsView,
    toProjectDocumentSummaryReportView,
    toProjectDocumentConstructionReportView,
    toProjectDocumentCustomerReportView,
    toProjectDocumentBillingReportView,
    toTenantsView,
    toTenantView,
    toTenantSettingsUsersView,
    toTenantSettingsUserView,
    toTenantSettingsUserCreateView,
    toTenantSettingsUserUpdateView,
    toTenantSettingsRolesView,
    toTenantSettingsRoleView,
    toTenantSettingsView,
    toTenantSettingsProfileView,
    toTenantSettingsTaxesView,
    toTenantSettingsTaxAgenciesView,
    toTenantSettingsTaxGroupsView,
    toTenantSettingsPermissionsView,
  } = useRouting();
  const element = useRoutes([
    {
      element: <UserInfoProvider />,
      children: [
        {
          element: <SecurityProvider />,
          children: [
            {
              index: true,
              element: <TenantsView />,
            },
            {
              element: <LayoutProvider />,
              children: [
                {
                  path: toTenantView(null),
                  element: <TenantProvider />,
                  children: [
                    {
                      element: <TenantLayout />,
                      children: [
                        { index: true, element: <TenantView /> },
                        { path: toProjectUpdateView(null), element: <ProjectUpdateView /> },
                        { path: toProjectCreateView(null), element: <ProjectCreateView /> },
                        { path: toProjectsView(null), element: <ProjectsView /> },
                        { path: toTenantProviderUpdateView(null), element: <ProviderUpdateView /> },
                        { path: toTenantProviderView(null), element: <ProviderView /> },
                        { path: toTenantProviderCreateView(null), element: <ProviderCreateView /> },
                        { path: toTenantProvidersView(null), element: <ProvidersView /> },
                        {
                          path: toTenantCustomerView(null),
                          element: <CustomerProvider />,
                          children: [
                            {
                              element: <CustomerView />,
                              children: [
                                { index: true, element: <CustomerOverviewView /> },
                                { path: toTenantCustomerContractsView(null), element: <CustomerContractsView /> },
                              ],
                            },
                          ],
                        },
                        { path: toTenantCustomerUpdateView(null), element: <CustomerUpdateView /> },
                        { path: toTenantCustomerCreateView(null), element: <CustomerCreateView /> },
                        { path: toTenantCustomersView(null), element: <CustomersView /> },
                      ],
                    },
                    {
                      path: toTenantSettingsView(null),
                      element: <SettingsProvider />,
                      children: [
                        {
                          element: <SettingsLayout />,
                          children: [
                            { index: true, element: <TenantSettingsView /> },
                            { path: toTenantSettingsProfileView(null), element: <TenantSettingsProfileView /> },
                            { path: toTenantSettingsPermissionsView(null), element: <TenantSettingsPermissionsView /> },
                            { path: toTenantSettingsTaxesView(null), element: <TenantSettingsTaxesView /> },
                            { path: toTenantSettingsTaxAgenciesView(null), element: <TenantSettingsTaxAgenciesView /> },
                            { path: toTenantSettingsTaxGroupsView(null), element: <TenantSettingsTaxGroupsView /> },
                            { path: toTenantSettingsUserUpdateView(null), element: <TenantSettingsUserUpdateView /> },
                            { path: toTenantSettingsUserCreateView(null), element: <TenantSettingsUserCreateView /> },
                            { path: toTenantSettingsUserView(null), element: <TenantSettingsUserView /> },
                            { path: toTenantSettingsUsersView(null), element: <TenantSettingsUsersView /> },
                            { path: toTenantSettingsRolesView(null), element: <TenantSettingsRolesView /> },
                            { path: toTenantSettingsRoleView(null), element: <TenantSettingsRoleView /> },
                          ],
                        },
                      ],
                    },
                    {
                      path: toProjectView(null),
                      element: <ProjectProvider />,
                      children: [
                        { path: toProjectDocumentConstructionReportView(null), element: <ProjectDocumentConstructionReportView /> },
                        { path: toProjectDocumentCustomerReportView(null), element: <ProjectDocumentCustomerReportView /> },
                        { path: toProjectDocumentSummaryReportView(null), element: <ProjectDocumentSummaryReportView /> },
                        { path: toProjectDocumentBillingReportView(null), element: <ProjectDocumentBillingReportView /> },
                        { path: toProjectDocumentsView(null), element: <ProjectDocumentsView /> },
                        { path: toProjectReportUpdateView(null), element: <ProjectReportUpdateView /> },
                        { path: toProjectReportView(null), element: <ProjectReportView /> },
                        { path: toProjectReportsView(null), element: <ProjectReportsView /> },
                        { path: toProjectContractInvoiceView(null), element: <ProjectContractInvoiceView /> },
                        {
                          element: <ContractSelectionGroupProvider />,
                          children: [
                            {
                              element: <ContractSelectionGroupFormProvider />,
                              children: [
                                { path: toProjectContractSelectionGroupUpdateView(null), element: <ProjectContractSelectionGroupUpdateView /> },
                                { path: toProjectContractSelectionGroupView(null), element: <ProjectContractSelectionGroupView /> },
                              ],
                            },
                          ],
                        },
                        { path: toProjectContractSelectionUpdateView(null), element: <ProjectContractSelectionUpdateView /> },
                        { path: toProjectContractSelectionView(null), element: <ProjectContractSelectionView /> },
                        {
                          path: toProjectContractView(null),
                          element: <ContractProvider />,
                          children: [
                            {
                              element: <ProjectContractView />,
                              children: [
                                { index: true, element: <ProjectContractSelectionsView /> },
                                { path: toProjectContractSelectionsView(null), element: <ProjectContractSelectionsView /> },
                                { path: toProjectContractInvoicesView(null), element: <ProjectContractInvoicesView /> },
                                { path: toProjectContractPaymentsView(null), element: <ProjectContractPaymentsView /> },
                                { path: toProjectContractRefundsView(null), element: <ProjectContractRefundsView /> },
                              ],
                            },
                          ],
                        },
                        { path: toProjectContractCreateView(null), element: <ProjectContractCreateView /> },
                        { path: toProjectContractsView(null), element: <ProjectContractsView /> },
                        { path: toProjectModelImportView(null), element: <ProjectModelImportView /> },
                        { path: toProjectModelView(null), element: <ProjectModelView /> },
                        { path: toProjectModelsView(null), element: <ProjectModelsView /> },
                        { path: toProjectProductCategoryProductCreateView(null), element: <ProjectProductCategoryProductCreateView /> },
                        { path: toProjectProductCategoryUpdateView(null), element: <ProjectProductCategoryUpdateView /> },
                        { path: toProjectProductCategoryCreateView(null), element: <ProjectProductCategoryCreateView /> },
                        { path: toProjectProductUpdateView(null), element: <ProjectProductUpdateView /> },
                        { path: toProjectProductsUpdateView(null), element: <ProjectProductsUpdateView /> },
                        { path: toProjectProductCreateView(null), element: <ProjectProductCreateView /> },
                        { path: toProjectProductView(null), element: <ProjectProductView /> },
                        { path: toProjectProductsView(null), element: <ProjectProductsView /> },
                        { path: toProjectUnitContractCreateView(null), element: <ProjectUnitContractCreateView /> },
                        { path: toProjectUnitUpdateView(null), element: <ProjectUnitUpdateView /> },
                        { path: toProjectUnitsUpdateView(null), element: <ProjectUnitsUpdateView /> },
                        { path: toProjectUnitCreateView(null), element: <ProjectUnitCreateView /> },
                        { path: toProjectFormReportLinkView(null), element: <ProjectFormReportLinkView /> },
                        { path: toProjectFormUpdateView(null), element: <ProjectFormUpdateView /> },
                        { path: toProjectFormCreateView(null), element: <ProjectFormCreateView /> },
                        { path: toProjectFormsView(null), element: <ProjectFormsView /> },
                        { path: toProjectSectionVibesView(null), element: <ProjectSectionVibesView /> },
                        { path: toProjectSectionUpdateView(null), element: <ProjectSectionUpdateView /> },
                        { path: toProjectSectionCreateView(null), element: <ProjectSectionCreateView /> },
                        { path: toProjectSectionView(null), element: <ProjectSectionView /> },
                        { path: toProjectSectionsView(null), element: <ProjectSectionsView /> },
                        { path: toProjectActivityUpdateView(null), element: <ProjectActivityUpdateView /> },
                        { path: toProjectActivityCreateView(null), element: <ProjectActivityCreateView /> },
                        { path: toProjectActivitiesView(null), element: <ProjectActivitiesView /> },
                        { index: true, element: <ProjectView /> },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: toTenantsView(true),
              element: <TenantsView />,
            },
          ],
        },
      ],
    },
    { path: toUnauthorizedPage(), element: <Unauthorized /> },
    { path: toForbiddenPage(), element: <Forbidden /> },
    { path: toErrorPage(), element: <Error /> },
    { path: "*", element: <NotFound /> },
  ]);
  return element;
};
