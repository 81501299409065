import "./index.scss";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { useCloneSectionMutation } from "graphql/mutations";
import { SectionCloneForm, useSectionCloneForm } from "forms";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly section: ProjectProviderTypes.MappedProjectSection;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const SectionCloneModal: FunctionComponent<Props> = ({ tenantId, projectId, section, onCompleted, onCancel }) => {
  const { values, setValues } = useSectionCloneForm({
    title: "",
  });
  const { cloneSection, cloneSectionErrors, cloneSectionLoading } = useCloneSectionMutation(onCompleted);
  return (
    <ModalForm
      id="section-clone-modal"
      title="Cloner la section"
      size="sm"
      errors={cloneSectionErrors}
      loading={cloneSectionLoading}
      disabled={false}
      submitButton={{
        text: "Cloner",
        onClick: async () => {
          await cloneSection({
            tenantId: tenantId,
            projectId: projectId,
            sectionId: section.id,
            title: values.title,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <SectionCloneForm values={values} setValues={setValues} />
    </ModalForm>
  );
};
