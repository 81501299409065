import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Input, SelectPicker, TagPicker } from "rsuite";
import { FormTable, Text } from "buildingBlocks";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { ProviderFragment } from "graphql/schema";

type ProductsFormValues = {
  readonly products: ReadonlyArray<{
    readonly id: string;
    readonly categoryId: string;
    readonly name: string;
    readonly variant: string;
    readonly format: string;
    readonly usedBySections: boolean;
    readonly provider: {
      readonly id: string | null;
      readonly productModel: string;
      readonly productCode: string;
      readonly productUrl: string;
    };
    readonly activities: ReadonlyArray<{
      readonly activityId: string;
      readonly index: number;
    }>;
  }>;
};

type Props = {
  readonly values: ProductsFormValues;
  readonly setValues: (values: ProductsFormValues) => void;
  readonly activities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity>;
  readonly productCategories: ReadonlyArray<ProjectProviderTypes.MappedProjectProductCategory>;
  readonly providers: ReadonlyArray<ProviderFragment>;
};

export const useProductsForm = (initialValues: ProductsFormValues) => {
  const [values, setValues] = useState<ProductsFormValues>(initialValues);
  return { values, setValues };
};

export const ProductsForm: FunctionComponent<Props> = ({ values, setValues, activities, productCategories, providers }) => (
  <FormTable
    id="products-form"
    table={{
      headColumns: [
        {
          name: "label",
          value: "CATÉGORIE / PRODUIT",
        },
        {
          name: "activities",
          value: "ACTIVITÉS",
        },
        {
          name: "provider",
          value: "FOURNISSEUR",
        },
        {
          name: "provider-product-model",
          value: "MODÈLE",
        },
        {
          name: "provider-product-code",
          value: "CODE",
        },
        {
          name: "provider-product-url",
          value: "SITE WEB",
        },
      ],
      bodyRows: [],
      bodyRowGroups: productCategories
        .filter((productCategory) => values.products.filter((product) => product.categoryId === productCategory.id).length !== 0)
        .map((productCategory) => {
          const products = values.products.filter((product) => product.categoryId === productCategory.id);
          return {
            key: productCategory.id,
            actions: [],
            columns: [
              {
                name: "label",
                component: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {productCategory.managedByModels && <FontAwesomeIcon size="sm" icon={faLock} />}
                    <span>{productCategory.name}</span>
                  </div>
                ),
              },
            ],
            rows: products.map((product) => {
              return {
                key: product.id,
                actions: [],
                columns: [
                  {
                    name: "label",
                    component: <Text highlight>{`${product.variant}${product.format ? ` [${product.format}]` : ""}`}</Text>,
                  },
                  {
                    name: "activities",
                    component: (
                      <TagPicker
                        value={[...product.activities].sort((left, right) => left.index - right.index).map((activity) => activity.activityId)}
                        data={activities.map((activity) => ({
                          label: activity.title,
                          value: activity.id,
                        }))}
                        readOnly={product.usedBySections}
                        cleanable={false}
                        onChange={(value: string[]) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftProduct = draft.products.find((draftProduct) => draftProduct.id === product.id);
                              if (draftProduct) {
                                draftProduct.activities = value.map((activityId, index) => ({
                                  activityId: activityId,
                                  index: index,
                                }));
                              }
                            })
                          );
                        }}
                      />
                    ),
                  },
                  {
                    name: "provider",
                    component: (
                      <SelectPicker
                        value={product.provider.id}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftProduct = draft.products.find((draftProduct) => draftProduct.id === product.id);
                              if (draftProduct) {
                                draftProduct.provider.id = value;
                              }
                            })
                          );
                        }}
                        cleanable
                        data={providers.map((provider) => ({
                          label: provider.name,
                          value: provider.id,
                        }))}
                      />
                    ),
                  },
                  {
                    name: "provider-product-model",
                    component: (
                      <Input
                        value={product.provider.productModel}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftProduct = draft.products.find((draftProduct) => draftProduct.id === product.id);
                              if (draftProduct) {
                                draftProduct.provider.productModel = value;
                              }
                            })
                          );
                        }}
                      />
                    ),
                  },
                  {
                    name: "provider-product-code",
                    component: (
                      <Input
                        value={product.provider.productCode}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftProduct = draft.products.find((draftProduct) => draftProduct.id === product.id);
                              if (draftProduct) {
                                draftProduct.provider.productCode = value;
                              }
                            })
                          );
                        }}
                      />
                    ),
                  },
                  {
                    name: "provider-product-url",
                    component: (
                      <Input
                        value={product.provider.productUrl}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftProduct = draft.products.find((draftProduct) => draftProduct.id === product.id);
                              if (draftProduct) {
                                draftProduct.provider.productUrl = value;
                              }
                            })
                          );
                        }}
                      />
                    ),
                  },
                ],
              };
            }),
          };
        }),
    }}
  />
);
