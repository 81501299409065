import { createContext, FunctionComponent, useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useList } from "react-use";
import { useFindProjectLazyQuery } from "graphql/queries";
import { useRouting } from "hooks";
import { useTenantContext, useSecurityContext } from "providers";
import { IHookStateSetAction } from "react-use/lib/misc/hookState";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { ProjectLayout } from "layouts/ProjectLayout";
import {
  ActivityFragment,
  ContractFragment,
  ContractSelectionFragment,
  FormFragment,
  InvoiceFragment,
  ModelFragment,
  PaymentFragment,
  ProductCategoryFragment,
  ProductFragment,
  ProjectFragment,
  RefundFragment,
  ReportFragment,
  SectionFragment,
  UnitFragment,
} from "graphql/schema";

type Params = {
  readonly projectId: string;
};

export const convertMappedSectionElementUnionsAsList = (
  mappedSectionElements: ReadonlyArray<ProjectProviderTypes.MappedProjectSectionElementUnion>
): ReadonlyArray<ProjectProviderTypes.MappedProjectSectionElement> => {
  return mappedSectionElements
    .reduce<ProjectProviderTypes.MappedProjectSectionElement[]>((previous, current) => {
      if (current.__typename === "SectionElementSet") {
        return previous.concat(current.elements);
      }
      return previous.concat([current]);
    }, [])
    .sort((left, right) => left.index - right.index);
};
export const convertMappedSectionElementOptionUnionsAsList = (
  mappedSectionElementOptions: ReadonlyArray<ProjectProviderTypes.MappedProjectSectionElementOptionUnion>
): ReadonlyArray<ProjectProviderTypes.MappedProjectSectionElementOption> => {
  return mappedSectionElementOptions
    .reduce<ProjectProviderTypes.MappedProjectSectionElementOption[]>((previous, current) => {
      if (current.__typename === "SectionElementOptionSet") {
        return previous.concat([...current.options]);
      }
      return previous.concat([current]);
    }, [])
    .sort((left, right) => left.index - right.index);
};
export const convertMappedSelectionGroupItemUnionsAsList = (
  mappedSelectionGroupItems: ReadonlyArray<ProjectProviderTypes.MappedProjectContractSelectionGroupItemUnion>
): ReadonlyArray<ProjectProviderTypes.MappedProjectContractSelectionGroupItem> => {
  return mappedSelectionGroupItems
    .reduce<ProjectProviderTypes.MappedProjectContractSelectionGroupItem[]>((previous, current) => {
      if (current.__typename === "ContractSelectionGroupItemSet") {
        return previous.concat([...current.items]);
      }
      return previous.concat([current]);
    }, [])
    .sort((left, right) => left.index - right.index);
};

type ProjectContextValue = {
  readonly project: ProjectProviderTypes.MappedProject;
  readonly activities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity>;
  readonly contracts: ReadonlyArray<ProjectProviderTypes.MappedProjectContract>;
  readonly forms: ReadonlyArray<ProjectProviderTypes.MappedProjectForm>;
  readonly invoices: ReadonlyArray<ProjectProviderTypes.MappedProjectInvoice>;
  readonly models: ReadonlyArray<ProjectProviderTypes.MappedProjectModel>;
  readonly productCategories: ReadonlyArray<ProjectProviderTypes.MappedProjectProductCategory>;
  readonly products: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct>;
  readonly payments: ReadonlyArray<ProjectProviderTypes.MappedProjectPayment>;
  readonly refunds: ReadonlyArray<ProjectProviderTypes.MappedProjectRefund>;
  readonly reports: ReadonlyArray<ProjectProviderTypes.MappedProjectReport>;
  readonly sections: ReadonlyArray<ProjectProviderTypes.MappedProjectSection>;
  readonly units: ReadonlyArray<ProjectProviderTypes.MappedProjectUnit>;
  readonly updateProject: (tenantId: string, project: ProjectFragment) => void;
  readonly upsertActivities: (tenantId: string, projectId: string, activities: ReadonlyArray<ActivityFragment>) => void;
  readonly removeActivities: (tenantId: string, projectId: string, activityIds: ReadonlyArray<string>) => void;
  readonly upsertContracts: (tenantId: string, projectId: string, contracts: ReadonlyArray<ContractFragment>) => void;
  readonly removeContracts: (tenantId: string, projectId: string, contractIds: ReadonlyArray<string>) => void;
  readonly upsertForms: (tenantId: string, projectId: string, forms: ReadonlyArray<FormFragment>) => void;
  readonly removeForms: (tenantId: string, projectId: string, formIds: ReadonlyArray<string>) => void;
  readonly upsertInvoices: (tenantId: string, projectId: string, invoices: ReadonlyArray<InvoiceFragment>) => void;
  readonly removeInvoices: (tenantId: string, projectId: string, invoiceIds: ReadonlyArray<string>) => void;
  readonly upsertModels: (tenantId: string, projectId: string, models: ReadonlyArray<ModelFragment>) => void;
  readonly removeModels: (tenantId: string, projectId: string, modelIds: ReadonlyArray<string>) => void;
  readonly upsertProductCategories: (tenantId: string, projectId: string, productCategories: ReadonlyArray<ProductCategoryFragment>) => void;
  readonly removeProductCategories: (tenantId: string, projectId: string, productCategoryIds: ReadonlyArray<string>) => void;
  readonly upsertProducts: (tenantId: string, projectId: string, products: ReadonlyArray<ProductFragment>) => void;
  readonly removeProducts: (tenantId: string, projectId: string, productIds: ReadonlyArray<string>) => void;
  readonly upsertPayments: (tenantId: string, projectId: string, payments: ReadonlyArray<PaymentFragment>) => void;
  readonly removePayments: (tenantId: string, projectId: string, paymentIds: ReadonlyArray<string>) => void;
  readonly upsertRefunds: (tenantId: string, projectId: string, refunds: ReadonlyArray<RefundFragment>) => void;
  readonly removeRefunds: (tenantId: string, projectId: string, refundIds: ReadonlyArray<string>) => void;
  readonly upsertReports: (tenantId: string, projectId: string, reports: ReadonlyArray<ReportFragment>) => void;
  readonly removeReports: (tenantId: string, projectId: string, reportIds: ReadonlyArray<string>) => void;
  readonly upsertSections: (tenantId: string, projectId: string, sections: ReadonlyArray<SectionFragment>) => void;
  readonly removeSections: (tenantId: string, projectId: string, sectionIds: ReadonlyArray<string>) => void;
  readonly upsertUnits: (tenantId: string, projectId: string, units: ReadonlyArray<UnitFragment>) => void;
  readonly removeUnits: (tenantId: string, projectId: string, unitIds: ReadonlyArray<string>) => void;
};

const initialValue: ProjectContextValue = {
  project: {
    __typename: "Project",
    id: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    code: "",
    name: "",
    label: "",
    description: null,
    tenantId: "",
    defaultReportId: "",
    contactInfo: {
      __typename: "ProjectContactInfo",
      companyName: "",
      email: "",
      phone: "",
      websiteUrl: "",
      federalTaxNumber: "",
      provincialTaxNumber: "",
      address: {
        __typename: "Address",
        line: "",
        city: "",
        subdivisionCode: "",
        countryCode: "",
        postalCode: "",
      },
    },
  },
  activities: [],
  contracts: [],
  forms: [],
  invoices: [],
  models: [],
  productCategories: [],
  products: [],
  payments: [],
  refunds: [],
  reports: [],
  sections: [],
  units: [],
  updateProject: () => {},
  upsertActivities: () => {},
  removeActivities: () => {},
  upsertContracts: () => {},
  removeContracts: () => {},
  upsertForms: () => {},
  removeForms: () => {},
  upsertInvoices: () => {},
  removeInvoices: () => {},
  upsertModels: () => {},
  removeModels: () => {},
  upsertProductCategories: () => {},
  removeProductCategories: () => {},
  upsertProducts: () => {},
  removeProducts: () => {},
  upsertPayments: () => {},
  removePayments: () => {},
  upsertRefunds: () => {},
  removeRefunds: () => {},
  upsertReports: () => {},
  removeReports: () => {},
  upsertSections: () => {},
  removeSections: () => {},
  upsertUnits: () => {},
  removeUnits: () => {},
};

function useCollection<T extends { id: string }>(
  project: ProjectFragment,
  compareFn?: (a: T, b: T) => number
): [
  T[],
  {
    set: (newList: IHookStateSetAction<T[]>) => void;
    upsert: (tenantId: string, projectId: string, itemsToUpdate: ReadonlyArray<T>) => void;
    remove: (tenantId: string, projectId: string, ids: ReadonlyArray<string>) => void;
  },
] {
  const [data, { set }] = useList<T>([]);
  return [
    data,
    {
      set,
      upsert: useCallback(
        (tenantId: string, projectId: string, itemsToUpdate: ReadonlyArray<T>) => {
          if (project.tenantId === tenantId && project.id === projectId) {
            const map: Map<string, T> = new Map(data.map((item) => [item.id, item]));
            for (const item of itemsToUpdate) {
              map.set(item.id, item);
            }
            let array = Array.from(map.values());
            if (compareFn) {
              array = array.sort(compareFn);
            }
            set(array);
          }
        },
        [project, data, set, compareFn]
      ),
      remove: useCallback(
        (tenantId: string, projectId: string, ids: ReadonlyArray<string>) => {
          if (project.tenantId === tenantId && project.id === projectId) {
            set(data.filter((item) => !ids.includes(item.id)));
          }
        },
        [project, data, set]
      ),
    },
  ];
}

const Context = createContext<ProjectContextValue>(initialValue);

const useProjectContext = () => useContext(Context);

const ProjectProvider: FunctionComponent = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const { tenant, providers, customers } = useTenantContext();
  const [project, setProject] = useState<ProjectFragment>(initialValue.project);
  const updateProject = useCallback(
    (tenantId: string, project: ProjectFragment) => {
      if (tenant.id === tenantId && project.tenantId === tenantId) {
        setProject(project);
      }
    },
    [tenant, setProject]
  );
  const [reports, { set: setReports, upsert: upsertReports, remove: removeReports }] = useCollection<ReportFragment>(project, (left, right) => left.name.localeCompare(right.name));
  const [activities, { set: setActivities, upsert: upsertActivities, remove: removeActivities }] = useCollection<ActivityFragment>(project, (left, right) => left.number.localeCompare(right.number));
  const [contracts, { set: setContracts, upsert: upsertContracts, remove: removeContracts }] = useCollection<ContractFragment>(
    project,
    (left, right) => new Date(right.createdAt).getTime() - new Date(left.createdAt).getTime()
  );
  const [forms, { set: setForms, upsert: upsertForms, remove: removeForms }] = useCollection<FormFragment>(project, (left, right) => left.index - right.index);
  const [invoices, { set: setInvoices, upsert: upsertInvoices, remove: removeInvoices }] = useCollection<InvoiceFragment>(project, (left, right) => left.number - right.number);
  const [sections, { set: setSections, upsert: upsertSections, remove: removeSections }] = useCollection<SectionFragment>(project, (left, right) => left.index - right.index);
  const [models, { set: setModels, upsert: upsertModels, remove: removeModels }] = useCollection<ModelFragment>(project);
  const [productCategories, { set: setProductCategories, upsert: upsertProductCategories, remove: removeProductCategories }] = useCollection<ProductCategoryFragment>(project, (left, right) =>
    left.name.localeCompare(right.name)
  );
  const [products, { set: setProducts, upsert: upsertProducts, remove: removeProducts }] = useCollection<ProductFragment>(project, (left, right) => left.name.localeCompare(right.name));
  const [payments, { set: setPayments, upsert: upsertPayments, remove: removePayments }] = useCollection<PaymentFragment>(project, (left, right) => left.number - right.number);
  const [refunds, { set: setRefunds, upsert: upsertRefunds, remove: removeRefunds }] = useCollection<RefundFragment>(project, (left, right) => left.number - right.number);
  const [units, { set: setUnits, upsert: upsertUnits, remove: removeUnits }] = useCollection<UnitFragment>(project, (left, right) => {
    if (left.floor !== right.floor) {
      return left.floor - right.floor;
    }
    return left.number.localeCompare(right.number, undefined, { numeric: true });
  });
  // ----------
  const [findProject, { loading }] = useFindProjectLazyQuery((project, activities, contracts, forms, invoices, models, productCategories, products, payments, refunds, reports, sections, units) => {
    setProject(project);
    setActivities([...activities]);
    setContracts([...contracts]);
    setForms([...forms]);
    setInvoices([...invoices]);
    setModels([...models]);
    setProductCategories([...productCategories]);
    setProducts([...products]);
    setPayments([...payments]);
    setRefunds([...refunds]);
    setReports([...reports]);
    setSections([...sections]);
    setUnits([...units]);
    setLoaded(true);
  });
  const { hasProject } = useSecurityContext();
  const { navigate, toProjectsView } = useRouting();
  const { projectId } = useParams<Params>();
  useEffect(() => {
    if (
      projectId &&
      !hasProject({
        tenantId: tenant.id,
        projectId: projectId,
      })
    ) {
      navigate(
        toProjectsView({
          tenantId: tenant.id,
        })
      );
    }
  }, [tenant, projectId, hasProject, navigate, toProjectsView]);
  useEffect(() => {
    if (projectId) {
      findProject({
        variables: {
          tenantId: tenant.id,
          projectId: projectId,
        },
      });
    }
  }, [tenant, projectId, findProject]);
  const mappedReports: ReadonlyArray<ProjectProviderTypes.MappedProjectReport> = reports;
  const mappedModels: ReadonlyArray<ProjectProviderTypes.MappedProjectModel> = models.map((model) => ({
    ...model,
    rooms: model.rooms.map((modelRoom) => ({
      ...modelRoom,
      elements: modelRoom.elements.map((modelRoomElement) => ({
        ...modelRoomElement,
        productIds: modelRoomElement.options.map((option) => option.productId),
      })),
    })),
    productIds: model.rooms.reduce<string[]>(
      (previousValue, currentValue) =>
        previousValue.concat(
          currentValue.elements.reduce<string[]>((previous, current) => previous.concat(current.options.map((option) => option.productId)), []),
          []
        ),
      []
    ),
  }));
  const mappedActivities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity> = activities.map((activity) => ({
    ...activity,
    products: products.filter((product) => product.activities.map((activity) => activity.activityId).includes(activity.id)),
  }));
  const mappedProducts: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct> = products.map((product) => ({
    ...product,
    category: productCategories.find((productCategory) => productCategory.id === product.categoryId)!,
    provider: {
      ...product.provider,
      provider: providers.find((provider) => provider.id === product.provider.id) ?? null,
    },
    activities: product.activities.map((productActivity) => ({
      ...productActivity,
      activity: activities.find((activity) => activity.id === productActivity.activityId)!,
    })),
  }));
  const mappedProductCategories: ReadonlyArray<ProjectProviderTypes.MappedProjectProductCategory> = productCategories.map((productCategory) => ({
    ...productCategory,
    products: mappedProducts.filter((product) => product.categoryId === productCategory.id),
  }));
  const contractSelections = contracts.reduce<ContractSelectionFragment[]>((previous, current) => previous.concat(current.selections), []);
  const mappedInvoices: ReadonlyArray<ProjectProviderTypes.MappedProjectInvoice> = invoices.map((invoice) => {
    const mappedPayments = payments.filter((payment) => payment.invoiceId === invoice.id).sort((left, right) => left.number - right.number);
    const mappedRefunds = refunds.filter((refund) => refund.invoiceId === invoice.id).sort((left, right) => left.number - right.number);
    return {
      ...invoice,
      paidAmount: mappedPayments.reduce((previous, current) => previous + current.amount, 0),
      refundedAmount: mappedRefunds.reduce((previous, current) => previous + current.amount, 0),
      payments: mappedPayments,
      refunds: mappedRefunds,
      contractSelection: contractSelections.find((contractSelection) => contractSelection.id === invoice.contractSelectionId) ?? null,
    };
  });
  const mappedRefunds: ReadonlyArray<ProjectProviderTypes.MappedProjectRefund> = refunds.map((refund) => ({
    ...refund,
    invoice: mappedInvoices.find((invoice) => invoice.id === refund.invoiceId) ?? null,
  }));
  const mappedPayments: ReadonlyArray<ProjectProviderTypes.MappedProjectPayment> = payments.map((payment) => ({
    ...payment,
    invoice: mappedInvoices.find((invoice) => invoice.id === payment.invoiceId) ?? null,
  }));
  const mappedSections: ReadonlyArray<ProjectProviderTypes.MappedProjectSection> = sections.map((section) => {
    const mappedModel = mappedModels.find((model) => model.id === section.modelLink?.modelId) ?? null;
    const mappedModelRoom = mappedModel?.rooms.find((modelRoom) => modelRoom.id === section.modelLink?.modelRoomId) ?? null;
    const mappedSectionElements = section.elements.map((sectionElement) => {
      if (sectionElement.__typename === "SectionElementSet") {
        return {
          ...sectionElement,
          sectionElementGroupName: section.elementGroups.find((sectionElementGroup) => sectionElementGroup.id === sectionElement.sectionElementGroupId)!.name,
          elements: sectionElement.elements.map((element) => {
            const mappedModelRoomElement = mappedModelRoom?.elements.find((mappedModelRoomElement) => mappedModelRoomElement.id === element.modelLink?.modelRoomElementId) ?? null;
            const mappedSectionElementOptions = element.options.map((elementOption) => {
              if (elementOption.__typename === "SectionElementOptionSet") {
                return {
                  ...elementOption,
                  productCategoryName: productCategories.find((productCategory) => productCategory.id === elementOption.productCategoryId)!.name,
                  options: elementOption.options.map((option) => {
                    const mappedModelRoomElementOption =
                      mappedModelRoomElement?.options.find((mappedModelRoomElementOption) => mappedModelRoomElementOption.id === option.modelLink?.modelRoomElementOptionId) ?? null;
                    return {
                      ...option,
                      modelLink: option.modelLink
                        ? {
                            model: mappedModel!,
                            modelRoom: mappedModelRoom!,
                            modelRoomElement: mappedModelRoomElement!,
                            modelRoomElementOption: mappedModelRoomElementOption!,
                          }
                        : null,
                      product: mappedProducts.find((product) => product.id === option.productId)!,
                    };
                  }),
                };
              }
              const mappedModelRoomElementOption =
                mappedModelRoomElement?.options.find((mappedModelRoomElementOption) => mappedModelRoomElementOption.id === elementOption.modelLink?.modelRoomElementOptionId) ?? null;
              return {
                ...elementOption,
                modelLink: elementOption.modelLink
                  ? {
                      model: mappedModel!,
                      modelRoom: mappedModelRoom!,
                      modelRoomElement: mappedModelRoomElement!,
                      modelRoomElementOption: mappedModelRoomElementOption!,
                    }
                  : null,
                product: mappedProducts.find((product) => product.id === elementOption.productId)!,
              };
            });
            return {
              ...element,
              group: section.elementGroups.find((elementGroup) => elementGroup.id === sectionElement.sectionElementGroupId) ?? null,
              modelLink: element.modelLink
                ? {
                    model: mappedModel!,
                    modelRoom: mappedModelRoom!,
                    modelRoomElement: mappedModelRoomElement!,
                  }
                : null,
              options: mappedSectionElementOptions,
              getOptionsWithoutSets: () => {
                return convertMappedSectionElementOptionUnionsAsList(mappedSectionElementOptions);
              },
            };
          }),
        };
      }
      const mappedModelRoomElement = mappedModelRoom?.elements.find((element) => element.id === sectionElement.modelLink?.modelRoomElementId) ?? null;
      const mappedSectionElementOptions = sectionElement.options.map((sectionElementOption) => {
        if (sectionElementOption.__typename === "SectionElementOptionSet") {
          return {
            ...sectionElementOption,
            productCategoryName: productCategories.find((productCategory) => productCategory.id === sectionElementOption.productCategoryId)!.name,
            options: sectionElementOption.options.map((option) => {
              const mappedModelRoomElementOption =
                mappedModelRoomElement?.options.find((mappedModelRoomElementOption) => mappedModelRoomElementOption.id === option.modelLink?.modelRoomElementOptionId) ?? null;
              return {
                ...option,
                modelLink: option.modelLink
                  ? {
                      model: mappedModel!,
                      modelRoom: mappedModelRoom!,
                      modelRoomElement: mappedModelRoomElement!,
                      modelRoomElementOption: mappedModelRoomElementOption!,
                    }
                  : null,
                product: mappedProducts.find((product) => product.id === option.productId)!,
              };
            }),
          };
        }
        const mappedModelRoomElementOption =
          mappedModelRoomElement?.options.find((mappedModelRoomElementOption) => mappedModelRoomElementOption.id === sectionElementOption.modelLink?.modelRoomElementOptionId) ?? null;
        return {
          ...sectionElementOption,
          modelLink: sectionElementOption.modelLink
            ? {
                model: mappedModel!,
                modelRoom: mappedModelRoom!,
                modelRoomElement: mappedModelRoomElement!,
                modelRoomElementOption: mappedModelRoomElementOption!,
              }
            : null,
          product: mappedProducts.find((product) => product.id === sectionElementOption.productId)!,
        };
      });
      return {
        ...sectionElement,
        modelLink: sectionElement.modelLink
          ? {
              model: mappedModel!,
              modelRoom: mappedModelRoom!,
              modelRoomElement: mappedModelRoomElement!,
            }
          : null,
        group: section.elementGroups.find((elementGroup) => elementGroup.id === sectionElement.groupId) ?? null,
        options: mappedSectionElementOptions,
        getOptionsWithoutSets: () => {
          return convertMappedSectionElementOptionUnionsAsList(mappedSectionElementOptions);
        },
      };
    });
    return {
      ...section,
      elements: mappedSectionElements,
      modelLink: section.modelLink
        ? {
            model: mappedModel!,
            modelRoom: mappedModelRoom!,
          }
        : null,
      getElementsWithoutSets: () => {
        return convertMappedSectionElementUnionsAsList(mappedSectionElements);
      },
      vibes: section.vibes.map((sectionVibe) => ({
        ...sectionVibe,
        items: sectionVibe.items.map((sectionVibeItem) => {
          const mappedSectionElement = convertMappedSectionElementUnionsAsList(mappedSectionElements).find((mappedSectionElement) => mappedSectionElement.id === sectionVibeItem.sectionElementId)!;
          const mappedSectionElementOption = convertMappedSectionElementOptionUnionsAsList(mappedSectionElement.options).find(
            (mappedSectionElementOption) => mappedSectionElementOption.id === sectionVibeItem.sectionElementOptionId
          )!;
          return {
            ...sectionVibeItem,
            sectionElement: mappedSectionElement,
            sectionElementOption: mappedSectionElementOption,
          };
        }),
      })),
    };
  });
  const mappedForms: ReadonlyArray<ProjectProviderTypes.MappedProjectForm> = forms.map((form) => {
    const mappedFormSections = form.sections.map((formSection) => ({
      ...formSection,
      section: mappedSections.find((section) => section.id === formSection.sectionId)!,
    }));
    const unlinkedReports: ProjectProviderTypes.MappedProjectReport[] = [];
    for (const report of mappedReports) {
      const formReportRoomElementLinkCount = form.reportRoomElementLinks.filter((link) => link.reportId === report.id).length;
      const formSectionElementCount = mappedFormSections.reduce((previousValue, formSection) => previousValue + formSection.section.getElementsWithoutSets().length, 0);
      const formReportVibeRoomLinkCount = form.reportVibeRoomLinks.filter((link) => link.reportId === report.id).length;
      const formSectionVibeCount = mappedFormSections.reduce((previousValue, formSection) => {
        if (formSection.section.vibes.length > 0) {
          return previousValue + 1;
        }
        return previousValue;
      }, 0);
      if (formReportRoomElementLinkCount !== formSectionElementCount || formReportVibeRoomLinkCount !== formSectionVibeCount) {
        unlinkedReports.push(report);
      }
    }
    return {
      ...form,
      sections: mappedFormSections,
      unlinkedReports: unlinkedReports,
    };
  });
  const mappedContracts: ReadonlyArray<ProjectProviderTypes.MappedProjectContract> = contracts.map((contract) => {
    const mappedForm = mappedForms.find((mappedForm) => mappedForm.id === contract.formId) ?? null;
    const contractSelections: ReadonlyArray<ProjectProviderTypes.MappedProjectContractSelection> = mappedForm
      ? contract.selections.map((contractSelection) => ({
          ...contractSelection,
          invoice: mappedInvoices.find((invoice) => invoice.id === contractSelection.invoiceId) ?? null,
          groups: contractSelection.groups.map((contractSelectionGroup) => {
            const mappedFormSection = mappedForm.sections.find((formSection) => formSection.id === contractSelectionGroup.formSectionId)!;
            const mappedContractSelectionGroupItems = contractSelectionGroup.items.map((contractSelectionGroupItem) => {
              if (contractSelectionGroupItem.__typename === "ContractSelectionGroupItemSet") {
                return {
                  ...contractSelectionGroupItem,
                  sectionElementGroupName: mappedFormSection.section.elementGroups.find((sectionElementGroup) => sectionElementGroup.id === contractSelectionGroupItem.sectionElementGroupId)!.name,
                  items: contractSelectionGroupItem.items.map((item) => {
                    const mappedSection = mappedSections.find((section) => section.id === item.sectionId)!;
                    const mappedSectionElement = convertMappedSectionElementUnionsAsList(mappedFormSection.section.elements).find((sectionElement) => sectionElement.id === item.sectionElementId)!;
                    const mappedSectionElementOption =
                      convertMappedSectionElementOptionUnionsAsList(mappedSectionElement.options).find((sectionElementOption) => sectionElementOption.id === item.sectionElementOptionId) ?? null;
                    return {
                      ...item,
                      section: mappedSection,
                      sectionElement: mappedSectionElement,
                      sectionElementOption: mappedSectionElementOption,
                    };
                  }),
                };
              }
              const mappedSection = mappedSections.find((section) => section.id === contractSelectionGroupItem.sectionId)!;
              const mappedSectionElement = convertMappedSectionElementUnionsAsList(mappedFormSection.section.elements).find(
                (sectionElement) => sectionElement.id === contractSelectionGroupItem.sectionElementId
              )!;
              const mappedSectionElementOption =
                convertMappedSectionElementOptionUnionsAsList(mappedSectionElement.options).find(
                  (sectionElementOption) => sectionElementOption.id === contractSelectionGroupItem.sectionElementOptionId
                ) ?? null;
              return {
                ...contractSelectionGroupItem,
                section: mappedSection,
                sectionElement: mappedSectionElement,
                sectionElementOption: mappedSectionElementOption,
              };
            });
            return {
              ...contractSelectionGroup,
              items: mappedContractSelectionGroupItems,
              getItemsWithoutSets: () => {
                return convertMappedSelectionGroupItemUnionsAsList(mappedContractSelectionGroupItems);
              },
              formSection: mappedFormSection,
              vibe: contractSelectionGroup.vibe
                ? {
                    ...contractSelectionGroup.vibe,
                    sectionVibe: mappedFormSection.section.vibes.find((sectionVibe) => sectionVibe.id === contractSelectionGroup.vibe?.sectionVibeId)!,
                  }
                : null,
            };
          }),
        }))
      : [];
    const mappedCustomers = contract.customers.map((contractCustomer) => ({
      ...contractCustomer,
      customer: customers.find((customer) => customer.id === contractCustomer.customerId)!,
    }));
    return {
      ...contract,
      unit: units.find((unit) => unit.id === contract.unitId)!,
      form: mappedForm,
      primaryCustomer: mappedCustomers.find((contractCustomer) => contractCustomer.index === 0)!.customer,
      secondaryCustomer: mappedCustomers.find((contractCustomer) => contractCustomer.index === 1)?.customer ?? null,
      customers: mappedCustomers,
      selections: contractSelections,
      currentSelection: contractSelections.find((contractSelection) => contractSelection.id === contract.currentSelectionId) ?? null,
      previousSelection: contractSelections.find((contractSelection) => contractSelection.id === contract.previousSelectionId) ?? null,
      invoices: mappedInvoices.filter((invoice) => invoice.contractId === contract.id).sort((left, right) => left.number - right.number),
      paidAmount: mappedPayments.filter((payment) => payment.contractId === contract.id).reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0),
      refundedAmount: mappedRefunds.filter((refund) => refund.contractId === contract.id).reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0),
      payments: mappedPayments.filter((payment) => payment.contractId === contract.id).sort((left, right) => left.number - right.number),
      refunds: mappedRefunds.filter((refund) => refund.contractId === contract.id).sort((left, right) => left.number - right.number),
    };
  });
  const mappedUnits: ReadonlyArray<ProjectProviderTypes.MappedProjectUnit> = units.map((unit) => {
    return {
      ...unit,
      contracts: mappedContracts.filter((contract) => contract.unitId === unit.id),
      currentContract: mappedContracts.find((contract) => contract.id === unit.currentContractId) ?? null,
      form: mappedForms.find((form) => form.id === unit.formId) ?? null,
    };
  });
  return (
    <Context.Provider
      value={{
        project,
        activities: mappedActivities,
        contracts: mappedContracts,
        forms: mappedForms,
        invoices: mappedInvoices,
        models: mappedModels,
        productCategories: mappedProductCategories,
        products: mappedProducts,
        payments: mappedPayments,
        refunds: mappedRefunds,
        reports: mappedReports,
        sections: mappedSections,
        units: mappedUnits,
        updateProject,
        upsertActivities,
        removeActivities,
        upsertContracts,
        removeContracts,
        upsertForms,
        removeForms,
        upsertInvoices,
        removeInvoices,
        upsertModels,
        removeModels,
        upsertProductCategories,
        removeProductCategories,
        upsertProducts,
        removeProducts,
        upsertPayments,
        removePayments,
        upsertRefunds,
        removeRefunds,
        upsertReports,
        removeReports,
        upsertSections,
        removeSections,
        upsertUnits,
        removeUnits,
      }}
    >
      <ProjectLayout loading={!loaded || loading} />
    </Context.Provider>
  );
};

export { ProjectProvider, useProjectContext };
