import { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

type LayoutContextValue = {
  readonly isFullscreen: boolean;
  readonly toggleFullscreen: () => void;
};

const initialValue: LayoutContextValue = {
  isFullscreen: false,
  toggleFullscreen: () => {},
};

const LayoutContext = createContext<LayoutContextValue>(initialValue);

const useLayoutContext = () => useContext(LayoutContext);

const LayoutProvider: FunctionComponent = () => {
  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
      document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
      document.removeEventListener("MSFullscreenChange", handleFullscreenChange);
    };
  }, []);
  const toggleFullscreen = useCallback(() => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable fullscreen:", err);
      });
    } else {
      document.exitFullscreen();
    }
  }, [isFullscreen]);
  return (
    <LayoutContext.Provider value={{ isFullscreen, toggleFullscreen }}>
      <Outlet />
    </LayoutContext.Provider>
  );
};

export { LayoutProvider, useLayoutContext };
