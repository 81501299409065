import { TemplateName } from "graphql/schema";

export const useFile = () => {
  const generateBlobUrl = (props: { data: string }) => {
    const byteCharacters = atob(props.data);
    const byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let index = 0; index < slice.length; index++) {
        byteNumbers[index] = slice.charCodeAt(index);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: "application/pdf" });
    return URL.createObjectURL(blob);
  };
  return {
    names: {
      forExcelSummaryReport: () => "summary_report.xlsx",
      forExcelCustomerReport: () => "customer_report.xlsx",
      forExcelConstructionReport: () => "construction_report.xlsx",
      forExcelBillingReport: () => "billing_report.xlsx",
      forPdfCustomerReport: () => "customer_report.pdf",
      forPdfConstructionReport: () => "construction_report.pdf",
      forPdfBillingReport: () => "billing_report.pdf",
      forPdfContract: () => "contract.pdf",
      forPdfContractSelection: () => "contract_selection.pdf",
      forPdfInvoice: () => "invoice.pdf",
      forTemplate: (props: { readonly name: TemplateName }) => {
        switch (props.name) {
          case TemplateName.BULK_CREATE_UNITS: {
            return "bulk_create_units.csv";
          }
          default: {
            throw new Error("Le nom du gabarit n'exite pas.");
          }
        }
      },
    },
    saveAsFromUrl: (input: { readonly url: string; readonly fileName: string }) => {
      const { url, fileName } = input;
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
    },
    saveAsFromBase64: (input: { readonly data: string; readonly fileName: string }) => {
      const { data, fileName } = input;
      const blobUrl = generateBlobUrl({ data });
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      link.click();
    },
    printFromBase64: (input: { readonly data: string }) => {
      // WARNING: The iframe is never deleted after printing, this is a limitation of the browser event...
      const { data } = input;
      const blobUrl = generateBlobUrl({ data });
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.onload = () => {
        iframe.contentWindow!.print();
      };
    },
  };
};
