import "./index.scss";
import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useProjectSectionContext, useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { useUpdateSectionMutation } from "graphql/mutations";
import { PermissionName } from "graphql/schema";
import { SectionForm, useSectionForm } from "forms";

export const ProjectSectionUpdateView: FunctionComponent = () => {
  const { navigate, toProjectSectionView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, models, activities, products } = useProjectContext();
  const { section } = useProjectSectionContext();
  const { values, setValues } = useSectionForm({
    title: section.title,
    modelLink: section.modelLink
      ? {
          modelId: section.modelLink.model.id,
          modelRoomId: section.modelLink.modelRoom.id,
        }
      : null,
    elementGroups: section.elementGroups.map((elementGroup) => ({
      id: elementGroup.id,
      name: elementGroup.name,
      index: elementGroup.index,
      isDeleted: false,
    })),
    elements: section.getElementsWithoutSets().map((sectionElement) => ({
      id: sectionElement.id,
      index: sectionElement.index,
      type: sectionElement.type,
      label: sectionElement.label,
      isOptional: sectionElement.isOptional,
      isManagedByVibes: sectionElement.isManagedByVibes,
      hideProductCategory: sectionElement.hideProductCategory,
      groupId: sectionElement.groupId,
      modelLink: sectionElement.modelLink
        ? {
            modelId: sectionElement.modelLink.model.id,
            modelRoomId: sectionElement.modelLink.modelRoom.id,
            modelRoomElementId: sectionElement.modelLink.modelRoomElement.id,
          }
        : null,
      isDeleted: false,
      options: sectionElement.getOptionsWithoutSets().map((sectionElementOption) => ({
        id: sectionElementOption.id,
        index: sectionElementOption.index,
        type: sectionElementOption.type,
        clientAmount: sectionElementOption.clientAmount,
        productId: sectionElementOption.productId,
        modelLink: sectionElementOption.modelLink
          ? {
              modelId: sectionElementOption.modelLink.model.id,
              modelRoomId: sectionElementOption.modelLink.modelRoom.id,
              modelRoomElementId: sectionElementOption.modelLink.modelRoomElement.id,
              modelRoomElementOptionId: sectionElementOption.modelLink.modelRoomElementOption.id,
            }
          : null,
        isDeleted: false,
        breakdownLines: [...sectionElementOption.product.activities]
          .sort((left, right) => left.index - right.index)
          .map(({ activityId }) => {
            const breakdownLine = sectionElementOption.breakdownLines.find((breakdownLine) => breakdownLine.activityId === activityId);
            return {
              activityId: breakdownLine?.activityId ?? activityId,
              subcontractorAmount: breakdownLine?.subcontractorAmount ?? 0,
              contractorAmount: breakdownLine?.contractorAmount ?? 0,
              promoterAmount: breakdownLine?.promoterAmount ?? 0,
            };
          }),
      })),
    })),
  });
  const { updateSection, updateSectionLoading, updateSectionErrors } = useUpdateSectionMutation(({ tenantId, projectId, section }) => {
    navigate(
      toProjectSectionView({
        tenantId: tenantId,
        projectId: projectId,
        sectionId: section.id,
      })
    );
  });
  return (
    <ViewForm
      id="project-section-update-view"
      title="Modifier la section"
      security={{
        permissions: [[PermissionName.SECTION_READ, PermissionName.SECTION_UPDATE]],
      }}
      errors={updateSectionErrors}
      loading={updateSectionLoading}
      onSubmit={async () => {
        await updateSection({
          tenantId: tenant.id,
          projectId: project.id,
          sectionId: section.id,
          title: values.title,
          elementGroups: values.elementGroups
            .filter((elementGroup) => !elementGroup.isDeleted)
            .map((elementGroup) => ({
              id: elementGroup.id,
              name: elementGroup.name,
              index: elementGroup.index,
            })),
          elements: values.elements
            .filter((element) => !element.isDeleted)
            .map((element) => ({
              id: element.id,
              index: element.index,
              type: element.type,
              label: element.label,
              isOptional: element.isOptional,
              isManagedByVibes: element.isManagedByVibes,
              hideProductCategory: element.hideProductCategory,
              groupId: element.groupId,
              options: element.options
                .filter((elementOption) => !elementOption.isDeleted)
                .map((elementOption) => ({
                  id: elementOption.id,
                  index: elementOption.index,
                  type: elementOption.type,
                  clientAmount: elementOption.clientAmount,
                  productId: elementOption.productId,
                  modelLink: elementOption.modelLink
                    ? {
                        modelId: elementOption.modelLink.modelId,
                        modelRoomId: elementOption.modelLink.modelRoomId,
                        modelRoomElementId: elementOption.modelLink.modelRoomElementId,
                        modelRoomElementOptionId: elementOption.modelLink.modelRoomElementOptionId,
                      }
                    : null,
                  breakdownLines: elementOption.breakdownLines.map((elementOptionBreakdownLine) => ({
                    activityId: elementOptionBreakdownLine.activityId,
                    subcontractorAmount: elementOptionBreakdownLine.subcontractorAmount,
                    contractorAmount: elementOptionBreakdownLine.contractorAmount,
                    promoterAmount: elementOptionBreakdownLine.promoterAmount,
                  })),
                })),
            })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectSectionView({
            tenantId: tenant.id,
            projectId: project.id,
            sectionId: section.id,
          })
        );
      }}
    >
      <SectionForm values={values} setValues={setValues} models={models} activities={activities} products={products} />
    </ViewForm>
  );
};
