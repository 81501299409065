import "./index.scss";
import { ContainerLoader, ViewReport } from "buildingBlocks";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { PermissionName } from "graphql/schema";
import { useRouting, useProjectContractContext, useProjectContractInvoiceContext, useFile } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { useLayoutContext } from "providers/LayoutProvider";
import { useDownloadContractInvoiceDocumentAsPdfMutation } from "graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useLoadContractInvoiceDocumentLazyQuery } from "graphql/queries";
import { DocumentWrapper } from "buildingBlocks/DocumentWrapper";

export const ProjectContractInvoiceView: FunctionComponent = () => {
  const { navigate, toProjectContractInvoicesView } = useRouting();
  const { isFullscreen, toggleFullscreen } = useLayoutContext();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const { invoice } = useProjectContractInvoiceContext();
  const { names, saveAsFromBase64, printFromBase64 } = useFile();
  const { downloadContractInvoiceDocumentAsPdf, downloadContractInvoiceDocumentAsPdfLoading } = useDownloadContractInvoiceDocumentAsPdfMutation(({ base64Data, print }) => {
    if (print) {
      printFromBase64({
        data: base64Data,
      });
    } else {
      saveAsFromBase64({
        data: base64Data,
        fileName: names.forPdfInvoice(),
      });
    }
  });
  const [html, setHtml] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { loadContractInvoiceDocument, contractInvoiceDocumentLoading } = useLoadContractInvoiceDocumentLazyQuery(({ html }) => {
    setHtml(html);
    setIsLoaded(true);
  });
  const execute = useCallback(async () => {
    await loadContractInvoiceDocument({
      variables: {
        tenantId: tenant.id,
        projectId: project.id,
        contractId: contract.id,
        invoiceId: invoice.id,
      },
    });
  }, [tenant, project, contract, invoice, loadContractInvoiceDocument]);
  useEffect(() => {
    execute();
    // eslint-disable-next-line
  }, []);
  if (!isLoaded || html === null || contractInvoiceDocumentLoading) {
    return <ContainerLoader />;
  }
  return (
    <ViewReport
      id="project-contract-invoice-view"
      security={{
        permissions: [[PermissionName.CONTRACT_READ]],
      }}
      header={{
        subtitle: "Facture",
        title: invoice.label,
        returnButton: {
          onClick: () => {
            if (isFullscreen) {
              toggleFullscreen();
            }
            navigate(
              toProjectContractInvoicesView({
                tenantId: tenant.id,
                projectId: project.id,
                contractId: contract.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Quitter le mode plein écran",
              show: isFullscreen,
              security: {},
              onClick: () => {
                toggleFullscreen();
              },
            },
            {
              __type: "Dropdown",
              appearance: "default",
              size: "sm",
              title: "Exporter",
              trigger: "click",
              placement: "bottomEnd",
              disabled: downloadContractInvoiceDocumentAsPdfLoading,
              show: !isFullscreen,
              security: {
                permissions: [[PermissionName.CONTRACT_READ]],
              },
              items: [
                {
                  __type: "DropdownItem",
                  text: "Télécharger (PDF)",
                  disabled: downloadContractInvoiceDocumentAsPdfLoading,
                  show: !isFullscreen,
                  security: {
                    permissions: [[PermissionName.CONTRACT_READ]],
                  },
                  onClick: () => {
                    downloadContractInvoiceDocumentAsPdf({
                      tenantId: tenant.id,
                      projectId: project.id,
                      contractId: contract.id,
                      invoiceId: invoice.id,
                      print: false,
                    });
                  },
                },
              ],
            },
            {
              __type: "Button",
              appearance: "default",
              text: <FontAwesomeIcon icon={faPrint} />,
              show: !isFullscreen,
              loading: downloadContractInvoiceDocumentAsPdfLoading,
              security: {
                permissions: [[PermissionName.CONTRACT_READ]],
              },
              onClick: () => {
                downloadContractInvoiceDocumentAsPdf({
                  tenantId: tenant.id,
                  projectId: project.id,
                  contractId: contract.id,
                  invoiceId: invoice.id,
                  print: true,
                });
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Entrer en mode plein écran",
              show: !isFullscreen,
              security: {},
              onClick: () => {
                toggleFullscreen();
              },
            },
          ],
        },
      }}
    >
      <DocumentWrapper html={html} />
    </ViewReport>
  );
};
