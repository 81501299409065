import "./index.scss";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { SelectPicker } from "rsuite";
import { ContainerLoader, ViewReport } from "buildingBlocks";
import { PermissionName } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { ReportMessage } from "components";
import { useFile, useProjectReportContext, useProjectUnitContext, useRouting } from "hooks";
import { useDownloadConstructionReportDocumentAsExcelMutation, useDownloadConstructionReportDocumentAsPdfMutation } from "graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useLoadConstructionReportDocumentLazyQuery } from "graphql/queries";
import { DocumentWrapper } from "buildingBlocks/DocumentWrapper";

export const ProjectDocumentConstructionReportView: FunctionComponent = () => {
  const { navigate, toProjectDocumentsView, toProjectDocumentConstructionReportView, toProjectContractSelectionsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, reports, units } = useProjectContext();
  const { report } = useProjectReportContext();
  const { unit } = useProjectUnitContext();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [html, setHtml] = useState<string | null | undefined>(undefined);
  const { names, printFromBase64, saveAsFromBase64 } = useFile();
  const { downloadConstructionReportDocumentAsExcel, downloadConstructionReportDocumentAsExcelLoading } = useDownloadConstructionReportDocumentAsExcelMutation(({ base64Data }) => {
    saveAsFromBase64({
      data: base64Data,
      fileName: names.forExcelConstructionReport(),
    });
  });
  const { downloadConstructionReportDocumentAsPdf, downloadConstructionReportDocumentAsPdfLoading } = useDownloadConstructionReportDocumentAsPdfMutation(({ base64Data, print }) => {
    if (print) {
      printFromBase64({
        data: base64Data,
      });
    } else {
      saveAsFromBase64({
        data: base64Data,
        fileName: names.forPdfConstructionReport(),
      });
    }
  });
  const { loadConstructionReportDocument, constructionReportDocumentLoading } = useLoadConstructionReportDocumentLazyQuery(({ html }) => {
    setHtml(html);
    setIsLoaded(true);
  });
  const execute = useCallback(async () => {
    await loadConstructionReportDocument({
      variables: {
        tenantId: tenant.id,
        projectId: project.id,
        reportId: report.id,
        unitId: unit.id,
      },
    });
  }, [tenant, project, report, unit, loadConstructionReportDocument]);
  useEffect(() => {
    if (unit.isDocumentsAvailable) {
      execute();
    } else {
      setHtml(null);
      setIsLoaded(true);
    }
  }, [setHtml, unit, execute]);
  if (!isLoaded || html === undefined || constructionReportDocumentLoading) {
    return <ContainerLoader />;
  }
  return (
    <ViewReport
      id="project-document-construction-report-view"
      security={{
        permissions: [[PermissionName.DOCUMENT_CONSTRUCTION_REPORT_READ]],
      }}
      header={{
        subtitle: "Contrats",
        title: "Fiche de construction",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectDocumentsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Dropdown",
              appearance: "default",
              size: "sm",
              title: "Exporter",
              trigger: "click",
              placement: "bottomEnd",
              disabled: downloadConstructionReportDocumentAsPdfLoading || downloadConstructionReportDocumentAsExcelLoading,
              security: {
                permissions: [[PermissionName.DOCUMENT_CONSTRUCTION_REPORT_READ]],
              },
              show: unit.isDocumentsAvailable,
              items: [
                {
                  __type: "DropdownItem",
                  text: "Télécharger (PDF)",
                  disabled: downloadConstructionReportDocumentAsPdfLoading || downloadConstructionReportDocumentAsExcelLoading,
                  show: unit.isDocumentsAvailable,
                  security: {
                    permissions: [[PermissionName.DOCUMENT_CONSTRUCTION_REPORT_READ]],
                  },
                  onClick: () => {
                    downloadConstructionReportDocumentAsPdf({
                      tenantId: tenant.id,
                      projectId: project.id,
                      reportId: report.id,
                      unitId: unit.id,
                      print: false,
                    });
                  },
                },
                {
                  __type: "DropdownItem",
                  text: "Télécharger (Excel)",
                  disabled: downloadConstructionReportDocumentAsPdfLoading || downloadConstructionReportDocumentAsExcelLoading,
                  show: unit.isDocumentsAvailable,
                  security: {
                    permissions: [[PermissionName.DOCUMENT_CONSTRUCTION_REPORT_READ]],
                  },
                  onClick: () => {
                    downloadConstructionReportDocumentAsExcel({
                      tenantId: tenant.id,
                      projectId: project.id,
                      reportId: report.id,
                      unitId: unit.id,
                    });
                  },
                },
              ],
            },
            {
              __type: "Button",
              text: <FontAwesomeIcon icon={faPrint} />,
              appearance: "default",
              show: unit.isDocumentsAvailable,
              loading: downloadConstructionReportDocumentAsPdfLoading || downloadConstructionReportDocumentAsExcelLoading,
              security: {
                permissions: [[PermissionName.DOCUMENT_CONSTRUCTION_REPORT_READ]],
              },
              onClick: () => {
                downloadConstructionReportDocumentAsPdf({
                  tenantId: tenant.id,
                  projectId: project.id,
                  reportId: report.id,
                  unitId: unit.id,
                  print: true,
                });
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Voir le contrat",
              disabled: false,
              show: unit.currentContract !== null,
              security: {
                permissions: [[PermissionName.CONTRACT_READ]],
              },
              onClick: () => {
                navigate(
                  toProjectContractSelectionsView({
                    tenantId: tenant.id,
                    projectId: project.id,
                    contractId: unit.currentContract!.id,
                  })
                );
              },
            },
          ],
        },
        filter: {
          left: {
            inputs: [
              <SelectPicker
                size="xs"
                label="Unité #"
                value={unit.id}
                onChange={(value) => {
                  navigate(
                    toProjectDocumentConstructionReportView({
                      tenantId: tenant.id,
                      projectId: project.id,
                      reportId: report.id,
                      unitId: value!,
                    })
                  );
                }}
                data={units.map((unit) => ({
                  label: unit.isDocumentsAvailable ? `${unit.number} [DISPONIBLE]` : unit.number,
                  value: unit.id,
                }))}
                cleanable={false}
              />,
            ],
            applyButton: null,
          },
          right: {
            inputs: [
              <SelectPicker
                size="xs"
                label="Rapport"
                value={report.id}
                searchable={false}
                onChange={(value) => {
                  if (value !== report.id) {
                    navigate(
                      toProjectDocumentConstructionReportView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        reportId: value!,
                        unitId: unit.id,
                      })
                    );
                  }
                }}
                cleanable={false}
                data={reports.map((report) => ({
                  label: report.name,
                  value: report.id,
                }))}
              />,
            ],
          },
        },
      }}
    >
      <ReportMessage unitState={unit.state} />
      {html !== null && <DocumentWrapper html={html} />}
    </ViewReport>
  );
};
