import "./index.scss";
import { ProductThumbnail } from "components/ProductThumbnail";
import { FunctionComponent } from "react";
import { Checkbox } from "rsuite";
import { SectionElementOptionType } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly variantOnly: boolean;
  readonly checked: boolean;
  readonly onClick: (sectionElementOption: ProjectProviderTypes.MappedProjectSectionElementOption) => void;
  readonly sectionElementOption: ProjectProviderTypes.MappedProjectSectionElementOption;
};

export const SectionElementOptionProductCard: FunctionComponent<Props> = ({ sectionElementOption, onClick, checked, variantOnly }) => {
  return (
    <div
      className={`product-card${!sectionElementOption.isUnlocked ? ` product-card-locked` : ""}`}
      onClick={
        sectionElementOption.isUnlocked
          ? () => {
              onClick(sectionElementOption);
            }
          : undefined
      }
    >
      <ProductThumbnail size="lg" hasThumbnail={sectionElementOption.product.hasThumbnail} thumbnailUrl={sectionElementOption.product.thumbnailUrl} />
      <div className="product-card-content">
        <div className="start">
          <div className="start">
            <span className="label">{variantOnly ? sectionElementOption.product.variant : sectionElementOption.product.name}</span>
            {sectionElementOption.product.format && <span>{sectionElementOption.product.format}</span>}
            {sectionElementOption.product.note && <span>{sectionElementOption.product.note}</span>}
          </div>
          {sectionElementOption.type === SectionElementOptionType.EXTRA && (
            <div className="end">
              <span>EXTRA</span>
            </div>
          )}
          {sectionElementOption.type === SectionElementOptionType.MIX_AND_MATCH && (
            <div className="end">
              <span>MIX AND MATCH</span>
            </div>
          )}
        </div>
        <div className="end">
          {sectionElementOption.type === SectionElementOptionType.INCLUDED ? <span>INCLUT</span> : <span>{sectionElementOption.clientAmount.toCurrencyFormat()}</span>}
          <Checkbox checked={checked} />
        </div>
      </div>
    </div>
  );
};
