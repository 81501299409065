import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { EnumLabel, SearchInputGroup, TooltipWrapper, ViewList, Text } from "buildingBlocks";
import { useProjectSectionContext, useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName, SectionElementOptionType, SectionElementType, SectionState } from "graphql/schema";
import { Tag } from "rsuite";
import { SectionConfigureDialog, SectionDeleteDialog, SectionPublishDialog } from "dialogs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { SectionElementOptionUpdateModal } from "modals/SectionElementOptionUpdateModal";
import { SectionCloneModal } from "modals/SectionCloneModal";

const useProjectSectionViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (sectionElements: ReadonlyArray<ProjectProviderTypes.MappedProjectSectionElement>) =>
        sectionElements
          .filter(
            (sectionElement) =>
              sectionElement.label.toSearchFormat().includes(search.toSearchFormat()) ||
              sectionElement.getOptionsWithoutSets().some((sectionElementOption) => sectionElementOption.product.name.toSearchFormat().includes(search.toSearchFormat()))
          )
          .map((sectionElement) => ({
            ...sectionElement,
            options: sectionElement.label.toSearchFormat().includes(search.toSearchFormat())
              ? sectionElement.getOptionsWithoutSets()
              : sectionElement.getOptionsWithoutSets().filter((sectionElementOption) => sectionElementOption.product.name.toSearchFormat().includes(search.toSearchFormat())),
          })),
      [search]
    ),
  };
};

export const ProjectSectionView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectSectionViewFilters();
  const { navigate, toProjectSectionsView, toProjectSectionUpdateView, toProjectSectionVibesView, toProjectProductView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, activities, products } = useProjectContext();
  const { section } = useProjectSectionContext();
  const [sectionToPublish, setSectionToPublish] = useState<ProjectProviderTypes.MappedProjectSection | null>(null);
  const [sectionToConfigure, setSectionToConfigure] = useState<ProjectProviderTypes.MappedProjectSection | null>(null);
  const [sectionToDelete, setSectionToDelete] = useState<ProjectProviderTypes.MappedProjectSection | null>(null);
  const [sectionToClone, setSectionToClone] = useState<ProjectProviderTypes.MappedProjectSection | null>(null);
  const [sectionElementOptionToUpdate, setSectionElementOptionToUpdate] = useState<ProjectProviderTypes.MappedProjectSectionElementOption | null>(null);
  return (
    <ViewList
      id="project-section-view"
      security={{
        permissions: [[PermissionName.SECTION_READ]],
      }}
      header={{
        title: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <span>{section.title}</span>
            <EnumLabel
              appearances={[
                {
                  value: SectionState.CREATED,
                  appearance: "primary",
                },
                {
                  value: SectionState.CONFIGURED,
                  appearance: "primary",
                },
                {
                  value: SectionState.PUBLISHED,
                  appearance: "primary",
                },
                {
                  value: SectionState.ARCHIVED,
                  appearance: "primary",
                },
              ]}
              value={section.state}
              uppercase
              width={100}
              height={22}
              bold
            />
          </div>
        ),
        subtitle: "Section",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectSectionsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Voir les ambiances",
              appearance: "default",
              show: section.state !== SectionState.CREATED && section.getElementsWithoutSets().some((sectionElement) => sectionElement.isManagedByVibes),
              security: {},
              onClick: () => {
                navigate(
                  toProjectSectionVibesView({
                    tenantId: tenant.id,
                    projectId: project.id,
                    sectionId: section.id,
                  })
                );
              },
            },
            {
              __type: "Button",
              text: "Modifier la section",
              show: section.state === SectionState.CREATED,
              security: {
                permissions: [[PermissionName.SECTION_UPDATE]],
              },
              onClick: () => {
                navigate(
                  toProjectSectionUpdateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                    sectionId: section.id,
                  })
                );
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Cloner la section",
              show: true,
              security: {
                permissions: [[PermissionName.SECTION_UPDATE]],
              },
              onClick: () => {
                setSectionToClone(section);
              },
            },
            {
              __type: "Button",
              content: "Configurer la section",
              show: section.state === SectionState.CREATED && section.getElementsWithoutSets().some((sectionElement) => sectionElement.isManagedByVibes),
              security: {
                permissions: [[PermissionName.SECTION_CONFIGURE]],
              },
              onClick: () => {
                setSectionToConfigure(section);
              },
            },
            {
              __type: "Button",
              content: "Publier la section",
              show:
                (section.state === SectionState.CONFIGURED && section.vibes.length >= 1) ||
                (section.state === SectionState.CREATED && section.vibes.length === 0 && section.getElementsWithoutSets().every((sectionElement) => !sectionElement.isManagedByVibes)),
              security: {
                permissions: [[PermissionName.SECTION_PUBLISH]],
              },
              onClick: () => {
                setSectionToPublish(section);
              },
            },
            {
              __type: "Button",
              content: "Supprimer la section",
              show: section.state === SectionState.CREATED || section.state === SectionState.CONFIGURED,
              security: {
                permissions: [[PermissionName.SECTION_DELETE]],
              },
              onClick: () => {
                setSectionToDelete(section);
              },
            },
          ],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [],
          },
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "number",
              value: "#",
            },
            {
              name: "name",
              value: "ÉLÉMENT / OPTION",
            },
            {
              name: "vibe",
              value: "AMBIANCE",
            },
            {
              name: "category",
              value: "Catégorie",
            },
            {
              name: "type",
              value: "TYPE",
            },
            {
              name: "client-amount",
              value: "MONTANT CLIENT",
            },
            {
              name: "subcontractor-amount",
              value: "MONTANT SOUS-TRAITANT",
            },
            {
              name: "contractor-amount",
              value: "MONTANT ENTREPRENEUR",
            },
            {
              name: "promoter-amount",
              value: "MONTANT PROMOTEUR",
            },
            {
              name: "contingency-amount",
              value: "MONTANT CONTINGENCE",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(section.getElementsWithoutSets()).map((sectionElement) => {
            const sectionElementDisplayName = sectionElement.group ? sectionElement.group.name + " - " + sectionElement.label : sectionElement.label;
            return {
              key: sectionElement.id,
              columns: [
                {
                  name: "number",
                  component: <Tag className="section-index">{sectionElement.index + 1}</Tag>,
                },
                {
                  name: "name",
                  component: (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {sectionElement.modelLink && (
                        <TooltipWrapper tooltip="Lié au(x) modèle(s)">
                          <FontAwesomeIcon color="grey" size="sm" icon={faLink} />
                        </TooltipWrapper>
                      )}
                      <span>{sectionElementDisplayName}</span>
                      {sectionElement.isOptional && <small>(facultatif)</small>}
                    </div>
                  ),
                },
                {
                  name: "vibe",
                  component:
                    sectionElement.type !== SectionElementType.UNIQUE || (sectionElement.type === SectionElementType.UNIQUE && sectionElement.isOptional)
                      ? "--"
                      : sectionElement.isManagedByVibes
                        ? "Oui"
                        : "Non",
                },
                {
                  name: "category",
                  component: sectionElement.type !== SectionElementType.UNIQUE && sectionElement.type !== SectionElementType.READONLY ? "--" : sectionElement.hideProductCategory ? "Caché" : "Affiché",
                },
                {
                  name: "type",
                  component: (
                    <EnumLabel
                      value={sectionElement.type}
                      appearances={[
                        {
                          value: SectionElementType.NONE,
                          appearance: "primary",
                        },
                        {
                          value: SectionElementType.BOOLEAN,
                          appearance: "primary",
                        },
                        {
                          value: SectionElementType.READONLY,
                          appearance: "primary",
                        },
                        {
                          value: SectionElementType.UNIQUE,
                          appearance: "primary",
                        },
                      ]}
                      block
                      bold
                      uppercase
                      width={200}
                    />
                  ),
                },
                {
                  name: "client-amount",
                  component: "",
                },
                {
                  name: "subcontractor-amount",
                  component: "",
                },
                {
                  name: "contractor-amount",
                  component: "",
                },
                {
                  name: "promoter-amount",
                  component: "",
                },
                {
                  name: "contingency-amount",
                  component: "",
                },
              ],
              actions: [],
              rows: sectionElement.options.map((sectionElementOption) => {
                return {
                  key: sectionElementOption.id,
                  columns: [
                    {
                      name: "number",
                      component: <Tag className="section-element-index">{sectionElementOption.index + 1}</Tag>,
                    },
                    {
                      name: "name",
                      component: (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {sectionElementOption.modelLink && (
                            <TooltipWrapper tooltip="Lié au(x) modèle(s)">
                              <FontAwesomeIcon color="grey" size="sm" icon={faLink} />
                            </TooltipWrapper>
                          )}
                          <Text highlight>{sectionElementOption.product.name}</Text>
                        </div>
                      ),
                    },
                    {
                      name: "vibe",
                      component: "",
                    },
                    {
                      name: "category",
                      component: "",
                    },
                    {
                      name: "type",
                      component: (
                        <EnumLabel
                          value={sectionElementOption.type}
                          appearances={[
                            {
                              value: SectionElementOptionType.INCLUDED,
                              appearance: "secondary",
                            },
                            {
                              value: SectionElementOptionType.EXCLUDED,
                              appearance: "secondary",
                            },
                            {
                              value: SectionElementOptionType.MIX_AND_MATCH,
                              appearance: "secondary",
                            },
                            {
                              value: SectionElementOptionType.MIX_AND_MATCH,
                              appearance: "secondary",
                            },
                          ]}
                          block
                          bold
                          uppercase
                          width={200}
                        />
                      ),
                    },
                    {
                      name: "client-amount",
                      component: sectionElementOption.clientAmount.toCurrencyFormat(),
                    },
                    {
                      name: "subcontractor-amount",
                      component: sectionElementOption.subcontractorAmount.toCurrencyFormat(),
                    },
                    {
                      name: "contractor-amount",
                      component: sectionElementOption.contractorAmount.toCurrencyFormat(),
                    },
                    {
                      name: "promoter-amount",
                      component: sectionElementOption.promoterAmount.toCurrencyFormat(),
                    },
                    {
                      name: "contingency-amount",
                      component: sectionElementOption.contingencyAmount.toCurrencyFormat(),
                    },
                  ],
                  actions: [
                    {
                      __type: "Button",
                      content: "Voir le produit",
                      security: {
                        permissions: [[PermissionName.PRODUCT_READ]],
                      },
                      show: true,
                      onClick: () => {
                        navigate(
                          toProjectProductView({
                            tenantId: tenant.id,
                            projectId: project.id,
                            productId: sectionElementOption.product.id,
                          })
                        );
                      },
                    },
                    {
                      __type: "Button",
                      content: "Modifier l'option",
                      security: {
                        permissions: [[PermissionName.SECTION_UPDATE]],
                      },
                      show: sectionElementOption.type === SectionElementOptionType.CREDIT_SUBCONTRACTOR || sectionElementOption.type === SectionElementOptionType.EXTRA,
                      onClick: () => {
                        setSectionElementOptionToUpdate(sectionElementOption);
                      },
                    },
                  ],
                };
              }),
            };
          }),
        },
      }}
    >
      {sectionToClone && (
        <SectionCloneModal
          tenantId={tenant.id}
          projectId={project.id}
          section={sectionToClone}
          onCompleted={() => {
            navigate(
              toProjectSectionsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          }}
          onCancel={() => {
            setSectionToClone(null);
          }}
        />
      )}
      {sectionToPublish && (
        <SectionPublishDialog
          tenantId={tenant.id}
          projectId={project.id}
          section={sectionToPublish}
          onCompleted={() => {
            setSectionToPublish(null);
          }}
          onClose={() => {
            setSectionToPublish(null);
          }}
        />
      )}
      {sectionToConfigure && (
        <SectionConfigureDialog
          tenantId={tenant.id}
          projectId={project.id}
          section={sectionToConfigure}
          onCompleted={() => {
            setSectionToConfigure(null);
          }}
          onClose={() => {
            setSectionToConfigure(null);
          }}
        />
      )}
      {sectionToDelete && (
        <SectionDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          section={sectionToDelete}
          onCompleted={() =>
            navigate(
              toProjectSectionsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            )
          }
          onClose={() => {
            setSectionToDelete(null);
          }}
        />
      )}
      {sectionElementOptionToUpdate && (
        <SectionElementOptionUpdateModal
          tenantId={tenant.id}
          projectId={project.id}
          sectionId={section.id}
          sectionElementOption={sectionElementOptionToUpdate}
          activities={activities}
          products={products}
          onCompleted={() => {
            setSectionElementOptionToUpdate(null);
          }}
          onCancel={() => {
            setSectionElementOptionToUpdate(null);
          }}
        />
      )}
    </ViewList>
  );
};
