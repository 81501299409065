import "./index.scss";
import { useUploadContractMutation } from "graphql/mutations";
import { FunctionComponent, useState } from "react";
import { FormContainer, ModalForm, Uploader } from "buildingBlocks";
import produce from "immer";
import { Form } from "rsuite";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type ContractUploadFormValues = {
  readonly document: File | undefined;
};

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractUploadModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, onCompleted, onCancel }) => {
  const [values, setValues] = useState<ContractUploadFormValues>({
    document: undefined,
  });
  const { uploadContract, uploadContractLoading, uploadContractErrors } = useUploadContractMutation(onCompleted, undefined, false);
  return (
    <ModalForm
      id="contract-upload-modal"
      title="Téléverser un contrat"
      errors={uploadContractErrors}
      loading={uploadContractLoading}
      disabled={values.document === undefined}
      submitButton={{
        onClick: async () => {
          await uploadContract({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            document: values.document!,
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <Form id="contract-upload-form">
        <FormContainer highlight withoutBorder>
          <Form.Group>
            <Form.ControlLabel>Document (.pdf)</Form.ControlLabel>
            <Uploader
              accept=".pdf"
              disabled={values.document !== undefined}
              onChange={(fileTypes) => {
                const fileType = fileTypes[0];
                if (fileType) {
                  setValues(
                    produce(values, (draft) => {
                      draft.document = fileType.blobFile;
                    })
                  );
                }
              }}
              onRemove={() => {
                setValues(
                  produce(values, (draft) => {
                    draft.document = undefined;
                  })
                );
              }}
            />
          </Form.Group>
        </FormContainer>
      </Form>
    </ModalForm>
  );
};
