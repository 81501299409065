import "./index.scss";
import { FunctionComponent } from "react";
import { ModalForm } from "buildingBlocks";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { useUpdateSectionElementOptionMutation } from "graphql/mutations";
import { SectionElementOptionUpdateForm, useSectionElementOptionUpdateForm } from "forms";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly sectionId: string;
  readonly sectionElementOption: ProjectProviderTypes.MappedProjectSectionElementOption;
  readonly activities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity>;
  readonly products: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct>;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const SectionElementOptionUpdateModal: FunctionComponent<Props> = ({ tenantId, projectId, sectionId, sectionElementOption, activities, products, onCompleted, onCancel }) => {
  const { values, setValues } = useSectionElementOptionUpdateForm({
    clientAmount: sectionElementOption.clientAmount,
    breakdownLines: sectionElementOption.breakdownLines.map((sectionElementOptionBreakdownLine) => ({
      activityId: sectionElementOptionBreakdownLine.activityId,
      subcontractorAmount: sectionElementOptionBreakdownLine.subcontractorAmount,
      contractorAmount: sectionElementOptionBreakdownLine.contractorAmount,
      promoterAmount: sectionElementOptionBreakdownLine.promoterAmount,
    })),
  });
  const { updateSectionElementOption, updateSectionElementOptionErrors, updateSectionElementOptionLoading } = useUpdateSectionElementOptionMutation(onCompleted);
  return (
    <ModalForm
      id="section-element-option-update-modal"
      title="Modifier l'option"
      size="lg"
      errors={updateSectionElementOptionErrors}
      loading={updateSectionElementOptionLoading}
      disabled={false}
      submitButton={{
        text: "Modifier",
        onClick: async () => {
          await updateSectionElementOption({
            tenantId: tenantId,
            projectId: projectId,
            sectionId: sectionId,
            sectionElementId: sectionElementOption.sectionElementId,
            sectionElementOptionId: sectionElementOption.id,
            sectionElementOptionClientAmount: values.clientAmount,
            sectionElementOptionBreakdownLines: values.breakdownLines.map((breakdownLine) => ({
              activityId: breakdownLine.activityId,
              subcontractorAmount: breakdownLine.subcontractorAmount,
              contractorAmount: breakdownLine.contractorAmount,
              promoterAmount: breakdownLine.promoterAmount,
            })),
          });
        },
      }}
      closeButton={{
        text: "Annuler",
        onClick: onCancel,
      }}
    >
      <SectionElementOptionUpdateForm values={values} setValues={setValues} sectionElementOption={sectionElementOption} activities={activities} products={products} />
    </ModalForm>
  );
};
