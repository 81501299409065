import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { DateTime, EnumLabel, SearchInputGroup, ViewTab, ViewTabList, Text } from "buildingBlocks";
import { InvoiceStatus, PermissionName } from "graphql/schema";
import { useRouting, useProjectContractContext } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { ContractInvoicePaymentCreateModal, ContractInvoiceRefundCreateModal } from "modals";
import { BalanceAmount } from "components";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectContractInvoicesViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (contractInvoices: ReadonlyArray<ProjectProviderTypes.MappedProjectInvoice>) =>
        contractInvoices.filter((contractInvoice) => contractInvoice.label.toSearchFormat().includes(search.toSearchFormat())),
      [search]
    ),
  };
};

export const ProjectContractInvoicesView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectContractInvoicesViewFilters();
  const { toProjectContractInvoiceView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, payments, refunds } = useProjectContext();
  const { contract } = useProjectContractContext();
  const [invoiceToCreatePayment, setInvoiceToCreatePayment] = useState<ProjectProviderTypes.MappedProjectInvoice | null>(null);
  const [invoiceToCreateRefund, setInvoiceToCreateRefund] = useState<ProjectProviderTypes.MappedProjectInvoice | null>(null);
  return (
    <ViewTab id="project-contract-invoices-view">
      <ViewTabList
        header={{
          filter: {
            left: {
              inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
              applyButton: null,
            },
            right: {
              inputs: [],
            },
          },
        }}
        content={{
          table: {
            emptyBodies: [
              {
                show: contract.invoices.length === 0,
                title: "Aucune facture",
                content: "Compléter une sélection pour visualiser les factures.",
              },
            ],
            headColumns: [
              {
                name: "date",
                value: "DATE",
              },
              {
                name: "label",
                value: "ÉTIQUETTE",
              },
              {
                name: "status",
                value: "STATUT",
              },
              {
                name: "selection",
                value: "Sélection",
              },
              {
                name: "total",
                value: "TOTAL",
              },
              {
                name: "credit-amount",
                value: "CRÉDIT",
              },
              {
                name: "paid-amount",
                value: "PAYÉ",
              },
              {
                name: "refunded-amount",
                value: "REMBOURSÉ",
              },
              {
                name: "balance",
                value: "SOLDE",
              },
            ],
            bodyRows: applyFilters(contract.invoices).map((invoice) => {
              return {
                key: invoice.id,
                lineThrough: invoice.status === InvoiceStatus.CANCELED || invoice.status === InvoiceStatus.CREDITED,
                href: toProjectContractInvoiceView({
                  tenantId: tenant.id,
                  projectId: project.id,
                  contractId: contract.id,
                  invoiceId: invoice.id,
                }),
                columns: [
                  {
                    name: "date",
                    component: <DateTime value={invoice.date} />,
                  },
                  {
                    name: "label",
                    component: <Text highlight>{invoice.label}</Text>,
                  },
                  {
                    name: "status",
                    component: <EnumLabel value={invoice.status} uppercase bold block />,
                  },
                  {
                    name: "selection",
                    component: invoice.contractSelection?.label ?? "--",
                  },
                  {
                    name: "total",
                    component: invoice.totalAmount.toCurrencyFormat(),
                  },
                  {
                    name: "credit-amount",
                    component: invoice.creditAfterTaxAmount.toCurrencyFormat(),
                  },
                  {
                    name: "paid-amount",
                    component: invoice.paidAmount.toCurrencyFormat(),
                  },
                  {
                    name: "refunded-amount",
                    component: invoice.refundedAmount.toCurrencyFormat(),
                  },
                  {
                    name: "balance",
                    component: <BalanceAmount value={invoice.balanceAmount} />,
                  },
                ],
                actions: [
                  {
                    __type: "Button",
                    content: "Créer un paiement",
                    show:
                      !contract.isCanceled &&
                      invoice !== null &&
                      invoice.contractSelection !== null &&
                      invoice.status !== InvoiceStatus.CREDITED &&
                      invoice.status !== InvoiceStatus.CANCELED &&
                      invoice.status !== InvoiceStatus.NO_CHARGE &&
                      contract.currentSelectionId === invoice.contractSelection.id &&
                      invoice.balanceAmount > 0,
                    security: {
                      permissions: [[PermissionName.CONTRACT_INVOICE_PAYMENT_CREATE]],
                    },
                    onClick: () => {
                      setInvoiceToCreatePayment(invoice);
                    },
                  },
                  {
                    __type: "Button",
                    content: "Créer un remboursement",
                    show:
                      !contract.isCanceled &&
                      invoice !== null &&
                      invoice.contractSelection !== null &&
                      invoice.status !== InvoiceStatus.CREDITED &&
                      invoice.status !== InvoiceStatus.CANCELED &&
                      invoice.status !== InvoiceStatus.NO_CHARGE &&
                      contract.currentSelectionId === invoice.contractSelection.id &&
                      invoice.balanceAmount < 0,
                    security: {
                      permissions: [[PermissionName.CONTRACT_INVOICE_REFUND_CREATE]],
                    },
                    onClick: () => {
                      setInvoiceToCreateRefund(invoice);
                    },
                  },
                ],
              };
            }),
            bodyRowGroups: [],
          },
        }}
      />
      {invoiceToCreatePayment && (
        <ContractInvoicePaymentCreateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          invoice={invoiceToCreatePayment}
          payments={payments}
          onCompleted={() => {
            setInvoiceToCreatePayment(null);
          }}
          onCancel={() => {
            setInvoiceToCreatePayment(null);
          }}
        />
      )}
      {invoiceToCreateRefund && (
        <ContractInvoiceRefundCreateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          invoice={invoiceToCreateRefund}
          refunds={refunds}
          onCompleted={() => {
            setInvoiceToCreateRefund(null);
          }}
          onCancel={() => {
            setInvoiceToCreateRefund(null);
          }}
        />
      )}
    </ViewTab>
  );
};
