import "./index.scss";
import { FunctionComponent } from "react";
import { ViewList, Text, getEnumLabel } from "buildingBlocks";
import { useProjectReportContext, useRouting } from "hooks";
import { Tag } from "rsuite";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";

export const ProjectReportView: FunctionComponent = () => {
  const { navigate, toProjectReportsView, toProjectReportUpdateView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { report } = useProjectReportContext();
  return (
    <ViewList
      id="project-report-view"
      security={{
        permissions: [[PermissionName.REPORT_READ]],
      }}
      header={{
        subtitle: "Rapport",
        title: report.name,
        returnButton: {
          onClick: () => {
            navigate(
              toProjectReportsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Modifier le rapport",
              show: true,
              security: {
                permissions: [[PermissionName.REPORT_UPDATE]],
              },
              onClick: () => {
                navigate(
                  toProjectReportUpdateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                    reportId: report.id,
                  })
                );
              },
            },
          ],
          otherActions: [],
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "index",
              value: "#",
            },
            {
              name: "name",
              value: "PIÈCE / ÉLÉMENT",
            },
            {
              name: "display",
              value: "AFFICHAGE",
            },
          ],
          bodyRows: [],
          bodyRowGroups: report.rooms.map((reportRoom) => {
            return {
              key: reportRoom.id,
              columns: [
                {
                  name: "index",
                  component: <Tag className="report-room-index">{reportRoom.index + 1}</Tag>,
                },
                {
                  name: "name",
                  component: reportRoom.name,
                },
                {
                  name: "display",
                  component: null,
                },
              ],
              actions: [],
              rows: reportRoom.elements.map((reportRoomElement) => {
                return {
                  key: reportRoomElement.id,
                  actions: [],
                  columns: [
                    {
                      name: "index",
                      component: <Tag className="report-room-element-index">{reportRoomElement.index + 1}</Tag>,
                    },
                    {
                      name: "name",
                      component: <Text highlight>{reportRoomElement.name}</Text>,
                    },
                    {
                      name: "display",
                      component: getEnumLabel(reportRoomElement.display),
                    },
                  ],
                };
              }),
            };
          }),
        },
      }}
    ></ViewList>
  );
};
