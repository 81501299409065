import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { DateTime, EnumLabel, SearchInputGroup, ViewTab, ViewTabList, Text } from "buildingBlocks";
import { useProjectContractContext, useRouting } from "hooks";
import { BalanceAmount } from "components";
import { ContractState, PermissionName, ContractSelectionState } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { ContractRevisionDeleteDialog, ContractSelectionDeleteDialog } from "dialogs";
import { ContractSelectionCreateModal, ContractRevisionUpdateModal, ContractSelectionUpdateModal } from "modals";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

const useProjectContractSelectionsViewFilters = () => {
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (contractSelections: ReadonlyArray<ProjectProviderTypes.MappedProjectContractSelection>) =>
        contractSelections.filter((contractSelection) => contractSelection.label.toSearchFormat().includes(search.toSearchFormat())),
      [search]
    ),
  };
};

export const ProjectContractSelectionsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectContractSelectionsViewFilters();
  const { navigate, toProjectContractSelectionView, toProjectUnitUpdateView } = useRouting();
  const { tenant, taxGroups } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const [contractToCreateSelection, setContractToCreateSelection] = useState<ProjectProviderTypes.MappedProjectContract | null>(null);
  const [contractSelectionToUpdate, setContractSelectionToUpdate] = useState<ProjectProviderTypes.MappedProjectContractSelection | null>(null);
  const [contractSelectionToDelete, setContractSelectionToDelete] = useState<ProjectProviderTypes.MappedProjectContractSelection | null>(null);
  const [contractRevisionToUpdate, setContractRevisionToUpdate] = useState<ProjectProviderTypes.MappedProjectContractSelection | null>(null);
  const [contractRevisionToDelete, setContractRevisionToDelete] = useState<ProjectProviderTypes.MappedProjectContractSelection | null>(null);
  return (
    <ViewTab id="project-contract-selections-view">
      <ViewTabList
        header={{
          filter: {
            left: {
              inputs: [<SearchInputGroup size="xs" placeholder="Recherche par étiquette" value={filterValues.search} onChange={filterFunctions.setSearch} />],
              applyButton: null,
            },
            right: {
              inputs: [],
            },
          },
        }}
        content={{
          table: {
            emptyBodies: [
              {
                show: !contract.isCanceled && contract.state !== ContractState.IS_CUSTOMIZED && contract.state === ContractState.WITHOUT_FORM,
                title: "Sans formulaire",
                content: "Pour associé un formulaire, cliquez sur le bouton ci-dessous.",
                button: {
                  text: "Modifier l'unité",
                  security: {
                    permissions: [[PermissionName.UNIT_UPDATE]],
                  },
                  onClick: () => {
                    navigate(
                      toProjectUnitUpdateView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        unitId: contract.unitId,
                      })
                    );
                  },
                },
              },
              {
                show: !contract.isCanceled && contract.state !== ContractState.IS_CUSTOMIZED && contract.state === ContractState.WITHOUT_SELECTION,
                title: "Aucune sélection",
                content: "Pour créer une sélection, cliquez sur le bouton ci-dessous.",
                button: {
                  text: "Créer une sélection",
                  security: {
                    permissions: [[PermissionName.CONTRACT_SELECTION_CREATE]],
                  },
                  onClick: () => {
                    setContractToCreateSelection(contract);
                  },
                },
              },
            ],
            headColumns: [
              {
                name: "date",
                value: "DATE",
              },
              {
                name: "label",
                value: "ÉTIQUETTE",
              },
              {
                name: "state",
                value: "STATUT",
              },
              {
                name: "reason",
                value: "MOTIF",
              },
              {
                name: "number-of-extras",
                value: "NB EXTRA",
              },
              {
                name: "total-amount",
                value: "MONTANT",
              },
              {
                name: "invoice",
                value: "FACTURE",
              },
            ],
            bodyRows: applyFilters(contract.selections).map((contractSelection) => {
              return {
                key: contractSelection.id,
                lineThrough: contractSelection.state === ContractSelectionState.CANCELED,
                href:
                  contractSelection.state !== ContractSelectionState.PENDING
                    ? toProjectContractSelectionView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        contractId: contract.id,
                        contractSelectionId: contractSelection.id,
                      })
                    : undefined,
                columns: [
                  {
                    name: "date",
                    component: <DateTime value={contractSelection.createdAt} />,
                  },
                  {
                    name: "label",
                    component: <Text highlight>{contractSelection.label}</Text>,
                  },
                  {
                    name: "state",
                    component: <EnumLabel value={contractSelection.state} uppercase bold block />,
                  },
                  {
                    name: "reason",
                    component: contractSelection.revisionReason ?? "--",
                  },
                  {
                    name: "number-of-extras",
                    component: contractSelection.state !== ContractSelectionState.PENDING ? contractSelection.numberOfExtras : "--",
                  },
                  {
                    name: "total-amount",
                    component: contractSelection.state !== ContractSelectionState.PENDING ? <BalanceAmount value={contractSelection.clientAmount} /> : "--",
                  },
                  {
                    name: "invoice",
                    component: contractSelection.state !== ContractSelectionState.PENDING ? (contractSelection.invoice?.label ?? "--") : "--",
                  },
                ],
                actions: [
                  {
                    __type: "Button",
                    content: "Modifier la sélection",
                    show:
                      !contract.isCanceled &&
                      contract.state !== ContractState.IS_CUSTOMIZED &&
                      contract.state === ContractState.SELECTION_PENDING &&
                      contractSelection.revisionNumber === 0 &&
                      contractSelection.state !== ContractSelectionState.FINISHED,
                    security: {
                      permissions: [[PermissionName.CONTRACT_SELECTION_UPDATE]],
                    },
                    onClick: () => {
                      setContractSelectionToUpdate(contractSelection);
                    },
                  },
                  {
                    __type: "Button",
                    content: "Supprimer la sélection",
                    show:
                      !contract.isCanceled &&
                      contract.state !== ContractState.IS_CUSTOMIZED &&
                      contract.state === ContractState.SELECTION_PENDING &&
                      contractSelection.revisionNumber === 0 &&
                      contractSelection.state !== ContractSelectionState.FINISHED,
                    security: {
                      permissions: [[PermissionName.CONTRACT_SELECTION_DELETE]],
                    },
                    onClick: () => {
                      setContractSelectionToDelete(contractSelection);
                    },
                  },
                  {
                    __type: "Button",
                    content: "Modifier la révision",
                    show:
                      !contract.isCanceled &&
                      contract.state !== ContractState.IS_CUSTOMIZED &&
                      contract.state === ContractState.REVISION_PENDING &&
                      contractSelection.revisionNumber !== 0 &&
                      contractSelection.state !== ContractSelectionState.FINISHED,
                    security: {
                      permissions: [[PermissionName.CONTRACT_SELECTION_UPDATE]],
                    },
                    onClick: () => {
                      setContractRevisionToUpdate(contractSelection);
                    },
                  },
                  {
                    __type: "Button",
                    content: "Supprimer la révision",
                    show:
                      !contract.isCanceled &&
                      contract.state !== ContractState.IS_CUSTOMIZED &&
                      contract.state === ContractState.REVISION_PENDING &&
                      contractSelection.revisionNumber !== 0 &&
                      contractSelection.state !== ContractSelectionState.FINISHED,
                    security: {
                      permissions: [[PermissionName.CONTRACT_SELECTION_DELETE]],
                    },
                    onClick: () => {
                      setContractRevisionToDelete(contractSelection);
                    },
                  },
                ],
              };
            }),
            bodyRowGroups: [],
          },
        }}
      />
      {contractToCreateSelection && (
        <ContractSelectionCreateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contractToCreateSelection}
          taxGroups={taxGroups}
          onCompleted={() => {
            setContractToCreateSelection(null);
          }}
          onCancel={() => {
            setContractToCreateSelection(null);
          }}
        />
      )}
      {contractSelectionToUpdate && (
        <ContractSelectionUpdateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          contractSelection={contractSelectionToUpdate}
          taxGroups={taxGroups}
          onCompleted={() => {
            setContractSelectionToUpdate(null);
          }}
          onCancel={() => {
            setContractSelectionToUpdate(null);
          }}
        />
      )}
      {contractSelectionToDelete && (
        <ContractSelectionDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          contractId={contract.id}
          contractSelection={contractSelectionToDelete}
          onCompleted={() => {
            setContractSelectionToDelete(null);
          }}
          onClose={() => {
            setContractSelectionToDelete(null);
          }}
        />
      )}
      {contractRevisionToUpdate && (
        <ContractRevisionUpdateModal
          tenantId={tenant.id}
          projectId={project.id}
          contract={contract}
          contractSelection={contractRevisionToUpdate}
          taxGroups={taxGroups}
          onCompleted={() => {
            setContractRevisionToUpdate(null);
          }}
          onCancel={() => {
            setContractRevisionToUpdate(null);
          }}
        />
      )}
      {contractRevisionToDelete && (
        <ContractRevisionDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          contractId={contract.id}
          revision={contractRevisionToDelete}
          onCompleted={() => {
            setContractRevisionToDelete(null);
          }}
          onClose={() => {
            setContractRevisionToDelete(null);
          }}
        />
      )}
    </ViewTab>
  );
};
