import "./index.scss";
import { FunctionComponent } from "react";
import { SelectPicker, IconButton } from "rsuite";
import { Outlet } from "react-router-dom";
import { ContainerLoader, Sidebar } from "buildingBlocks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { Types } from "types";
import { ProjectFragment, TenantFragment, TenantType } from "graphql/schema";
import { useSecurityContext } from "providers";
import { useRouting } from "hooks";

type Props = {
  readonly onHeaderClick: () => void;
  readonly isFullscreen?: boolean;
  readonly navs: ReadonlyArray<Types.SidebarNav>;
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly tenant: TenantFragment;
  readonly headerNav?: {
    readonly tenants: ReadonlyArray<TenantFragment>;
    readonly onTenantChange: (tenantId: string) => void;
    readonly onTenantClean: () => void;
    readonly projectId: string | null;
    readonly projects: ReadonlyArray<ProjectFragment>;
    readonly onProjectChange: (projectId: string) => void;
    readonly onProjectClean: () => void;
  };
  readonly onLogout: () => void;
};

export const Layout: FunctionComponent<Props> = ({ onHeaderClick, navs, tenant, headerNav, onLogout, disabled = false, loading = false, isFullscreen = false }) => {
  const { navigate, toTenantSettingsView } = useRouting();
  const { isAuthorized } = useSecurityContext();
  return (
    <section className={`layout${isFullscreen ? " layout-is-fullscreen" : " layout-has-sidebar"}`}>
      {!isFullscreen && <Sidebar onHeaderClick={onHeaderClick} disabled={disabled} navs={navs} />}
      <section>
        {!isFullscreen && (
          <nav>
            {headerNav && (
              <>
                <SelectPicker
                  id="layout-tenant-input"
                  label="Organisation"
                  placeholder="Tous"
                  size="sm"
                  data={headerNav.tenants.map((tenant) => ({
                    label: tenant.name,
                    value: tenant.id,
                  }))}
                  readOnly={disabled}
                  value={tenant.id}
                  style={{ width: 275 }}
                  onChange={(value) => {
                    if (value) {
                      headerNav.onTenantChange(value);
                    }
                  }}
                  onClean={headerNav.onTenantClean}
                />
                <SelectPicker
                  id="layout-project-input"
                  label="Projet"
                  placeholder="Tous"
                  size="sm"
                  data={headerNav.projects.map((project) => ({
                    label: project.label,
                    value: project.id,
                  }))}
                  readOnly={disabled}
                  value={headerNav.projectId}
                  style={{ width: 350 }}
                  onChange={(value) => {
                    if (value) {
                      headerNav.onProjectChange(value);
                    }
                  }}
                  onClean={headerNav.onProjectClean}
                />
              </>
            )}
            <div className="right">
              {headerNav && (
                <div className="nav">
                  <IconButton appearance="link" icon={<FontAwesomeIcon icon={faBell} />} disabled />
                  {tenant.type === TenantType.PRINCIPAL && (
                    <IconButton
                      appearance="link"
                      icon={<FontAwesomeIcon icon={faCog} />}
                      disabled={
                        disabled ||
                        !isAuthorized({
                          security: {
                            isTenantSettingsManager: true,
                          },
                        })
                      }
                      onClick={() => {
                        navigate(
                          toTenantSettingsView({
                            tenantId: tenant.id,
                          })
                        );
                      }}
                    />
                  )}
                </div>
              )}
              <div className={`logout${headerNav ? " logout-with-left-border" : ""}`}>
                <IconButton appearance="link" icon={<FontAwesomeIcon icon={faRightFromBracket} />} disabled={disabled} onClick={onLogout} />
              </div>
            </div>
          </nav>
        )}
        {loading ? <ContainerLoader /> : <Outlet />}
      </section>
    </section>
  );
};
