import "./index.scss";
import { FunctionComponent } from "react";
import DOMPurify from "dompurify";

type Props = {
  readonly html: string;
  readonly table?: boolean;
};

export const DocumentWrapper: FunctionComponent<Props> = ({ html, table = false }) => (
  <div
    className={`document-wrapper${table ? " document-wrapper-table" : ""}`}
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(html),
    }}
  />
);
