import "./index.scss";
import { FunctionComponent } from "react";
import {
  ContractState,
  CountrySubdivisionType,
  FormState,
  Gender,
  InvoiceItemType,
  InvoiceStatus,
  InvoiceTermsOfPayment,
  Language,
  ModelRoomElementType,
  PaymentMode,
  RefundMode,
  RoleTemplate,
  SectionElementOptionType,
  SectionElementType,
  SectionState,
  SectionVibeMode,
  ContractSelectionGroupState,
  ContractSelectionGroupVibeMode,
  ContractSelectionState,
  TemplateName,
  UnitState,
  CustomerType,
  ReportRoomElementDisplay,
  ContractSelectionWorkflow,
} from "graphql/schema";

type Appearances = "primary" | "secondary" | "warning" | "danger";

type Values =
  | Gender
  | Language
  | TemplateName
  | CountrySubdivisionType
  | RoleTemplate
  | FormState
  | SectionState
  | SectionElementType
  | SectionElementOptionType
  | SectionVibeMode
  | ModelRoomElementType
  | UnitState
  | ContractState
  | ContractSelectionState
  | ContractSelectionGroupState
  | ContractSelectionGroupVibeMode
  | InvoiceStatus
  | InvoiceTermsOfPayment
  | InvoiceItemType
  | PaymentMode
  | RefundMode
  | ReportRoomElementDisplay
  | CustomerType
  | string;

type AppearanceValues = {
  readonly value: Values;
  readonly appearance: Appearances;
};

type Props = {
  readonly value: Values;
  readonly plaintext?: boolean;
  readonly appearances?: ReadonlyArray<AppearanceValues>;
  readonly block?: boolean;
  readonly uppercase?: boolean;
  readonly width?: number;
  readonly bold?: boolean;
  readonly height?: number;
};

export const EnumLabel: FunctionComponent<Props> = ({ value, appearances, plaintext, block, bold, uppercase, width = undefined, height = undefined }) => {
  const appearance = getEnumAppearance(value, appearances);
  const label = getEnumLabel(value);
  return (
    <div
      className={`enum-label${appearance ? " enum-label-appearance-" + appearance : ""}${bold ? " enum-label-bold" : ""}${plaintext ? " enum-label-plaintext" : ""}${block ? " enum-label-block" : ""}${
        uppercase ? " enum-label-uppercase" : ""
      }`}
      style={{
        width: width,
        height: height,
      }}
    >
      {label}
    </div>
  );
};

const getEnumAppearance = (value: Values, appearances?: ReadonlyArray<AppearanceValues>) => {
  return appearances?.find((appearance) => appearance.value === value)?.appearance ?? "secondary";
};

export const getEnumLabelUppercase = (value: Values) => {
  return getEnumLabel(value).toLowerCase();
};

export const getEnumLabel = (value: Values) => {
  switch (value) {
    case ContractSelectionWorkflow.WITH_CUSTOMER: {
      return "Avec client";
    }
    case ContractSelectionWorkflow.WITHOUT_CUSTOMER: {
      return "Sans client";
    }
    case Gender.NONE: {
      return "Aucun";
    }
    case Gender.MALE: {
      return "Homme";
    }
    case Gender.FEMALE: {
      return "Femme";
    }
    case Language.FR: {
      return "Français";
    }
    case Language.EN: {
      return "Anglais";
    }
    case TemplateName.BULK_CREATE_UNITS: {
      return "Créer des unités en vrac";
    }
    case CountrySubdivisionType.STATE: {
      return "État";
    }
    case CountrySubdivisionType.PROVINCE: {
      return "Province";
    }
    case CountrySubdivisionType.TERRITORY: {
      return "Territoire";
    }
    case CountrySubdivisionType.DISTRICT: {
      return "District";
    }
    case CountrySubdivisionType.OUTLYING_AREA: {
      return "Zone";
    }
    case RoleTemplate.NONE: {
      return "Aucun";
    }
    case RoleTemplate.PROJECT_ADMINISTRATOR: {
      return "Administrateur de projet";
    }
    case RoleTemplate.PROJECT_MANAGER: {
      return "Gestionnaire de projet";
    }
    case RoleTemplate.READER: {
      return "Lecture seule";
    }
    case RoleTemplate.SELLER: {
      return "Vendeur";
    }
    case FormState.CREATED: {
      return "Brouillon";
    }
    case FormState.PUBLISHED: {
      return "Disponible";
    }
    case FormState.ARCHIVED: {
      return "Archivé";
    }
    case SectionState.CREATED: {
      return "Brouillon";
    }
    case SectionState.CONFIGURED: {
      return "Configuré";
    }
    case SectionState.PUBLISHED: {
      return "Disponible";
    }
    case SectionState.ARCHIVED: {
      return "Archivé";
    }
    case SectionElementType.NONE: {
      return "Inconnu";
    }
    case SectionElementType.BOOLEAN: {
      return "Booléen";
    }
    case SectionElementType.UNIQUE: {
      return "Choix unique";
    }
    case SectionElementType.READONLY: {
      return "Lecture seule";
    }
    case SectionElementOptionType.INCLUDED: {
      return "Inclus";
    }
    case SectionElementOptionType.EXCLUDED: {
      return "Exclu";
    }
    case SectionElementOptionType.MIX_AND_MATCH: {
      return "Mix and match";
    }
    case SectionElementOptionType.EXTRA: {
      return "Extra";
    }
    case SectionElementOptionType.CREDIT_SUBCONTRACTOR: {
      return "Crédit (sous-traitant)";
    }
    case SectionVibeMode.STANDARD: {
      return "Standard";
    }
    case SectionVibeMode.UPGRADE: {
      return "Mise à niveau";
    }
    case ModelRoomElementType.NONE: {
      return "Inconnu";
    }
    case ModelRoomElementType.MATERIAL: {
      return "Matériel";
    }
    case ModelRoomElementType.ACCESSORY: {
      return "Accessoire";
    }
    case UnitState.NO_CONTRACT: {
      return "Disponible";
    }
    case UnitState.CONTRACT_IS_CUSTOMIZED: {
      return "En personalisation";
    }
    case UnitState.CONTRACT_WITHOUT_FORM: {
      return "Sans formulaire";
    }
    case UnitState.CONTRACT_WITHOUT_SELECTION: {
      return "Sans sélection";
    }
    case UnitState.CONTRACT_SELECTION_PENDING: {
      return "Sélection en attente";
    }
    case UnitState.CONTRACT_REVISION_PENDING: {
      return "Révision en attente";
    }
    case UnitState.CONTRACT_PAYMENT_PENDING: {
      return "Paiement en attente";
    }
    case UnitState.CONTRACT_REFUND_PENDING: {
      return "Remboursement en attente";
    }
    case UnitState.CONTRACT_COMPLETED: {
      return "Terminé";
    }
    case ContractState.IS_CUSTOMIZED: {
      return "En personalisation";
    }
    case ContractState.WITHOUT_FORM: {
      return "Sans formulaire";
    }
    case ContractState.WITHOUT_SELECTION: {
      return "Sans sélection";
    }
    case ContractState.SELECTION_PENDING: {
      return "Sélection en attente";
    }
    case ContractState.REVISION_PENDING: {
      return "Révision en attente";
    }
    case ContractState.PAYMENT_PENDING: {
      return "Paiement en attente";
    }
    case ContractState.REFUND_PENDING: {
      return "Remboursement en attente";
    }
    case ContractState.COMPLETED: {
      return "Terminé";
    }
    case ContractSelectionState.PENDING: {
      return "En attente";
    }
    case ContractSelectionState.FINISHED: {
      return "Terminé";
    }
    case ContractSelectionGroupState.PENDING: {
      return "En attente";
    }
    case ContractSelectionGroupState.COMPLETED: {
      return "Complété";
    }
    case ContractSelectionGroupVibeMode.STANDARD: {
      return "Standard";
    }
    case ContractSelectionGroupVibeMode.MIX_AND_MATCH: {
      return "Mix and match";
    }
    case ContractSelectionGroupVibeMode.UPGRADE: {
      return "Mise à niveau";
    }
    case InvoiceStatus.NO_CHARGE: {
      return "Sans frais";
    }
    case InvoiceStatus.PENDING: {
      return "En attente";
    }
    case InvoiceStatus.PAID: {
      return "Payé";
    }
    case InvoiceStatus.REFUNDED: {
      return "Remboursé";
    }
    case InvoiceStatus.CREDITED: {
      return "Crédité";
    }
    case InvoiceStatus.CANCELED: {
      return "Annulé";
    }
    case InvoiceTermsOfPayment.NET0: {
      return "Payable à réception";
    }
    case InvoiceTermsOfPayment.NET7: {
      return "7 net";
    }
    case InvoiceTermsOfPayment.NET15: {
      return "15 net";
    }
    case InvoiceTermsOfPayment.NET21: {
      return "21 net";
    }
    case InvoiceTermsOfPayment.NET30: {
      return "30 net";
    }
    case InvoiceTermsOfPayment.NET45: {
      return "45 net";
    }
    case InvoiceTermsOfPayment.NET60: {
      return "60 net";
    }
    case InvoiceItemType.OTHER: {
      return "Autre";
    }
    case InvoiceItemType.MIX_AND_MATCH: {
      return "Mix and match";
    }
    case InvoiceItemType.EXTRA: {
      return "Extra";
    }
    case PaymentMode.BANK_TRANSFER: {
      return "Virement";
    }
    case PaymentMode.CASH: {
      return "Espèce";
    }
    case PaymentMode.CHECK: {
      return "Chèque";
    }
    case PaymentMode.CREDIT_CARD: {
      return "Carte de crédit";
    }
    case RefundMode.BANK_TRANSFER: {
      return "Virement";
    }
    case RefundMode.CASH: {
      return "Espèce";
    }
    case RefundMode.CHECK: {
      return "Chèque";
    }
    case RefundMode.CREDIT_CARD: {
      return "Carte de crédit";
    }
    case CustomerType.INDIVIDUAL: {
      return "Particulier";
    }
    case CustomerType.BUSINESS: {
      return "Entreprise";
    }
    case ReportRoomElementDisplay.DEFAULT: {
      return "Par défaut";
    }
    case ReportRoomElementDisplay.EXTRA_ONLY: {
      return "Extra seulement";
    }
    default: {
      return value;
    }
  }
};
