import "./index.scss";
import { FunctionComponent, useState } from "react";
import { SelectPicker, Tag } from "rsuite";
import { EnumLabel, FormTable, Text, TooltipWrapper } from "buildingBlocks";
import produce from "immer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type FormReportLinkFormValues = {
  readonly reportVibeRoomLinks: ReadonlyArray<{
    readonly formSectionId: string;
    readonly reportRoomId: string;
  }>;
  readonly reportRoomElementLinks: ReadonlyArray<{
    readonly formSectionId: string;
    readonly sectionElementId: string;
    readonly reportRoomId: string;
    readonly reportRoomElementId: string;
  }>;
};

type Props = {
  readonly values: FormReportLinkFormValues;
  readonly setValues: (values: FormReportLinkFormValues) => void;
  readonly form: ProjectProviderTypes.MappedProjectForm;
  readonly report: ProjectProviderTypes.MappedProjectReport;
};

export const useFormReportLinkForm = (initialValues: FormReportLinkFormValues) => {
  const [values, setValues] = useState<FormReportLinkFormValues>(initialValues);
  return { values, setValues };
};

type ReportRoomElement = {
  readonly id: string;
  readonly name: string;
  readonly roomId: string;
  readonly roomName: string;
  readonly label: string;
};

export const FormReportLinkForm: FunctionComponent<Props> = ({ values, setValues, form, report }) => {
  const reportRoomIds = values.reportVibeRoomLinks.map((link) => link.reportRoomId);
  const reportRoomElements = report.rooms.reduce<ReportRoomElement[]>((previousValue, currentValue) => {
    const elements: ReportRoomElement[] = [];
    for (const element of currentValue.elements) {
      elements.push({
        roomId: currentValue.id,
        id: element.id,
        name: element.name,
        roomName: currentValue.name,
        label: `${currentValue.name} | ${element.name}`,
      });
    }
    return previousValue.concat(elements);
  }, []);
  return (
    <FormTable
      id="form-report-link-form"
      table={{
        headColumns: [
          {
            name: "index",
            value: "#",
          },
          {
            name: "label",
            value: "TITRE / ÉTIQUETTE",
          },
          {
            name: "is-valid",
            value: "",
          },
          {
            name: "type",
            value: "TYPE",
          },
          {
            name: "input",
            value: "PIÈCE / ÉLÉMENT",
          },
        ],
        bodyRows: [],
        bodyRowGroups: form.sections.map((formSection) => {
          const reportRoomId = values.reportVibeRoomLinks.find((link) => link.formSectionId === formSection.id)?.reportRoomId || null;
          return {
            key: formSection.id,
            actions: [],
            columns: [
              {
                name: "index",
                component: <Tag className="form-section-index">{formSection.index + 1}</Tag>,
              },
              {
                name: "label",
                component: formSection.title,
              },
              {
                name: "is-valid",
                component:
                  formSection.section.vibes.length !== 0 && reportRoomId === null ? (
                    <TooltipWrapper tooltip="Une pièce du rapport doit être associé à cette section qui est géré par des ambiances." highlight>
                      <FontAwesomeIcon color="red" icon={faWarning} />
                    </TooltipWrapper>
                  ) : null,
              },
              {
                name: "type",
                component: "",
              },
              {
                name: "input",
                component:
                  formSection.section.vibes.length !== 0 ? (
                    <SelectPicker
                      value={reportRoomId}
                      onChange={(value) => {
                        setValues(
                          produce(values, (draft) => {
                            const draftLink = draft.reportVibeRoomLinks.find((link) => link.formSectionId === formSection.id);
                            if (draftLink) {
                              draftLink.reportRoomId = value!;
                            }
                          })
                        );
                      }}
                      cleanable
                      onClean={() => {
                        setValues(
                          produce(values, (draft) => {
                            const draftLink = draft.reportVibeRoomLinks.find((link) => link.formSectionId === formSection.id);
                            if (draftLink) {
                              draftLink.reportRoomId = "";
                            }
                          })
                        );
                      }}
                      data={report.rooms
                        .filter((reportRoom) => reportRoom.id === reportRoomId || !reportRoomIds.includes(reportRoom.id))
                        .map((reportRoom) => ({
                          label: reportRoom.name,
                          value: reportRoom.id,
                        }))}
                    />
                  ) : (
                    ""
                  ),
              },
            ],
            rows: formSection.section.getElementsWithoutSets().map((sectionElement) => {
              const reportRoomElementId =
                values.reportRoomElementLinks.find((link) => link.formSectionId === formSection.id && link.sectionElementId === sectionElement.id)?.reportRoomElementId || null;
              const reportRoomElementIds = values.reportRoomElementLinks.map((x) => x.reportRoomElementId);
              return {
                key: sectionElement.id,
                actions: [],
                columns: [
                  {
                    name: "index",
                    component: <Tag className="section-element-index">{sectionElement.index + 1}</Tag>,
                  },
                  {
                    name: "label",
                    component: <Text highlight>{sectionElement.group ? `${sectionElement.group.name} - ${sectionElement.label}` : sectionElement.label}</Text>,
                  },
                  {
                    name: "is-valid",
                    component:
                      reportRoomElementId === null ? (
                        <TooltipWrapper tooltip="Un élément d'une pièce du rapport doit être associé à cet élément de section." highlight>
                          <FontAwesomeIcon color="red" icon={faWarning} />
                        </TooltipWrapper>
                      ) : null,
                  },
                  {
                    name: "type",
                    component: <EnumLabel value={sectionElement.type} width={150} uppercase />,
                  },
                  {
                    name: "input",
                    component: (
                      <SelectPicker
                        value={reportRoomElementId}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftReportRoomElementLink = draft.reportRoomElementLinks.find(
                                (draftReportRoomElementLink) => draftReportRoomElementLink.formSectionId === formSection.id && draftReportRoomElementLink.sectionElementId === sectionElement.id
                              );
                              if (draftReportRoomElementLink) {
                                const reportRoomElement = reportRoomElements.find((reportRoomElement) => reportRoomElement.id === value);
                                if (reportRoomElement) {
                                  draftReportRoomElementLink.reportRoomId = reportRoomElement.roomId;
                                  draftReportRoomElementLink.reportRoomElementId = reportRoomElement.id;
                                }
                              }
                            })
                          );
                        }}
                        cleanable
                        onClean={() => {
                          setValues(
                            produce(values, (draft) => {
                              const draftReportRoomElementLink = draft.reportRoomElementLinks.find(
                                (draftReportRoomElementLink) => draftReportRoomElementLink.formSectionId === formSection.id && draftReportRoomElementLink.sectionElementId === sectionElement.id
                              );
                              if (draftReportRoomElementLink) {
                                draftReportRoomElementLink.reportRoomId = "";
                                draftReportRoomElementLink.reportRoomElementId = "";
                              }
                            })
                          );
                        }}
                        valueKey="id"
                        groupBy="roomName"
                        labelKey="label"
                        data={reportRoomElements.filter((reportRoomElement) => reportRoomElement.id === reportRoomElementId || !reportRoomElementIds.includes(reportRoomElement.id))}
                      />
                    ),
                  },
                ],
              };
            }),
          };
        }),
      }}
    />
  );
};
