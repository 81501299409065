import "./index.scss";
import { FunctionComponent, useCallback, useState } from "react";
import { SearchInputGroup, TooltipWrapper, ViewList, Text } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext, useSecurityContext } from "providers";
import { PermissionName } from "graphql/schema";
import { SelectPicker } from "rsuite";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Types } from "types";

type DocumentGroup = {
  readonly name: string;
  readonly documents: ReadonlyArray<Document>;
};

type Document = {
  readonly name: string;
  readonly description: string;
  readonly href: string | undefined;
  readonly permissions: Types.Permissions;
};

const useProjectDocumentsViewFilters = () => {
  const { isAuthorized } = useSecurityContext();
  const { searchParams } = useRouting();
  const [search, setSearch] = useState<string>(searchParams.get("search") ?? "");
  return {
    filterValues: {
      search,
    },
    filterFunctions: {
      setSearch,
    },
    applyFilters: useCallback(
      (documentGroups: ReadonlyArray<DocumentGroup>) =>
        documentGroups
          .filter((documentGroup) =>
            documentGroup.documents.some((document) =>
              isAuthorized({
                security: {
                  permissions: document.permissions,
                },
              })
            )
          )
          .filter((documentGroup) => documentGroup.documents.some((document) => document.name.toSearchFormat().includes(search.toSearchFormat())))
          .map((documentGroup) => ({
            ...documentGroup,
            documents: documentGroup.documents
              .filter((document) =>
                isAuthorized({
                  security: {
                    permissions: document.permissions,
                  },
                })
              )
              .filter((document) => document.name.toSearchFormat().includes(search.toSearchFormat())),
          })),
      [isAuthorized, search]
    ),
  };
};

export const ProjectDocumentsView: FunctionComponent = () => {
  const { filterValues, filterFunctions, applyFilters } = useProjectDocumentsViewFilters();
  const { navigate, toProjectView, toProjectDocumentSummaryReportView, toProjectDocumentConstructionReportView, toProjectDocumentCustomerReportView, toProjectDocumentBillingReportView } =
    useRouting();
  const { tenant } = useTenantContext();
  const { project, reports, units } = useProjectContext();
  const [reportId, setReportId] = useState<string>(project.defaultReportId);
  const unit = units.find(() => true) ?? undefined;
  const documentGroups: DocumentGroup[] = [
    {
      name: "Contrats",
      documents: [
        {
          name: "Résumé",
          description: "Résumé complet de toutes les sélections des clients.",
          href: unit
            ? toProjectDocumentSummaryReportView({
                tenantId: tenant.id,
                projectId: project.id,
                reportId: reportId,
              })
            : undefined,
          permissions: [[PermissionName.DOCUMENT_SUMMARY_REPORT_READ]],
        },
        {
          name: "Fiche de construction",
          description: "Représentation d'une sélection d'un client pour le chantier.",
          href: unit
            ? toProjectDocumentConstructionReportView({
                tenantId: tenant.id,
                projectId: project.id,
                reportId: reportId,
                unitId: unit.id,
              })
            : undefined,
          permissions: [[PermissionName.DOCUMENT_CONSTRUCTION_REPORT_READ]],
        },
        {
          name: "Fiche du client",
          description: "Représentation de la sélection d'un client pour ces dossiers.",
          href: unit
            ? toProjectDocumentCustomerReportView({
                tenantId: tenant.id,
                projectId: project.id,
                reportId: reportId,
                unitId: unit.id,
              })
            : undefined,
          permissions: [[PermissionName.DOCUMENT_CUSTOMER_REPORT_READ]],
        },
      ],
    },
    {
      name: "Ventes",
      documents: [
        {
          name: "Facturation",
          description: "Une liste des éléments facturés pour chaque unité.",
          href: unit
            ? toProjectDocumentBillingReportView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            : undefined,
          permissions: [[PermissionName.DOCUMENT_BILLING_REPORT_READ]],
        },
      ],
    },
  ];
  return (
    <ViewList
      id="project-documents-view"
      security={{
        permissions: [
          [PermissionName.DOCUMENT_CONSTRUCTION_REPORT_READ],
          [PermissionName.DOCUMENT_CUSTOMER_REPORT_READ],
          [PermissionName.DOCUMENT_BILLING_REPORT_READ],
          [PermissionName.DOCUMENT_SUMMARY_REPORT_READ],
        ],
      }}
      header={{
        title: "Tous les documents",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [<SearchInputGroup size="xs" placeholder="Recherche par nom" value={filterValues.search} onChange={filterFunctions.setSearch} />],
            applyButton: null,
          },
          right: {
            inputs: [
              <SelectPicker
                size="xs"
                label="Rapport"
                value={reportId}
                searchable={false}
                onChange={(value) => {
                  if (reportId !== value) {
                    setReportId(value!);
                  }
                }}
                cleanable={false}
                data={reports.map((report) => ({
                  label: report.name,
                  value: report.id,
                }))}
              />,
            ],
          },
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "name",
              value: "NOM",
            },
            {
              name: "is-valid",
              value: "",
            },
            {
              name: "description",
              value: "DESCRIPTION",
            },
          ],
          bodyRows: [],
          bodyRowGroups: applyFilters(documentGroups).map((documentGroup, documentGroupIndex) => {
            return {
              key: documentGroupIndex.toString(),
              actions: [],
              columns: [
                {
                  name: "name",
                  component: documentGroup.name,
                },
              ],
              rows: documentGroup.documents.map((document, documentIndex) => ({
                key: documentIndex.toString(),
                href: document.href,
                actions: [],
                columns: [
                  {
                    name: "name",
                    component: <Text highlight>{document.name}</Text>,
                  },
                  {
                    name: "is-valid",
                    component:
                      units.length === 0 ? (
                        <TooltipWrapper tooltip="Ajouter des unités dans le projet pour accéder au rapport." highlight>
                          <FontAwesomeIcon color="red" icon={faWarning} />
                        </TooltipWrapper>
                      ) : (
                        ""
                      ),
                  },
                  {
                    name: "description",
                    component: document.description,
                  },
                ],
              })),
            };
          }),
        },
      }}
    />
  );
};
