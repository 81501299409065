import "./index.scss";
import { Fragment, FunctionComponent, PropsWithChildren, ReactNode, useState } from "react";
import { useEnsureSecurity } from "hooks";
import { Table, TableBody, TableColumn, TableColumnActions, TableContent, TableHead, TableRow, HeaderToolbar, HeaderFilter, HeaderOverview } from "buildingBlocks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "rsuite";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Types } from "types";

type Props = PropsWithChildren & {
  readonly id: string;
  readonly security: Types.Security;
  readonly header: {
    readonly subtitle?: string;
    readonly title: ReactNode;
    readonly returnButton?: {
      readonly onClick: () => void;
    };
    readonly toolbar: Types.HeaderToolbar;
    readonly filter?: Types.HeaderFilter;
    readonly overview?: Types.HeaderOverview;
  };
  readonly content: {
    readonly table: Types.Table;
  };
};

export const ViewList: FunctionComponent<Props> = ({ id, security, header, content, children }) => {
  useEnsureSecurity(security);
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);
  return (
    <section id={id} className={`view-list${header.overview && header.overview.cards.length !== 0 ? " view-list-has-overview" : ""}${header.filter ? " view-list-has-filter" : ""}`}>
      <header>
        <div className="left">
          <div>
            {header.returnButton && <IconButton className="btn-return" size="sm" icon={<FontAwesomeIcon icon={faAngleLeft} />} appearance="default" onClick={header.returnButton.onClick} />}
            <div>
              {header.subtitle && <small className="subtitle">{header.subtitle}</small>}
              <h1>{header.title}</h1>
            </div>
          </div>
          <HeaderToolbar buttons={header.toolbar.buttons} otherActions={header.toolbar.otherActions} />
        </div>
        {header.overview && header.overview.cards.length !== 0 && <HeaderOverview cards={header.overview.cards} />}
        {header.filter && <HeaderFilter leftInputs={header.filter.left.inputs} rightInputs={header.filter.right.inputs} applyButton={header.filter.left.applyButton} />}
      </header>
      {children}
      <main>
        <Table fluid size="md">
          <TableContent>
            <TableHead>
              <TableRow hasOverflow={hasOverflow}>
                {content.table.headColumns.map((column, index) => (
                  <TableColumn key={index} name={column.name}>
                    {column.value.toUpperCase()}
                  </TableColumn>
                ))}
                <TableColumnActions />
              </TableRow>
            </TableHead>
            <TableBody onResize={setHasOverflow} emptyBodies={content.table.emptyBodies}>
              {content.table.bodyRows.map((row) => (
                <TableRow key={row.key} href={row.href}>
                  {row.columns.map((column) => (
                    <TableColumn key={column.name} name={column.name} href={row.href}>
                      {column.component}
                    </TableColumn>
                  ))}
                  <TableColumnActions actions={row.actions} />
                </TableRow>
              ))}
              {content.table.bodyRowGroups.map((group) => (
                <Fragment key={group.key}>
                  <TableRow highlight href={group.href}>
                    {group.columns.map((column) => (
                      <TableColumn key={column.name} name={column.name} href={group.href}>
                        {column.component}
                      </TableColumn>
                    ))}
                    <TableColumnActions actions={group.actions} />
                  </TableRow>
                  {group.rows.length === 0 && group.rowEmpty && <TableRow empty={group.rowEmpty} />}
                  {group.rows.map((row) => (
                    <TableRow key={row.key} href={row.href}>
                      {row.columns.map((column) => (
                        <TableColumn key={column.name} name={column.name} href={row.href}>
                          {column.component}
                        </TableColumn>
                      ))}
                      <TableColumnActions actions={row.actions} />
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </TableContent>
        </Table>
      </main>
    </section>
  );
};
