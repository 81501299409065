import "./index.scss";
import { useCancelContractMutation } from "graphql/mutations";
import { FunctionComponent, useState } from "react";
import { FormContainer, ModalForm } from "buildingBlocks";
import { Form, Input, Tag, Toggle } from "rsuite";
import produce from "immer";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly tenantId: string;
  readonly projectId: string;
  readonly contract: ProjectProviderTypes.MappedProjectContract;
  readonly onCompleted: () => void;
  readonly onCancel: () => void;
};

export const ContractCancelModal: FunctionComponent<Props> = ({ tenantId, projectId, contract, onCompleted, onCancel }) => {
  const [values, setValues] = useState<{
    readonly objectValue: string;
    readonly isRefundable: boolean;
  }>({
    objectValue: "",
    isRefundable: false,
  });
  const { cancelContract, cancelContractLoading, cancelContractErrors } = useCancelContractMutation(onCompleted, onCancel, true);
  return (
    <ModalForm
      id="contract-cancel-modal"
      title="Annuler le contrat"
      errors={cancelContractErrors}
      loading={cancelContractLoading}
      disabled={values.objectValue === contract.unit.label}
      submitButton={{
        text: "Annuler",
        onClick: async () => {
          await cancelContract({
            tenantId: tenantId,
            projectId: projectId,
            contractId: contract.id,
            isRefundable: values.isRefundable,
          });
        },
      }}
      closeButton={{
        text: "Fermer",
        onClick: onCancel,
      }}
    >
      <Form id="contract-cancel-form">
        <FormContainer withoutBorder highlight>
          <Form.Group>
            <p className="dialog-danger-zone">Vous êtes sur le point de faire une action définitive. Cette action est irréversible et ne peut pas être annulée.</p>
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Est remboursable</Form.ControlLabel>
            <Toggle
              checked={values.isRefundable}
              checkedChildren="OUI"
              unCheckedChildren="NON"
              onChange={(checked) => {
                setValues(
                  produce(values, (draft) => {
                    draft.isRefundable = checked;
                  })
                );
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>
              Pour confirmer l'annulation, veuillez écrire{" "}
              <Tag className="dialog-object-name" size="sm">
                {contract.unit.label}
              </Tag>{" "}
              ci-dessous.
            </Form.ControlLabel>
            <Input
              value={values.objectValue}
              onChange={(value) => {
                setValues(
                  produce(values, (draft) => {
                    draft.objectValue = value;
                  })
                );
              }}
              size="md"
            />
          </Form.Group>
        </FormContainer>
      </Form>
    </ModalForm>
  );
};
