import "./index.scss";
import { FunctionComponent } from "react";
import { ViewForm } from "buildingBlocks";
import { useRouting, useProjectProductContext } from "hooks";
import { useUpdateProductMutation } from "graphql/mutations";
import { ProductForm, useProductForm } from "forms";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";

export const ProjectProductUpdateView: FunctionComponent = () => {
  const { navigate, toProjectProductView } = useRouting();
  const { tenant, providers } = useTenantContext();
  const { project, activities, productCategories } = useProjectContext();
  const { product } = useProjectProductContext();
  const { values, setValues } = useProductForm({
    categoryId: product.categoryId,
    variant: product.variant ?? "",
    format: product.format ?? "",
    note: product.note ?? "",
    description: product.description ?? "",
    managedByModels: product.managedByModels,
    usedBySections: product.usedBySections,
    hasThumbnail: product.hasThumbnail,
    thumbnailUrl: product.thumbnailUrl,
    thumbnail: undefined,
    provider: {
      id: product.provider.id,
      productModel: product.provider.productModel ?? "",
      productCode: product.provider.productCode ?? "",
      productUrl: product.provider.productUrl ?? "",
    },
    activities: product.activities.map((productActivity) => ({
      index: productActivity.index,
      activityId: productActivity.activityId,
    })),
  });
  const { updateProduct, updateProductLoading, updateProductErrors } = useUpdateProductMutation(({ tenantId, projectId, product }) => {
    navigate(
      toProjectProductView({
        tenantId: tenantId,
        projectId: projectId,
        productId: product.id,
      })
    );
  });
  return (
    <ViewForm
      id="project-product-update-view"
      title="Modifier le produit"
      security={{
        permissions: [[PermissionName.PRODUCT_READ, PermissionName.PRODUCT_UPDATE]],
      }}
      errors={updateProductErrors}
      loading={updateProductLoading}
      onSubmit={async () => {
        await updateProduct({
          tenantId: tenant.id,
          projectId: project.id,
          productId: product.id,
          variant: values.variant,
          format: values.format.trim() || null,
          note: values.note.trim() || null,
          description: values.description.trim() || null,
          thumbnail: values.thumbnail ?? null,
          categoryId: values.categoryId,
          provider: {
            id: values.provider.id,
            productModel: values.provider.productModel.trim() || null,
            productCode: values.provider.productCode.trim() || null,
            productUrl: values.provider.productUrl.trim() || null,
          },
          activities: values.activities.map((productActivity) => ({
            index: productActivity.index,
            activityId: productActivity.activityId,
          })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectProductView({
            tenantId: tenant.id,
            projectId: project.id,
            productId: product.id,
          })
        );
      }}
    >
      <ProductForm values={values} setValues={setValues} activities={activities} providers={providers} productCategories={productCategories} />
    </ViewForm>
  );
};
