import { FunctionComponent } from "react";
import { useCreateUnitMutation } from "graphql/mutations";
import { UnitForm, useUnitForm } from "forms";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { ViewForm } from "buildingBlocks";
import { PermissionName } from "graphql/schema";

export const ProjectUnitCreateView: FunctionComponent = () => {
  const { navigate, toProjectView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, units, forms } = useProjectContext();
  const { values, setValues } = useUnitForm({
    number: "",
    floor: Math.max(...units.map((unit) => unit.floor)),
    bedroomCount: 1,
    bathroomCount: 1,
    halfBathroomCount: 0,
    formId: null,
  });
  const { createUnit, createUnitLoading, createUnitErrors } = useCreateUnitMutation(({ tenantId, projectId }) => {
    navigate(
      toProjectView({
        tenantId: tenantId,
        projectId: projectId,
      })
    );
  });
  return (
    <ViewForm
      id="project-unit-create-view"
      title="Créer une unité"
      security={{
        permissions: [[PermissionName.UNIT_READ, PermissionName.UNIT_CREATE]],
      }}
      errors={createUnitErrors}
      loading={createUnitLoading}
      onSubmit={async () => {
        await createUnit({
          tenantId: tenant.id,
          projectId: project.id,
          number: values.number,
          floor: values.floor,
          bedroomCount: values.bedroomCount,
          bathroomCount: values.bathroomCount,
          halfBathroomCount: values.halfBathroomCount,
          formId: values.formId,
        });
      }}
      onCancel={() => {
        navigate(
          toProjectView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <UnitForm values={values} setValues={setValues} forms={forms} />
    </ViewForm>
  );
};
