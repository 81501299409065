import "./index.scss";
import { FunctionComponent } from "react";
import { Tag, ViewList, Text, TooltipWrapper } from "buildingBlocks";
import { useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ProjectReportsView: FunctionComponent = () => {
  const { navigate, toProjectView, toProjectReportView, toProjectReportUpdateView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, reports } = useProjectContext();
  return (
    <ViewList
      id="project-reports-view"
      security={{
        permissions: [[PermissionName.REPORT_READ]],
      }}
      header={{
        title: "Tous les rapports",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [],
          otherActions: [],
        },
      }}
      content={{
        table: {
          headColumns: [
            {
              name: "index",
              value: "#",
            },
            {
              name: "name",
              value: "NOM",
            },
          ],
          bodyRows: reports.map((report) => {
            return {
              key: report.id,
              href: toProjectReportView({
                tenantId: tenant.id,
                projectId: project.id,
                reportId: report.id,
              }),
              columns: [
                {
                  name: "index",
                  component: <Tag className="report-index">{report.index + 1}</Tag>,
                },
                {
                  name: "name",
                  component: (
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      {report.isDefault && (
                        <TooltipWrapper tooltip="Verrouillé">
                          <FontAwesomeIcon color="grey" size="sm" icon={faLock} />
                        </TooltipWrapper>
                      )}
                      <Text highlight>{report.name}</Text>
                    </div>
                  ),
                },
              ],
              actions: [
                {
                  __type: "Button",
                  content: "Modifier le rapport",
                  show: true,
                  security: {
                    permissions: [[PermissionName.REPORT_UPDATE]],
                  },
                  onClick: () => {
                    navigate(
                      toProjectReportUpdateView({
                        tenantId: tenant.id,
                        projectId: project.id,
                        reportId: report.id,
                      })
                    );
                  },
                },
              ],
            };
          }),
          bodyRowGroups: [],
        },
      }}
    ></ViewList>
  );
};
