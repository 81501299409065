import "./index.scss";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ContainerLoader, ViewReport } from "buildingBlocks";
import { useFile, useProjectContractContext, useProjectContractSelectionContext, useRouting } from "hooks";
import { ContractSelectionState, PermissionName } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { useLoadContractSelectionDocumentLazyQuery } from "graphql/queries";
import { DocumentWrapper } from "buildingBlocks/DocumentWrapper";
import { useDownloadContractSelectionDocumentAsPdfMutation } from "graphql/mutations";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ProjectContractSelectionView: FunctionComponent = () => {
  const { navigate, toProjectContractSelectionsView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const { contractSelection } = useProjectContractSelectionContext();
  const [html, setHtml] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { names, printFromBase64, saveAsFromBase64 } = useFile();
  const { downloadContractSelectionDocumentAsPdf, downloadContractSelectionDocumentAsPdfLoading } = useDownloadContractSelectionDocumentAsPdfMutation(({ base64Data, print }) => {
    if (print) {
      printFromBase64({
        data: base64Data,
      });
    } else {
      saveAsFromBase64({
        data: base64Data,
        fileName: names.forPdfContractSelection(),
      });
    }
  });
  const { loadContractSelectionDocument, contractSelectionDocumentLoading } = useLoadContractSelectionDocumentLazyQuery(({ html }) => {
    setHtml(html);
    setIsLoaded(true);
  });
  const execute = useCallback(async () => {
    await loadContractSelectionDocument({
      variables: {
        tenantId: tenant.id,
        projectId: project.id,
        contractId: contract.id,
        contractSelectionId: contractSelection.id,
      },
    });
  }, [tenant, project, contract, contractSelection, loadContractSelectionDocument]);
  useEffect(() => {
    execute();
    // eslint-disable-next-line
  }, []);
  if (!isLoaded || html === null || contractSelectionDocumentLoading) {
    return <ContainerLoader />;
  }
  return (
    <ViewReport
      id="project-contract-selection-view"
      security={{
        permissions: [[PermissionName.CONTRACT_READ]],
      }}
      header={{
        title: contractSelection.label,
        subtitle: contract.unit.label,
        returnButton: {
          onClick: () => {
            navigate(
              toProjectContractSelectionsView({
                tenantId: tenant.id,
                projectId: project.id,
                contractId: contract.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Dropdown",
              appearance: "default",
              size: "sm",
              title: "Exporter",
              trigger: "click",
              placement: "bottomEnd",
              disabled: downloadContractSelectionDocumentAsPdfLoading,
              show: contractSelection.state === ContractSelectionState.FINISHED,
              security: {
                permissions: [[PermissionName.CONTRACT_READ]],
              },
              items: [
                {
                  __type: "DropdownItem",
                  text: "Télécharger (PDF)",
                  disabled: downloadContractSelectionDocumentAsPdfLoading,
                  show: contractSelection.state === ContractSelectionState.FINISHED,
                  security: {
                    permissions: [[PermissionName.CONTRACT_READ]],
                  },
                  onClick: () => {
                    downloadContractSelectionDocumentAsPdf({
                      tenantId: tenant.id,
                      projectId: project.id,
                      contractId: contract.id,
                      contractSelectionId: contractSelection.id,
                      print: false,
                    });
                  },
                },
              ],
            },
            {
              __type: "Button",
              appearance: "default",
              text: <FontAwesomeIcon icon={faPrint} />,
              show: contractSelection.state === ContractSelectionState.FINISHED,
              loading: downloadContractSelectionDocumentAsPdfLoading,
              security: {
                permissions: [[PermissionName.CONTRACT_READ]],
              },
              onClick: () => {
                downloadContractSelectionDocumentAsPdf({
                  tenantId: tenant.id,
                  projectId: project.id,
                  contractId: contract.id,
                  contractSelectionId: contractSelection.id,
                  print: true,
                });
              },
            },
          ],
          otherActions: [],
        },
      }}
    >
      <DocumentWrapper html={html} />
    </ViewReport>
  );
};
