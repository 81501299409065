import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Form, Input } from "rsuite";
import { FormContainer } from "buildingBlocks";
import produce from "immer";

export type SectionCloneFormValues = {
  readonly title: string;
};

type Props = {
  readonly values: SectionCloneFormValues;
  readonly setValues: (values: SectionCloneFormValues) => void;
};

export const useSectionCloneForm = (initialValues: SectionCloneFormValues) => {
  const [values, setValues] = useState<SectionCloneFormValues>(initialValues);
  return { values, setValues };
};

export const SectionCloneForm: FunctionComponent<Props> = ({ values, setValues }) => (
  <Form id="section-clone-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Titre de la section</Form.ControlLabel>
        <Input
          value={values.title}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.title = value;
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
  </Form>
);
