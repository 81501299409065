import "./index.scss";
import { FunctionComponent, useState } from "react";
import { Panel, PanelControl, ViewDetail, Link } from "buildingBlocks";
import { useProjectProductContext, useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { PermissionName } from "graphql/schema";
import { ProductDeleteDialog } from "dialogs";
import { ProductThumbnail } from "components";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

export const ProjectProductView: FunctionComponent = () => {
  const { navigate, toTenantProviderView, toProjectProductsView, toProjectProductUpdateView } = useRouting();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { product } = useProjectProductContext();
  const [productToDelete, setProductToDelete] = useState<ProjectProviderTypes.MappedProjectProduct | null>(null);
  return (
    <ViewDetail
      id="project-product-view"
      security={{
        permissions: [[PermissionName.PRODUCT_READ]],
      }}
      header={{
        title: product.name,
        subtitle: "Produit",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectProductsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Button",
              text: "Modifier le produit",
              show: true,
              security: {
                permissions: [[PermissionName.PRODUCT_UPDATE]],
              },
              onClick: () => {
                navigate(
                  toProjectProductUpdateView({
                    tenantId: tenant.id,
                    projectId: project.id,
                    productId: product.id,
                  })
                );
              },
            },
          ],
          otherActions: [
            {
              __type: "Button",
              content: "Supprimer le produit",
              show: !product.managedByModels,
              security: {
                permissions: [[PermissionName.PRODUCT_DELETE]],
              },
              onClick: () => {
                setProductToDelete(product);
              },
            },
          ],
        },
      }}
    >
      <Panel>
        <PanelControl label="Catégorie" values={[product.category.name]} />
        <PanelControl label="Variance" values={[product.variant ?? "--"]} />
        <PanelControl label="Format" values={[product.format ?? "--"]} />
        <PanelControl label="Note" values={[product.note ?? "--"]} />
        <PanelControl label="Description" values={[product.description ?? "--"]} />
        <PanelControl label="Géré par le(s) modèle(s)" values={[product.managedByModels ? "Oui" : "Non"]} />
        <PanelControl label="Activités" values={product.activities.map(({ activity }) => activity.label)} />
        <PanelControl label="Vignette" values={[product.hasThumbnail ? <ProductThumbnail size="lg" hasThumbnail={product.hasThumbnail} thumbnailUrl={product.thumbnailUrl} bordered /> : "--"]} />
      </Panel>
      <Panel
        header={{
          title: "Fournisseur",
        }}
      >
        <PanelControl
          label="Nom"
          values={[
            product.provider.provider ? (
              <Link
                href={toTenantProviderView({
                  tenantId: tenant.id,
                  providerId: product.provider.provider.id,
                })}
              >
                {product.provider.provider.name}
              </Link>
            ) : (
              "--"
            ),
          ]}
        />
        <PanelControl label="Modèle du produit" values={[product.provider.productModel ?? "--"]} />
        <PanelControl label="Code du produit" values={[product.provider.productCode ?? "--"]} />
        <PanelControl
          label="Site Web du produit"
          values={[
            product.provider.productUrl ? (
              <Link href={product.provider.productUrl} external>
                {product.provider.productUrl}
              </Link>
            ) : (
              "--"
            ),
          ]}
        />
      </Panel>
      {productToDelete && (
        <ProductDeleteDialog
          tenantId={tenant.id}
          projectId={project.id}
          product={productToDelete}
          onCompleted={() => {
            navigate(
              toProjectProductsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          }}
          onClose={() => {
            setProductToDelete(null);
          }}
        />
      )}
    </ViewDetail>
  );
};
