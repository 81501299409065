import { gql } from "@apollo/client";

// ----------
export const ERROR_FRAGMENT = gql`
  fragment Error on Error {
    message
  }
`;

export const ADDRESS_FRAGMENT = gql`
  fragment Address on Address {
    line
    city
    subdivisionCode
    countryCode
    postalCode
  }
`;

// Refund
export const REFUND_FRAGMENT = gql`
  fragment Refund on Refund {
    id
    createdAt
    updatedAt
    date
    number
    label
    referenceNumber
    isLocked
    mode
    amount
    notes
    invoiceId
    contractId
  }
`;

// Payment
export const PAYMENT_FRAGMENT = gql`
  fragment Payment on Payment {
    id
    createdAt
    updatedAt
    date
    number
    label
    referenceNumber
    isLocked
    mode
    amount
    notes
    invoiceId
    contractId
  }
`;

// Invoice
export const INVOICE_ITEM_FRAGMENT = gql`
  fragment InvoiceItem on InvoiceItem {
    id
    index
    type
    name
    description
    quantity
    unitPrice
    amount
  }
`;

export const INVOICE_CUSTOMER_INFO_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment InvoiceCustomerInfo on InvoiceCustomerInfo {
    name
    address {
      ...Address
    }
  }
`;

export const INVOICE_CUSTOMER_FRAGMENT = gql`
  fragment InvoiceCustomer on InvoiceCustomer {
    customerId
    index
  }
`;

export const INVOICE_FRAGMENT = gql`
  ${INVOICE_CUSTOMER_INFO_FRAGMENT}
  ${INVOICE_CUSTOMER_FRAGMENT}
  ${INVOICE_ITEM_FRAGMENT}
  fragment Invoice on Invoice {
    id
    createdAt
    updatedAt
    number
    date
    terms
    due
    label
    status
    subtotalAmount
    feePercent
    feeAmount
    feeSubtotalAmount
    creditBeforeTaxAmount
    creditBeforeTaxSubtotalAmount
    taxPercent
    taxAmount
    totalAmount
    creditAfterTaxAmount
    balanceAmount
    contractId
    contractSelectionId
    customers {
      ...InvoiceCustomer
    }
    customerInfo {
      ...InvoiceCustomerInfo
    }
    items {
      ...InvoiceItem
    }
  }
`;

// Contract
export const CONTRACT_SELECTION_GROUP_ITEM_BREAKDOWN_LINE_FRAGMENT = gql`
  fragment ContractSelectionGroupItemBreakdownLine on ContractSelectionGroupItemBreakdownLine {
    activityId
    subcontractorAmount
    contractorAmount
    promoterAmount
    costAmount
  }
`;

export const CONTRACT_SELECTION_GROUP_ITEM_FRAGMENT = gql`
  ${CONTRACT_SELECTION_GROUP_ITEM_BREAKDOWN_LINE_FRAGMENT}
  fragment ContractSelectionGroupItem on ContractSelectionGroupItem {
    id
    index
    sectionElementOptionType
    subcontractorAmount
    contractorAmount
    promoterAmount
    costAmount
    clientAmount
    contingencyAmount
    sectionId
    sectionElementId
    sectionElementOptionId
    breakdownLines {
      ...ContractSelectionGroupItemBreakdownLine
    }
  }
`;

export const CONTRACT_SELECTION_GROUP_ITEM_SET_FRAGMENT = gql`
  ${CONTRACT_SELECTION_GROUP_ITEM_FRAGMENT}
  fragment ContractSelectionGroupItemSet on ContractSelectionGroupItemSet {
    sectionElementGroupId
    minIndex
    maxIndex
    items {
      ...ContractSelectionGroupItem
    }
  }
`;

export const CONTRACT_SELECTION_GROUP_ITEM_UNION_FRAGMENT = gql`
  ${CONTRACT_SELECTION_GROUP_ITEM_FRAGMENT}
  ${CONTRACT_SELECTION_GROUP_ITEM_SET_FRAGMENT}
  fragment ContractSelectionGroupItemUnion on ContractSelectionGroupItemUnion {
    ... on ContractSelectionGroupItem {
      ...ContractSelectionGroupItem
    }
    ... on ContractSelectionGroupItemSet {
      ...ContractSelectionGroupItemSet
    }
  }
`;

export const CONTRACT_SELECTION_GROUP_VIBE_FRAGMENT = gql`
  fragment ContractSelectionGroupVibe on ContractSelectionGroupVibe {
    sectionVibeId
    mode
    subcontractorAmount
    contractorAmount
    promoterAmount
    costAmount
    clientAmount
    contingencyAmount
  }
`;

export const CONTRACT_SELECTION_GROUP_FRAGMENT = gql`
  ${CONTRACT_SELECTION_GROUP_VIBE_FRAGMENT}
  ${CONTRACT_SELECTION_GROUP_ITEM_UNION_FRAGMENT}
  fragment ContractSelectionGroup on ContractSelectionGroup {
    id
    index
    state
    numberOfExtras
    subcontractorAmount
    contractorAmount
    promoterAmount
    costAmount
    clientAmount
    contingencyAmount
    formSectionId
    vibe {
      ...ContractSelectionGroupVibe
    }
    items {
      ...ContractSelectionGroupItemUnion
    }
  }
`;

export const CONTRACT_SELECTION_BILLING_SETTINGS_FRAGMENT = gql`
  fragment ContractSelectionBillingSettings on ContractSelectionBillingSettings {
    taxGroupId
    terms
    revisionAmount
    creditAmount
    mixAndMatchAmount
  }
`;

export const CONTRACT_SELECTION_FRAGMENT = gql`
  ${CONTRACT_SELECTION_BILLING_SETTINGS_FRAGMENT}
  ${CONTRACT_SELECTION_GROUP_FRAGMENT}
  fragment ContractSelection on ContractSelection {
    id
    createdAt
    updatedAt
    label
    state
    revisionNumber
    revisionReason
    numberOfExtras
    subcontractorAmount
    contractorAmount
    promoterAmount
    costAmount
    clientAmount
    contingencyAmount
    workflow
    isTermsAndConditionsAccepted
    invoiceId
    billingSettings {
      ...ContractSelectionBillingSettings
    }
    groups {
      ...ContractSelectionGroup
    }
  }
`;

export const CONTRACT_CUSTOMER_FRAGMENT = gql`
  fragment ContractCustomer on ContractCustomer {
    customerId
    index
  }
`;

export const CONTRACT_FRAGMENT = gql`
  ${CONTRACT_SELECTION_FRAGMENT}
  ${CONTRACT_CUSTOMER_FRAGMENT}
  fragment Contract on Contract {
    id
    createdAt
    updatedAt
    state
    isCanceled
    nonRefundableAmount
    totalAmount
    balanceAmount
    unitId
    formId
    currentSelectionId
    previousSelectionId
    customers {
      ...ContractCustomer
    }
    selections {
      ...ContractSelection
    }
  }
`;

// Unit
export const UNIT_FRAGMENT = gql`
  fragment Unit on Unit {
    id
    createdAt
    updatedAt
    state
    number
    floor
    bedroomCount
    bathroomCount
    halfBathroomCount
    isDocumentsAvailable
    label
    formId
    currentContractId
  }
`;

// Section
export const SECTION_VIBE_ITEM_FRAGMENT = gql`
  fragment SectionVibeItem on SectionVibeItem {
    id
    index
    subcontractorAmount
    contractorAmount
    promoterAmount
    costAmount
    sectionElementId
    sectionElementOptionId
  }
`;

export const SECTION_VIBE_FRAGMENT = gql`
  ${SECTION_VIBE_ITEM_FRAGMENT}
  fragment SectionVibe on SectionVibe {
    id
    index
    name
    mode
    state
    subcontractorAmount
    contractorAmount
    promoterAmount
    costAmount
    clientAmount
    contingencyAmount
    items {
      ...SectionVibeItem
    }
  }
`;

export const SECTION_ELEMENT_OPTION_BREAKDOWN_LINE_FRAGMENT = gql`
  fragment SectionElementOptionBreakdownLine on SectionElementOptionBreakdownLine {
    activityId
    subcontractorAmount
    contractorAmount
    promoterAmount
    costAmount
  }
`;

export const SECTION_ELEMENT_OPTION_MODEL_LINK_FRAGMENT = gql`
  fragment SectionElementOptionModelLink on SectionElementOptionModelLink {
    modelId
    modelRoomId
    modelRoomElementId
    modelRoomElementOptionId
  }
`;

export const SECTION_ELEMENT_OPTION_FRAGMENT = gql`
  ${SECTION_ELEMENT_OPTION_MODEL_LINK_FRAGMENT}
  ${SECTION_ELEMENT_OPTION_BREAKDOWN_LINE_FRAGMENT}
  fragment SectionElementOption on SectionElementOption {
    id
    index
    type
    subcontractorAmount
    contractorAmount
    promoterAmount
    costAmount
    clientAmount
    contingencyAmount
    isUnlocked
    productId
    sectionElementId
    modelLink {
      ...SectionElementOptionModelLink
    }
    breakdownLines {
      ...SectionElementOptionBreakdownLine
    }
  }
`;

export const SECTION_ELEMENT_OPTION_SET_FRAGMENT = gql`
  ${SECTION_ELEMENT_OPTION_FRAGMENT}
  fragment SectionElementOptionSet on SectionElementOptionSet {
    productCategoryId
    minIndex
    maxIndex
    options {
      ...SectionElementOption
    }
  }
`;

export const SECTION_ELEMENT_OPTION_UNION_FRAGMENT = gql`
  ${SECTION_ELEMENT_OPTION_FRAGMENT}
  ${SECTION_ELEMENT_OPTION_SET_FRAGMENT}
  fragment SectionElementOptionUnion on SectionElementOptionUnion {
    ... on SectionElementOption {
      ...SectionElementOption
    }
    ... on SectionElementOptionSet {
      ...SectionElementOptionSet
    }
  }
`;

export const SECTION_ELEMENT_MODEL_LINK_FRAGMENT = gql`
  fragment SectionElementModelLink on SectionElementModelLink {
    modelId
    modelRoomId
    modelRoomElementId
  }
`;

export const SECTION_ELEMENT_FRAGMENT = gql`
  ${SECTION_ELEMENT_OPTION_UNION_FRAGMENT}
  ${SECTION_ELEMENT_MODEL_LINK_FRAGMENT}
  fragment SectionElement on SectionElement {
    id
    createdAt
    updatedAt
    index
    type
    label
    isOptional
    isManagedByVibes
    hideProductCategory
    groupId
    modelLink {
      ...SectionElementModelLink
    }
    options {
      ...SectionElementOptionUnion
    }
  }
`;

export const SECTION_ELEMENT_SET_FRAGMENT = gql`
  ${SECTION_ELEMENT_FRAGMENT}
  fragment SectionElementSet on SectionElementSet {
    sectionElementGroupId
    minIndex
    maxIndex
    elements {
      ...SectionElement
    }
  }
`;

export const SECTION_ELEMENT_UNION_FRAGMENT = gql`
  ${SECTION_ELEMENT_FRAGMENT}
  ${SECTION_ELEMENT_SET_FRAGMENT}
  fragment SectionElementUnion on SectionElementUnion {
    ... on SectionElement {
      ...SectionElement
    }
    ... on SectionElementSet {
      ...SectionElementSet
    }
  }
`;

export const SECTION_ELEMENT_GROUP_FRAGMENT = gql`
  fragment SectionElementGroup on SectionElementGroup {
    id
    createdAt
    updatedAt
    index
    name
  }
`;

export const SECTION_MODEL_LINK_FRAGMENT = gql`
  fragment SectionModelLink on SectionModelLink {
    modelId
    modelRoomId
  }
`;

export const SECTION_FRAGMENT = gql`
  ${SECTION_MODEL_LINK_FRAGMENT}
  ${SECTION_ELEMENT_UNION_FRAGMENT}
  ${SECTION_ELEMENT_GROUP_FRAGMENT}
  ${SECTION_VIBE_FRAGMENT}
  fragment Section on Section {
    id
    createdAt
    updatedAt
    state
    title
    index
    modelLink {
      ...SectionModelLink
    }
    elements {
      ...SectionElementUnion
    }
    elementGroups {
      ...SectionElementGroup
    }
    vibes {
      ...SectionVibe
    }
  }
`;

// Form
export const FORM_REPORT_VIBE_ROOM_LINK_FRAGMENT = gql`
  fragment FormReportVibeRoomLink on FormReportVibeRoomLink {
    formSectionId
    reportId
    reportRoomId
  }
`;

export const FORM_REPORT_ROOM_ELEMENT_LINK_FRAGMENT = gql`
  fragment FormReportRoomElementLink on FormReportRoomElementLink {
    formSectionId
    sectionElementId
    reportId
    reportRoomId
    reportRoomElementId
  }
`;

export const FORM_SECTION_FRAGMENT = gql`
  fragment FormSection on FormSection {
    id
    index
    title
    sectionId
  }
`;

export const FORM_FRAGMENT = gql`
  ${FORM_SECTION_FRAGMENT}
  ${FORM_REPORT_ROOM_ELEMENT_LINK_FRAGMENT}
  ${FORM_REPORT_VIBE_ROOM_LINK_FRAGMENT}
  fragment Form on Form {
    id
    createdAt
    updatedAt
    index
    name
    state
    modelId
    sections {
      ...FormSection
    }
    reportRoomElementLinks {
      ...FormReportRoomElementLink
    }
    reportVibeRoomLinks {
      ...FormReportVibeRoomLink
    }
  }
`;

// ProductCategory
export const PRODUCT_CATEGORY_FRAGMENT = gql`
  fragment ProductCategory on ProductCategory {
    id
    createdAt
    updatedAt
    name
    displayName
    description
    managedByModels
  }
`;

// Product
export const PRODUCT_ACTIVITY_FRAGMENT = gql`
  fragment ProductActivity on ProductActivity {
    activityId
    index
  }
`;

export const PRODUCT_PROVIDER_FRAGMENT = gql`
  fragment ProductProvider on ProductProvider {
    id
    productModel
    productCode
    productUrl
  }
`;

export const PRODUCT_FRAGMENT = gql`
  ${PRODUCT_PROVIDER_FRAGMENT}
  ${PRODUCT_ACTIVITY_FRAGMENT}
  fragment Product on Product {
    id
    createdAt
    updatedAt
    name
    variant
    format
    note
    description
    thumbnailUrl
    hasThumbnail
    managedByModels
    usedBySections
    categoryId
    provider {
      ...ProductProvider
    }
    activities {
      ...ProductActivity
    }
  }
`;

// Model
export const MODEL_ROOM_ELEMENT_OPTION_FRAGMENT = gql`
  fragment ModelRoomElementOption on ModelRoomElementOption {
    id
    sharedId
    index
    productId
  }
`;

export const MODEL_ROOM_ELEMENT_FRAGMENT = gql`
  ${MODEL_ROOM_ELEMENT_OPTION_FRAGMENT}
  fragment ModelRoomElement on ModelRoomElement {
    id
    sharedId
    name
    index
    type
    options {
      ...ModelRoomElementOption
    }
  }
`;

export const MODEL_ROOM_FRAGMENT = gql`
  ${MODEL_ROOM_ELEMENT_FRAGMENT}
  fragment ModelRoom on ModelRoom {
    id
    sharedId
    index
    name
    elements {
      ...ModelRoomElement
    }
  }
`;

export const MODEL_FRAGMENT = gql`
  ${MODEL_ROOM_FRAGMENT}
  fragment Model on Model {
    id
    createdAt
    updatedAt
    index
    sharedId
    name
    rooms {
      ...ModelRoom
    }
  }
`;

// Report
export const REPORT_ROOM_ELEMENT_FRAGMENT = gql`
  fragment ReportRoomElement on ReportRoomElement {
    id
    createdAt
    updatedAt
    index
    name
    display
  }
`;

export const REPORT_ROOM_FRAGMENT = gql`
  ${REPORT_ROOM_ELEMENT_FRAGMENT}
  fragment ReportRoom on ReportRoom {
    id
    createdAt
    updatedAt
    index
    name
    elements {
      ...ReportRoomElement
    }
  }
`;

export const REPORT_ACTIVITY_FRAGMENT = gql`
  fragment ReportActivity on ReportActivity {
    activityId
    index
  }
`;

export const REPORT_FRAGMENT = gql`
  ${REPORT_ACTIVITY_FRAGMENT}
  ${REPORT_ROOM_FRAGMENT}
  fragment Report on Report {
    id
    createdAt
    updatedAt
    index
    isDefault
    name
    activities {
      ...ReportActivity
    }
    rooms {
      ...ReportRoom
    }
  }
`;

// Activity
export const ACTIVITY_FRAGMENT = gql`
  fragment Activity on Activity {
    id
    createdAt
    updatedAt
    number
    title
    label
    description
  }
`;

// Project
export const PROJECT_CONTACT_INFO_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment ProjectContactInfo on ProjectContactInfo {
    companyName
    email
    phone
    websiteUrl
    federalTaxNumber
    provincialTaxNumber
    address {
      ...Address
    }
  }
`;

export const PROJECT_FRAGMENT = gql`
  ${PROJECT_CONTACT_INFO_FRAGMENT}
  fragment Project on Project {
    id
    createdAt
    updatedAt
    code
    name
    label
    description
    tenantId
    defaultReportId
    contactInfo {
      ...ProjectContactInfo
    }
  }
`;

// Provider
export const PROVIDER_FRAGMENT = gql`
  fragment Provider on Provider {
    id
    createdAt
    updatedAt
    name
    websiteUrl
    description
  }
`;

// Customer
export const CUSTOMER_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment Customer on Customer {
    id
    firstName
    lastName
    fullName
    companyName
    displayName
    type
    label
    email
    phone
    gender
    language
    notes
    isDefault
    address {
      ...Address
    }
  }
`;

// Tax
export const TAX_FRAGMENT = gql`
  fragment Tax on Tax {
    id
    createdAt
    updatedAt
    name
    rate
    agencyId
  }
`;

export const TAX_GROUP_FRAGMENT = gql`
  fragment TaxGroup on TaxGroup {
    id
    createdAt
    updatedAt
    name
    taxIds
  }
`;

export const TAX_AGENCY_FRAGMENT = gql`
  fragment TaxAgency on TaxAgency {
    id
    createdAt
    updatedAt
    name
    description
  }
`;

// Permission
export const PERMISSION_FRAGMENT = gql`
  fragment Permission on Permission {
    name
    createdAt
    updatedAt
    index
    displayName
    description
    groupName
  }
`;

// Role
export const ROLE_FRAGMENT = gql`
  fragment Role on Role {
    id
    createdAt
    updatedAt
    name
    description
    template
    isLocked
    isTenantProfileManager
    isTenantSecurityManager
    isTenantAccountingManager
    isTenantProjectManager
    permissions
  }
`;

// User
export const USER_FRAGMENT = gql`
  fragment User on User {
    id
    createdAt
    updatedAt
    email
    firstName
    lastName
    fullName
    isTest
    isBlocked
    roleId
    hasAccessToAllProjects
    hasAccessToTestTenant
    projectIds
  }
`;

export const USER_INFO_TENANT_FRAGMENT = gql`
  fragment UserInfoTenant on UserInfoTenant {
    id
    testId
    isProfileManager
    isProjectManager
    isSecurityManager
    isAccountingManager
    isBlocked
    hasAccessToAllProjects
    hasAccessToTestTenant
    permissions
    projectIds
  }
`;

export const USER_INFO_FRAGMENT = gql`
  ${USER_INFO_TENANT_FRAGMENT}
  fragment UserInfo on UserInfo {
    userId
    email
    isSuperAdmin
    tenants {
      ...UserInfoTenant
    }
  }
`;

// Tenant
export const TENANT_PROFILE_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment TenantProfile on TenantProfile {
    companyName
    email
    phone
    language
    currencyCode
    websiteUrl
    address {
      ...Address
    }
  }
`;

export const TENANT_FRAGMENT = gql`
  fragment Tenant on Tenant {
    id
    type
    status
    name
  }
`;

// Country
export const COUNTRY_SUBDIVISION_FRAGMENT = gql`
  fragment CountrySubdivision on CountrySubdivision {
    code
    name
    type
  }
`;

export const COUNTRY_FRAGMENT = gql`
  ${COUNTRY_SUBDIVISION_FRAGMENT}
  fragment Country on Country {
    code
    name
    callingCode
    subdivisions {
      ...CountrySubdivision
    }
  }
`;
