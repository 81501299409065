import "./index.scss";
import { ViewForm } from "buildingBlocks";
import { FormReportLinkForm, useFormReportLinkForm } from "forms";
import { useProjectFormContext, useProjectReportContext, useRouting } from "hooks";
import { useProjectContext, useTenantContext } from "providers";
import { FunctionComponent } from "react";
import { PermissionName } from "graphql/schema";
import { SelectPicker } from "rsuite";
import { useLinkFormMutation } from "graphql/mutations";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type FormReportRoomElementLink = {
  readonly formSectionId: string;
  readonly sectionElementId: string;
  readonly reportRoomId: string;
  readonly reportRoomElementId: string;
};

type FormReportVibeRoomLink = {
  readonly formSectionId: string;
  readonly reportRoomId: string;
};

type UseData = (
  form: ProjectProviderTypes.MappedProjectForm,
  report: ProjectProviderTypes.MappedProjectReport
) => {
  readonly formReportRoomElementLinks: FormReportRoomElementLink[];
  readonly formReportVibeRoomLinks: FormReportVibeRoomLink[];
};

const useData: UseData = (form, report) => {
  const formReportRoomElementLinks = form.reportRoomElementLinks.filter((link) => link.reportId === report.id);
  const formReportVibeRoomLinks = form.reportVibeRoomLinks.filter((link) => link.reportId === report.id);
  return {
    formReportRoomElementLinks: form.sections.reduce<FormReportRoomElementLink[]>((previousValue, formSection) => {
      const sectionElements: FormReportRoomElementLink[] = [];
      for (const sectionElement of formSection.section.getElementsWithoutSets()) {
        const reportRoomElementLink = formReportRoomElementLinks.find((link) => link.formSectionId === formSection.id && link.sectionElementId === sectionElement.id);
        sectionElements.push({
          formSectionId: formSection.id,
          sectionElementId: sectionElement.id,
          reportRoomId: reportRoomElementLink?.reportRoomId ?? "",
          reportRoomElementId: reportRoomElementLink?.reportRoomElementId ?? "",
        });
      }
      return previousValue.concat(sectionElements);
    }, []),
    formReportVibeRoomLinks: form.sections
      .filter((formSection) => formSection.section.vibes.length > 0)
      .map((formSection) => {
        const formReportVibeRoomLink = formReportVibeRoomLinks.find((link) => link.formSectionId === formSection.id);
        return {
          formSectionId: formSection.id,
          reportRoomId: formReportVibeRoomLink?.reportRoomId ?? "",
        };
      }),
  };
};

export const ProjectFormReportLinkView: FunctionComponent = () => {
  const { navigate, toProjectFormsView, toProjectFormReportLinkView } = useRouting();
  const { tenant } = useTenantContext();
  const { project, reports } = useProjectContext();
  const { form } = useProjectFormContext();
  const { report } = useProjectReportContext();
  const { formReportRoomElementLinks, formReportVibeRoomLinks } = useData(form, report);
  const { values, setValues } = useFormReportLinkForm({
    reportRoomElementLinks: formReportRoomElementLinks,
    reportVibeRoomLinks: formReportVibeRoomLinks,
  });
  const { linkForm, linkFormLoading, linkFormErrors } = useLinkFormMutation(
    ({ tenantId, projectId }) => {
      navigate(
        toProjectFormsView({
          tenantId: tenantId,
          projectId: projectId,
        })
      );
    },
    undefined,
    true
  );
  return (
    <ViewForm
      id="project-form-report-link-view"
      title={
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Lier le formulaire au rapport
          <SelectPicker
            size="lg"
            value={report.id}
            onChange={(value) => {
              if (report.id !== value) {
                navigate(
                  toProjectFormReportLinkView({
                    tenantId: tenant.id,
                    projectId: project.id,
                    formId: form.id,
                    reportId: report.id,
                  })
                );
              }
            }}
            data={reports.map((report) => ({
              label: report.name.toLowerCase(),
              value: report.id,
            }))}
            searchable={false}
            cleanable={false}
          />
        </div>
      }
      security={{
        permissions: [[PermissionName.FORM_READ, PermissionName.FORM_LINK]],
      }}
      loading={linkFormLoading}
      errors={linkFormErrors}
      disabled={values.reportVibeRoomLinks.some((link) => link.reportRoomId === "") || values.reportRoomElementLinks.some((link) => link.reportRoomElementId === "")}
      onSubmit={async () => {
        await linkForm({
          tenantId: tenant.id,
          projectId: project.id,
          formId: form.id,
          reportId: report.id,
          reportVibeRoomLinks: values.reportVibeRoomLinks.map((link) => ({
            formSectionId: link.formSectionId,
            reportRoomId: link.reportRoomId,
          })),
          reportRoomElementLinks: values.reportRoomElementLinks.map((link) => ({
            formSectionId: link.formSectionId,
            sectionElementId: link.sectionElementId,
            reportRoomId: link.reportRoomId,
            reportRoomElementId: link.reportRoomElementId,
          })),
        });
      }}
      onCancel={() => {
        navigate(
          toProjectFormsView({
            tenantId: tenant.id,
            projectId: project.id,
          })
        );
      }}
    >
      <FormReportLinkForm values={values} setValues={setValues} form={form} report={report} />
    </ViewForm>
  );
};
