import "./index.scss";
import { FunctionComponent } from "react";

export enum DateTimeFormat {
  DATE_AND_TIME,
  DATE_ONLY,
  TIME_ONLY,
}

type Props = {
  readonly value: any;
  readonly format?: DateTimeFormat | undefined;
  readonly bold?: boolean;
  readonly italic?: boolean;
};

export const DateTime: FunctionComponent<Props> = ({ value, format = DateTimeFormat.DATE_ONLY, bold = false, italic = false }) => {
  return <span className={`datetime${bold ? " datetime-bold" : ""}${italic ? " datetime-italic" : ""}`}>{getDateTimeFormat(value, format)}</span>;
};

const getDateTimeFormat = (value: any, format: DateTimeFormat): string => {
  switch (format) {
    case DateTimeFormat.DATE_AND_TIME: {
      return new Date(value).toLocaleString("fr-CA");
    }
    case DateTimeFormat.DATE_ONLY: {
      return new Date(value).toLocaleDateString("fr-CA");
    }
    case DateTimeFormat.TIME_ONLY: {
      return new Date(value).toLocaleTimeString("fr-CA");
    }
    default: {
      throw new Error("Invalid DateTimeFormat.");
    }
  }
};
