import "./index.scss";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ContainerLoader, ViewReport } from "buildingBlocks";
import { ContractSelectionState, PermissionName } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { CheckPicker, Message } from "rsuite";
import { useFile, useRouting } from "hooks";
import { useList } from "react-use";
import { useDownloadBillingReportDocumentAsExcelMutation, useDownloadBillingReportDocumentAsPdfMutation } from "graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { DocumentWrapper } from "buildingBlocks/DocumentWrapper";
import { useLoadBillingReportDocumentLazyQuery } from "graphql/queries";

export const ProjectDocumentBillingReportView: FunctionComponent = () => {
  const { navigate, toProjectDocumentsView } = useRouting();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [unitIds, { set: setUnitIds }] = useList<string>([]);
  const { tenant } = useTenantContext();
  const { project, units } = useProjectContext();
  const [html, setHtml] = useState<string | null | undefined>(undefined);
  const hasAtLeastOneContractSelectionWithInvoiceTotalAmountNotZero = units.some(
    (unit) =>
      unit.currentContract &&
      unit.currentContract.currentSelection &&
      unit.currentContract.currentSelection.invoice &&
      unit.currentContract.currentSelection.invoice.totalAmount !== 0 &&
      unit.currentContract.currentSelection.state === ContractSelectionState.FINISHED
  );
  const { names, printFromBase64, saveAsFromBase64 } = useFile();
  const { downloadBillingReportDocumentAsExcel, downloadBillingReportDocumentAsExcelLoading } = useDownloadBillingReportDocumentAsExcelMutation(({ base64Data }) => {
    saveAsFromBase64({
      data: base64Data,
      fileName: names.forExcelBillingReport(),
    });
  });
  const { downloadBillingReportDocumentAsPdf, downloadBillingReportDocumentAsPdfLoading } = useDownloadBillingReportDocumentAsPdfMutation(({ base64Data, print }) => {
    if (print) {
      printFromBase64({
        data: base64Data,
      });
    } else {
      saveAsFromBase64({
        data: base64Data,
        fileName: names.forPdfBillingReport(),
      });
    }
  });
  const { loadBillingReportDocument, billingReportDocumentLoading } = useLoadBillingReportDocumentLazyQuery(({ html }) => {
    setHtml(html);
    setIsLoaded(true);
  });
  const execute = useCallback(async () => {
    await loadBillingReportDocument({
      variables: {
        tenantId: tenant.id,
        projectId: project.id,
        unitIds: unitIds,
      },
    });
  }, [tenant, project, unitIds, loadBillingReportDocument]);
  useEffect(() => {
    if (hasAtLeastOneContractSelectionWithInvoiceTotalAmountNotZero) {
      execute();
    } else {
      setHtml(null);
      setIsLoaded(true);
    }
    // eslint-disable-next-line
  }, []);
  if (!isLoaded || html === undefined || billingReportDocumentLoading) {
    return <ContainerLoader />;
  }
  return (
    <ViewReport
      id="project-document-billing-report-view"
      security={{
        permissions: [[PermissionName.DOCUMENT_BILLING_REPORT_READ]],
      }}
      header={{
        subtitle: "Ventes",
        title: "Facturation",
        returnButton: {
          onClick: () => {
            navigate(
              toProjectDocumentsView({
                tenantId: tenant.id,
                projectId: project.id,
              })
            );
          },
        },
        toolbar: {
          buttons: [
            {
              __type: "Dropdown",
              appearance: "default",
              size: "sm",
              title: "Exporter",
              trigger: "click",
              placement: "bottomEnd",
              disabled: downloadBillingReportDocumentAsPdfLoading || downloadBillingReportDocumentAsExcelLoading,
              show: hasAtLeastOneContractSelectionWithInvoiceTotalAmountNotZero,
              security: {
                permissions: [[PermissionName.DOCUMENT_BILLING_REPORT_READ]],
              },
              items: [
                {
                  __type: "DropdownItem",
                  text: "Télécharger (PDF)",
                  disabled: downloadBillingReportDocumentAsPdfLoading || downloadBillingReportDocumentAsExcelLoading,
                  show: hasAtLeastOneContractSelectionWithInvoiceTotalAmountNotZero,
                  security: {
                    permissions: [[PermissionName.DOCUMENT_BILLING_REPORT_READ]],
                  },
                  onClick: () => {
                    downloadBillingReportDocumentAsPdf({
                      tenantId: tenant.id,
                      projectId: project.id,
                      unitIds: unitIds,
                      print: false,
                    });
                  },
                },
                {
                  __type: "DropdownItem",
                  text: "Télécharger (Excel)",
                  disabled: downloadBillingReportDocumentAsPdfLoading || downloadBillingReportDocumentAsExcelLoading,
                  show: hasAtLeastOneContractSelectionWithInvoiceTotalAmountNotZero,
                  security: {
                    permissions: [[PermissionName.DOCUMENT_BILLING_REPORT_READ]],
                  },
                  onClick: () => {
                    downloadBillingReportDocumentAsExcel({
                      tenantId: tenant.id,
                      projectId: project.id,
                      unitIds: unitIds,
                    });
                  },
                },
              ],
            },
            {
              __type: "Button",
              text: <FontAwesomeIcon icon={faPrint} />,
              appearance: "default",
              show: hasAtLeastOneContractSelectionWithInvoiceTotalAmountNotZero,
              loading: downloadBillingReportDocumentAsPdfLoading || downloadBillingReportDocumentAsExcelLoading,
              security: {
                permissions: [[PermissionName.DOCUMENT_BILLING_REPORT_READ]],
              },
              onClick: () => {
                downloadBillingReportDocumentAsPdf({
                  tenantId: tenant.id,
                  projectId: project.id,
                  unitIds: unitIds,
                  print: true,
                });
              },
            },
          ],
          otherActions: [],
        },
        filter: {
          left: {
            inputs: [
              <CheckPicker
                size="xs"
                label="Unités"
                placeholder="Sélectionner des unités"
                searchable
                value={[...unitIds]}
                onChange={setUnitIds}
                data={units
                  .filter(
                    (unit) =>
                      unit.currentContract &&
                      unit.currentContract.currentSelection &&
                      unit.currentContract.currentSelection.invoice &&
                      unit.currentContract.currentSelection.invoice.totalAmount !== 0 &&
                      unit.currentContract.currentSelection.state === ContractSelectionState.FINISHED
                  )
                  .map((unit) => ({
                    label: unit.number,
                    value: unit.id,
                  }))}
              />,
            ],
            applyButton: {
              disabled: !hasAtLeastOneContractSelectionWithInvoiceTotalAmountNotZero,
              onClick: () => {
                execute();
              },
            },
          },
          right: {
            inputs: [],
          },
        },
      }}
    >
      {html === null && (
        <Message type="info" showIcon className={`report-message`} header="Le rapport de facturation est indisponible">
          Aucune unité n'a de sélection avec des coûts associés...
        </Message>
      )}
      {html !== null && <DocumentWrapper html={html} />}
    </ViewReport>
  );
};
