import "./index.scss";
import { FormContainer, Uploader } from "buildingBlocks";
import { FunctionComponent, useState } from "react";
import { Form, Input, SelectPicker, TagPicker, Toggle } from "rsuite";
import produce from "immer";
import { ProductThumbnail } from "components";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";
import { ProviderFragment } from "graphql/schema";

type ProductFormValues = {
  readonly categoryId: string;
  readonly variant: string;
  readonly format: string;
  readonly note: string;
  readonly description: string;
  readonly managedByModels: boolean;
  readonly usedBySections: boolean;
  readonly hasThumbnail: boolean;
  readonly thumbnailUrl: string;
  readonly thumbnail: File | undefined;
  readonly provider: {
    readonly id: string | null;
    readonly productModel: string;
    readonly productCode: string;
    readonly productUrl: string;
  };
  readonly activities: ReadonlyArray<{
    readonly activityId: string;
    readonly index: number;
  }>;
};

type Props = {
  readonly values: ProductFormValues;
  readonly setValues: (values: ProductFormValues) => void;
  readonly activities: ReadonlyArray<ProjectProviderTypes.MappedProjectActivity>;
  readonly providers: ReadonlyArray<ProviderFragment>;
  readonly productCategories: ReadonlyArray<ProjectProviderTypes.MappedProjectProductCategory>;
  readonly readonlyProductCategory?: boolean;
};

export const useProductForm = (initialValues: ProductFormValues) => {
  const [values, setValues] = useState<ProductFormValues>(initialValues);
  return { values, setValues };
};

export const ProductForm: FunctionComponent<Props> = ({ values, setValues, activities, productCategories, providers, readonlyProductCategory = false }) => (
  <Form id="product-form">
    <FormContainer highlight>
      <Form.Group>
        <Form.ControlLabel>Catégorie</Form.ControlLabel>
        <SelectPicker
          readOnly={readonlyProductCategory || values.managedByModels}
          value={values.categoryId}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.categoryId = value!;
              })
            );
          }}
          data={productCategories
            .filter((productCategory) => productCategory.managedByModels === values.managedByModels)
            .map((productCategory) => ({
              value: productCategory.id,
              label: productCategory.name,
            }))}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Variant</Form.ControlLabel>
        <Input
          value={values.variant}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.variant = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Format</Form.ControlLabel>
        <Input
          value={values.format}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.format = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Note</Form.ControlLabel>
        <Input
          as="textarea"
          rows={2}
          value={values.note}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.note = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Description</Form.ControlLabel>
        <Input
          as="textarea"
          rows={3}
          value={values.description}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.description = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Activités</Form.ControlLabel>
        <TagPicker
          value={[...values.activities].sort((left, right) => left.index - right.index).map((activity) => activity.activityId)}
          data={activities.map((activity) => ({
            label: activity.title,
            value: activity.id,
          }))}
          readOnly={values.usedBySections}
          cleanable={false}
          onChange={(value: string[]) => {
            setValues(
              produce(values, (draft) => {
                draft.activities = value.map((activityId, index) => ({
                  activityId: activityId,
                  index: index,
                }));
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Vignette (.png)
          <Toggle
            size="md"
            readOnly={values.managedByModels}
            checkedChildren="Oui"
            unCheckedChildren="Non"
            checked={values.hasThumbnail}
            onChange={(checked) => {
              setValues(
                produce(values, (draft) => {
                  draft.hasThumbnail = checked;
                  draft.thumbnail = undefined;
                })
              );
            }}
          />
        </Form.ControlLabel>
        {values.hasThumbnail ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Uploader
              accept=".png"
              disabled={values.thumbnail !== undefined}
              onChange={(fileTypes) => {
                const fileType = fileTypes[0];
                if (fileType) {
                  setValues(
                    produce(values, (draft) => {
                      draft.thumbnail = fileType.blobFile;
                    })
                  );
                }
              }}
              onRemove={() => {
                setValues(
                  produce(values, (draft) => {
                    draft.thumbnail = undefined;
                  })
                );
              }}
            />
            <ProductThumbnail
              size="lg"
              hasThumbnail={values.hasThumbnail}
              thumbnailUrl={values.thumbnailUrl}
              preview={values.thumbnail ? URL.createObjectURL(values.thumbnail as any) : undefined}
              bordered
            />
          </div>
        ) : (
          "--"
        )}
      </Form.Group>
    </FormContainer>
    <FormContainer
      header={{
        title: "Fournisseur",
        actions: [],
      }}
    >
      <Form.Group>
        <SelectPicker
          value={values.provider.id}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.provider.id = value;
              })
            );
          }}
          data={providers.map((provider) => ({
            value: provider.id,
            label: provider.name,
          }))}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Modèle du produit</Form.ControlLabel>
        <Input
          value={values.provider.productModel}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.provider.productModel = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Code du produit</Form.ControlLabel>
        <Input
          value={values.provider.productCode}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.provider.productCode = value;
              })
            );
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Site web du produit</Form.ControlLabel>
        <Input
          value={values.provider.productUrl}
          onChange={(value) => {
            setValues(
              produce(values, (draft) => {
                draft.provider.productUrl = value;
              })
            );
          }}
        />
      </Form.Group>
    </FormContainer>
  </Form>
);
