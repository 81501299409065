import "./index.scss";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ContainerLoader, ViewReport } from "buildingBlocks";
import { useProjectContractContext, useProjectContractSelectionContext, useRouting } from "hooks";
import { ContractSelectionWorkflow, PermissionName } from "graphql/schema";
import { useProjectContext, useTenantContext } from "providers";
import { ContractSelectionConfirmForm, useContractSelectionConfirmForm } from "forms";
import { useConfirmContractSelectionMutation } from "graphql/mutations";
import { useLayoutContext } from "providers/LayoutProvider";
import { DocumentWrapper } from "buildingBlocks/DocumentWrapper";
import { useLoadContractSelectionDocumentLazyQuery } from "graphql/queries";

export const ProjectContractSelectionUpdateView: FunctionComponent = () => {
  const { navigate, toProjectContractSelectionGroupUpdateView, toProjectContractSelectionsView, toProjectContractInvoiceView } = useRouting();
  const { isFullscreen, toggleFullscreen } = useLayoutContext();
  const { tenant } = useTenantContext();
  const { project } = useProjectContext();
  const { contract } = useProjectContractContext();
  const { contractSelection } = useProjectContractSelectionContext();
  const [html, setHtml] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { values, setValues } = useContractSelectionConfirmForm({
    isTermsAndConditionsAccepted: contractSelection.isTermsAndConditionsAccepted,
  });
  const { loadContractSelectionDocument, contractSelectionDocumentLoading } = useLoadContractSelectionDocumentLazyQuery(({ html }) => {
    setHtml(html);
    setIsLoaded(true);
  });
  const execute = useCallback(async () => {
    await loadContractSelectionDocument({
      variables: {
        tenantId: tenant.id,
        projectId: project.id,
        contractId: contract.id,
        contractSelectionId: contractSelection.id,
      },
    });
  }, [tenant, project, contract, contractSelection, loadContractSelectionDocument]);
  useEffect(() => {
    execute();
    // eslint-disable-next-line
  }, []);
  const { confirmContractSelection, confirmContractSelectionLoading } = useConfirmContractSelectionMutation(({ tenantId, projectId, contract, invoices }) => {
    const contractSelection = contract.selections.find((contractSelection) => contractSelection.id === contract.currentSelectionId);
    const invoice = invoices.find((invoice) => invoice.id === contractSelection?.invoiceId);
    if (invoice) {
      navigate(
        toProjectContractInvoiceView({
          tenantId: tenantId,
          projectId: projectId,
          contractId: contract.id,
          invoiceId: invoice.id,
        })
      );
    } else {
      navigate(
        toProjectContractSelectionsView({
          tenantId: tenantId,
          projectId: projectId,
          contractId: contract.id,
        })
      );
    }
  });
  if (!isLoaded || html === null || contractSelectionDocumentLoading) {
    return <ContainerLoader />;
  }
  return (
    <ViewReport
      id="project-contract-selection-update-view"
      security={{
        permissions: [[PermissionName.CONTRACT_SELECTION_CONFIRM]],
      }}
      header={{
        title: contractSelection.label,
        subtitle: contract.unit.label,
        toolbar: {
          buttons: [
            {
              __type: "Button",
              appearance: "default",
              text: "Quitter le mode plein écran",
              show: isFullscreen,
              security: {},
              onClick: () => {
                toggleFullscreen();
              },
            },
            {
              __type: "Button",
              appearance: "primary",
              text: "Entrer en mode plein écran",
              show: !isFullscreen,
              security: {},
              onClick: () => {
                toggleFullscreen();
              },
            },
          ],
          otherActions: [],
        },
      }}
      form={{
        disabled: contractSelection.workflow === ContractSelectionWorkflow.WITH_CUSTOMER && !values.isTermsAndConditionsAccepted,
        loading: confirmContractSelectionLoading,
        onSubmit: async () => {
          await confirmContractSelection({
            tenantId: tenant.id,
            projectId: project.id,
            contractId: contract.id,
            selectionId: contractSelection.id,
            isTermsAndConditionsAccepted: values.isTermsAndConditionsAccepted,
          });
        },
        onCancel: () => {
          navigate(
            toProjectContractSelectionGroupUpdateView({
              tenantId: tenant.id,
              projectId: project.id,
              contractId: contract.id,
              contractSelectionId: contractSelection.id,
              contractSelectionGroupId: contractSelection.groups.find((contractSelectionGroup) => contractSelectionGroup.index === contractSelection.groups.length - 1)!.id,
            })
          );
        },
      }}
    >
      <DocumentWrapper html={html} />
      <ContractSelectionConfirmForm values={values} setValues={setValues} />
    </ViewReport>
  );
};
