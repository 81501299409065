import "./index.scss";
import { FunctionComponent } from "react";
import { SectionElementFormValues, SectionElementOptionFormValues, SectionFormValues } from "forms";
import { SectionElementFormPanel } from "components/SectionElementFormPanel";
import { SectionElementOptionType } from "graphql/schema";
import produce from "immer";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type Props = {
  readonly sectionElement: SectionElementFormValues;
  readonly values: SectionFormValues;
  readonly setValues: (values: SectionFormValues) => void;
  readonly products: ReadonlyArray<ProjectProviderTypes.MappedProjectProduct>;
  readonly onSectionElementOptionCostAmountUpdated: (sectionElement: SectionElementFormValues, sectionElementOption: SectionElementOptionFormValues) => void;
};

export const SectionElementTypeBooleanFormPanel: FunctionComponent<Props> = ({ sectionElement, values, setValues, products, onSectionElementOptionCostAmountUpdated }) => (
  <SectionElementFormPanel
    model={null}
    sectionElement={sectionElement}
    values={values}
    setValues={setValues}
    products={products.filter((product) => !product.managedByModels)}
    isOptionalDisabled
    isManagedByVibesButtonDisabled
    isDeleteOptionDisabled={() => {
      return true;
    }}
    isAddOptionButtonDisabled={values.elements.find((element) => element.id === sectionElement.id)!.options.length === 1}
    isHideProductCategoryDisabled
    hasSectionElementGroup
    sectionElementOptionTypes={[SectionElementOptionType.INCLUDED, SectionElementOptionType.EXTRA]}
    onSectionElementOptionCostAmountUpdated={onSectionElementOptionCostAmountUpdated}
    onSectionElementOptionTypeChange={(type, sectionElementOption) => {
      setValues(
        produce(values, (draft) => {
          const draftElement = draft.elements.find((draftElement) => draftElement.id === sectionElement.id);
          if (draftElement) {
            const draftElementOption = draftElement.options.find((draftElementOption) => draftElementOption.id === sectionElementOption.id);
            if (draftElementOption) {
              draftElementOption.type = type;
              draftElementOption.clientAmount = 0;
              draftElementOption.breakdownLines = draftElementOption.breakdownLines.map((draftElementOptionBbreakdownLine) => ({
                ...draftElementOptionBbreakdownLine,
                subcontractorAmount: 0,
                contractorAmount: 0,
                promoterAmount: 0,
              }));
            }
          }
        })
      );
    }}
  />
);
