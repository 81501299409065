import "./index.scss";
import { FormTable, Text } from "buildingBlocks";
import { FunctionComponent, useState } from "react";
import { InputNumber, SelectPicker } from "rsuite";
import produce from "immer";
import { FormState } from "graphql/schema";
import { ProjectProviderTypes } from "providers/ProjectProvider/types";

type UnitsFormValues = {
  readonly units: ReadonlyArray<{
    readonly id: string;
    readonly number: string;
    readonly floor: number;
    readonly bedroomCount: number;
    readonly bathroomCount: number;
    readonly halfBathroomCount: number;
    readonly formId: string | null;
  }>;
};

type Props = {
  readonly values: UnitsFormValues;
  readonly setValues: (values: UnitsFormValues) => void;
  readonly forms: ReadonlyArray<ProjectProviderTypes.MappedProjectForm>;
};

export const useUnitsForm = (initialValues: UnitsFormValues) => {
  const [values, setValues] = useState<UnitsFormValues>(initialValues);
  return { values, setValues };
};

export const UnitsForm: FunctionComponent<Props> = ({ values, setValues, forms }) => (
  <FormTable
    id="units-form"
    table={{
      headColumns: [
        {
          name: "number",
          value: "UNITÉ",
        },
        {
          name: "bedroom-count",
          value: "CHAMBRE(S)",
        },
        {
          name: "bathroom-count",
          value: "SALLE(S) DE BAIN",
        },
        {
          name: "half-bathroom-count",
          value: "SALLE(S) D'EAU",
        },
        {
          name: "form",
          value: "Formulaire",
        },
      ],
      bodyRows: [],
      bodyRowGroups: values.units
        .map((unit) => unit.floor)
        .unique()
        .sort((left, right) => left - right)
        .map((floor) => {
          const filteredUnits = values.units.filter((unit) => unit.floor === floor);
          return {
            key: floor.toString(),
            actions: [],
            columns: [
              {
                name: "floor",
                component: (
                  <div>
                    <span>
                      {floor}
                      <sup>{floor === 1 ? "er" : "e"}</sup>
                    </span>
                    <span>étage</span>
                  </div>
                ),
              },
            ],
            rows: filteredUnits.map((unit) => {
              return {
                key: unit.id,
                actions: [],
                columns: [
                  {
                    name: "number",
                    component: <Text highlight>{unit.number}</Text>,
                  },
                  {
                    name: "bedroom-count",
                    component: (
                      <InputNumber
                        min={1}
                        value={unit.bedroomCount}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftUnit = draft.units.find((draftUnit) => draftUnit.id === unit.id);
                              if (draftUnit) {
                                draftUnit.bedroomCount = Number(value);
                              }
                            })
                          );
                        }}
                      />
                    ),
                  },
                  {
                    name: "bathroom-count",
                    component: (
                      <InputNumber
                        min={1}
                        value={unit.bathroomCount}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftUnit = draft.units.find((draftUnit) => draftUnit.id === unit.id);
                              if (draftUnit) {
                                draftUnit.bathroomCount = Number(value);
                              }
                            })
                          );
                        }}
                      />
                    ),
                  },
                  {
                    name: "half-bathroom-count",
                    component: (
                      <InputNumber
                        min={0}
                        value={unit.halfBathroomCount}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftUnit = draft.units.find((draftUnit) => draftUnit.id === unit.id);
                              if (draftUnit) {
                                draftUnit.halfBathroomCount = Number(value);
                              }
                            })
                          );
                        }}
                      />
                    ),
                  },
                  {
                    name: "form",
                    component: (
                      <SelectPicker
                        value={unit.formId}
                        onChange={(value) => {
                          setValues(
                            produce(values, (draft) => {
                              const draftUnit = draft.units.find((draftUnit) => draftUnit.id === unit.id);
                              if (draftUnit) {
                                draftUnit.formId = value;
                              }
                            })
                          );
                        }}
                        cleanable
                        data={forms
                          .filter((form) => form.state === FormState.PUBLISHED)
                          .map((form) => ({
                            label: form.name,
                            value: form.id,
                          }))}
                      />
                    ),
                  },
                ],
              };
            }),
          };
        }),
    }}
  />
);
