import "./index.scss";
import { FunctionComponent } from "react";
import { Button, Dropdown, IconButton, Popover, Whisper } from "rsuite";
import { ButtonSplit } from "buildingBlocks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useSecurityContext } from "providers";
import { Types } from "types";

type Props = {
  readonly buttons: Types.HeaderToolbarButtons;
  readonly otherActions: Types.HeaderToolbarOtherActions;
};

export const HeaderToolbar: FunctionComponent<Props> = ({ buttons, otherActions }) => {
  const { isAuthorized } = useSecurityContext();
  const toolbar = {
    buttons: buttons.filter(
      (button) =>
        isAuthorized({ security: button.security }) ||
        (button.__type === "Dropdown" &&
          isAuthorized({ security: button.security }) &&
          button.items.filter((item) => item.__type === "DropdownItem").some((item) => item.__type === "DropdownItem" && isAuthorized({ security: item.security })))
    ),
    otherActions: otherActions.filter((otherAction) => otherAction.__type === "Divider" || (otherAction.__type === "Button" && isAuthorized({ security: otherAction.security }))),
  };
  if (toolbar.buttons.filter((otherAction) => otherAction.show).length === 0 && toolbar.otherActions.filter((otherAction) => otherAction.show).length === 0) {
    return null;
  }
  return (
    <div className="header-toolbar">
      {toolbar.buttons.filter((button) => button.show).length !== 0 && (
        <div className="header-toolbar-buttons">
          {toolbar.buttons.map((button, index) => {
            if (!button.show) {
              return null;
            }
            if (button.__type === "ButtonSplit") {
              return (
                <ButtonSplit
                  key={index}
                  appearance={button.appearance}
                  icon={button.icon}
                  text={button.text}
                  disabled={button.disabled}
                  onClick={button.onClick}
                  actions={button.actions.filter((action) => action.__type === "Divider" || (action.__type === "Button" && isAuthorized({ security: action.security })))}
                />
              );
            }
            if (button.__type === "Dropdown") {
              return (
                <Dropdown key={index} title={button.title} disabled={button.disabled} appearance={button.appearance} trigger="click" placement="bottomEnd">
                  {button.items
                    .filter((item) => item.show)
                    .map((item, itemIndex) => {
                      if (!item.show) {
                        return null;
                      }
                      if (item.__type === "DropdownDivider") {
                        return <Dropdown.Item key={itemIndex} divider />;
                      }
                      return (
                        <Dropdown.Item key={itemIndex} disabled={item.disabled} onClick={item.onClick}>
                          {item.text}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown>
              );
            }
            return (
              <Button key={index} disabled={button.disabled} loading={button.loading} appearance={button.appearance} onClick={button.onClick} size="md">
                {button.text}
              </Button>
            );
          })}
        </div>
      )}
      {toolbar.otherActions.filter((otherAction) => otherAction.show).length !== 0 && (
        <Whisper
          placement="autoVerticalEnd"
          trigger="click"
          speaker={({ onClose, left, top, className }, ref) => {
            return (
              <Popover ref={ref} className={className} style={{ left, top }} full>
                <Dropdown.Menu
                  onSelect={() => {
                    onClose();
                  }}
                >
                  {toolbar.otherActions.map((otherAction, otherActionIndex) => {
                    if (!otherAction.show) {
                      return null;
                    }
                    if (otherAction.__type === "Divider") {
                      return <Dropdown.Item key={otherActionIndex} divider />;
                    }
                    return (
                      <Dropdown.Item key={otherActionIndex} eventKey={otherActionIndex} disabled={otherAction.disabled} onClick={otherAction.onClick}>
                        {otherAction.icon && <FontAwesomeIcon icon={otherAction.icon} />}
                        {otherAction.content}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Popover>
            );
          }}
        >
          <IconButton size="md" icon={<FontAwesomeIcon icon={faEllipsisV} />} />
        </Whisper>
      )}
    </div>
  );
};
